<template>
  <div
    id="app"
    v-loading="this.$store.state.isLoading"
    :element-loading-text="$t('m.加载中')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <keep-alive>
      <router-view />
    </keep-alive>

    <!-- 悬浮球 -->
    <div style="position: relative" v-if="tipsBall">
      <div class="button-box" v-drag draggable="false">
        <div class="btn-bg-img">
          <img src="./assets/icon/btn_Save备份@2x (2).png" alt="" />
        </div>
      </div>
    </div>

    <!-- 悬浮框 -->
    <div style="position: relative" v-if="tipsShow">
      <div class="drag-box" v-dradBox draggable="false">
        <div class="drag-cont">
          <div class="drag-cont-top">
            <span>{{$t('m.新手操作')}}</span>
            <img
              class="icon"
              src="./assets/icon/icon_ put away @2x.png"
              alt=""
              @click="dragShow()"
            />
          </div>
          <div class="drag-cont-bottom">
            <!-- 创建团队 -->
            <div
              class="drag-cont-bigBox"
              :class="[clickIndex == '1' ? 'cont-on' : '']"
            >
              <div class="drag-cont-box" @click="clickTo(1)">
                <div class="title">
                  <span class="spot"></span>
                  <span>{{$t('m.创建团队')}}</span>
                  <!-- <img
                  class="next-icon"
                  src="./assets/icon/icon_  arrow @2x.png"
                  alt=""
                  v-if="!noviceData.isTeam"
                />
                <span class="next" v-if="!noviceData.isTeam">下一步</span> -->
                </div>
                <img
                  src="./assets/icon/icon_ok@2x.png"
                  class="icon"
                  alt=""
                  v-if="noviceData.isTeam"
                />
                <img
                  src="./assets/icon/icon_undone@2x.png"
                  class="icon"
                  alt=""
                  v-else
                />
              </div>
            </div>

            <!-- 团队设置 -->
            <div
              class="drag-cont-bigBox"
              :class="[clickIndex == '4' ? 'cont-on' : '']"
            >
              <div class="drag-cont-box" @click="clickTo(4)">
                <div class="title">
                  <span class="spot"></span>
                  <span>{{$t('m.团队设置')}}</span>
                  <!-- <img
                  class="next-icon"
                  src="./assets/icon/icon_  arrow @2x.png"
                  alt=""
                  v-if="!noviceData.isTeamExamine"
                /> -->
                  <!-- <span class="next" v-if="!noviceData.isTeamExamine"
                  >下一步</span
                > -->
                </div>
                <img
                  src="./assets/icon/icon_ok@2x.png"
                  class="icon"
                  alt=""
                  v-if="noviceData.isTeamExamine"
                />
                <img
                  src="./assets/icon/icon_undone@2x.png"
                  class="icon"
                  alt=""
                  v-else
                />
              </div>

              <div class="box-text">
                <span style="cursor: pointer" @click="clickTo(4)"
                  >1.{{$t('m.设置团队信息')}}</span
                >
                <span style="cursor: pointer" @click="clickTo(3)"
                  >2.{{$t('m.补充团队保证金')}}</span
                >
                <span style="cursor: pointer" @click="clickTo(4)"
                  >3.{{$t('m.提交团队审核')}}</span
                >
                <span style="cursor: pointer" @click="clickTo(4)"
                  >4.{{$t('m.等待审核通过')}}</span
                >
              </div>
            </div>

            <!-- 创建项目 -->
            <div
              class="drag-cont-bigBox"
              :class="[clickIndex == '2' ? 'cont-on' : '']"
            >
              <div class="drag-cont-box" @click="clickTo(2)">
                <div class="title">
                  <span class="spot"></span>
                  <span>{{$t('m.创建项目')}}</span>
                  <!-- <img
                  class="next-icon"
                  src="./assets/icon/icon_  arrow @2x.png"
                  alt=""
                  v-if="
                    !noviceData.isProject &&
                    noviceData.isTeamExamine &&
                    noviceData.isProjectBond
                  "
                />
                <span
                  class="next"
                  v-if="
                    !noviceData.isProject &&
                    noviceData.isTeamExamine &&
                    noviceData.isProjectBond
                  "
                  >下一步</span
                > -->
                </div>
                <img
                  src="./assets/icon/icon_ok@2x.png"
                  class="icon"
                  alt=""
                  v-if="noviceData.isProject"
                />
                <img
                  src="./assets/icon/icon_undone@2x.png"
                  class="icon"
                  alt=""
                  v-else
                />
              </div>
            </div>

            <!-- 项目设置 -->
            <div
              class="drag-cont-bigBox"
              :class="[clickIndex == '6' ? 'cont-on' : '']"
              v-if="isProject"
            >
              <div class="drag-cont-box" @click="clickTo(6)">
                <div class="title">
                  <span class="spot"></span>
                  <span>{{$t('m.项目设置')}}</span>
                  <!-- <img
                  class="next-icon"
                  src="./assets/icon/icon_  arrow @2x.png"
                  alt=""
                  v-if="!noviceData.isProjectExamine"
                />
                <span class="next" v-if="!noviceData.isProjectExamine"
                  >下一步</span
                > -->
                </div>
                <img
                  src="./assets/icon/icon_ok@2x.png"
                  class="icon"
                  alt=""
                  v-if="noviceData.isProjectExamine"
                />
                <img
                  src="./assets/icon/icon_undone@2x.png"
                  class="icon"
                  alt=""
                  v-else
                />
              </div>
              <div class="box-text">
                <span>1.{{$t('m.设置基本信息、托管限制及分成赔付')}}</span>
                <span>2.{{$t('m.补充项目保证金')}}</span>
                <span>3.{{$t('m.提交项目审核')}}</span>
                <span>4.{{$t('m.项目审核通过')}}</span>
              </div>
            </div>

            <!-- 成员管理 -->
            <div
              class="drag-cont-bigBox"
              :class="[clickIndex == '5' ? 'cont-on' : '']"
            >
              <div class="drag-cont-box" @click="clickTo(5)">
                <div class="title">
                  <span class="spot"></span>
                  <span>{{$t('m.成员管理')}}</span>
                  <!-- <img
                  class="next-icon"
                  src="./assets/icon/icon_  arrow @2x.png"
                  alt=""
                  v-if="
                    !noviceData.isTeamMember &&
                    noviceData.isTeamExamine &&
                    noviceData.isProjectBond
                  "
                />
                <span
                  class="next"
                  v-if="
                    !noviceData.isTeamMember &&
                    noviceData.isTeamExamine &&
                    noviceData.isProjectBond
                  "
                  >下一步</span
                > -->
                </div>
                <img
                  src="./assets/icon/icon_ok@2x.png"
                  class="icon"
                  alt=""
                  v-if="noviceData.isTeamMember"
                />
                <img
                  src="./assets/icon/icon_undone@2x.png"
                  class="icon"
                  alt=""
                  v-else
                />
              </div>

              <div class="box-text">
                <span>1.{{$t('m.查看成员权限')}}</span>
                <span>2.{{$t('m.邀请成员加入团队')}}</span>
              </div>
            </div>

            <!-- 保证金 -->
            <div
              :class="[clickIndex == '3' ? 'cont-on' : '']"
              class="drag-cont-bigBox"
            >
              <div class="drag-cont-box" @click="clickTo(3)">
                <div class="title">
                  <span class="spot"></span>
                  <span>{{$t('m.保证金')}}</span>
                  <!-- <img
                  class="next-icon"
                  src="./assets/icon/icon_  arrow @2x.png"
                  alt=""
                  v-if="!noviceData.isProjectBond && noviceData.isTeamExamine"
                />
                <span
                  class="next"
                  v-if="!noviceData.isProjectBond && noviceData.isTeamExamine"
                  >下一步</span
                > -->
                </div>
                <img
                  src="./assets/icon/icon_ok@2x.png"
                  class="icon"
                  alt=""
                  v-if="noviceData.isProjectBond"
                />
                <img
                  src="./assets/icon/icon_undone@2x.png"
                  class="icon"
                  alt=""
                  v-else
                />
              </div>

              <div class="box-text">
                <span>1.{{$t('m.转入团队保证金')}}</span>
                <span>2.{{$t('m.划转保证金到项目')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from "vuex";
export default {
  data() {
    return {
      isOpen: false,
      isMove: false,

      tipsBall: true, // 悬浮球
      tipsShow: false, //弹窗

      isProject: false,
      noviceData: null,
      clickIndex: null,
    };
  },
  watch: {
    "$store.state.isProject"(New, old) {
      this.isProject = New;
    },

    "$store.state.isTeam"(New, old) {
      if (New) {
        this.tipsBall = true;
        this.tipsShow = false;
      } else {
        this.tipsBall = false;
        this.tipsShow = false;
      }
    },
    "$store.state.noviceData"(New, old) {
      this.noviceData = New;
    },
  },
  methods: {
    // 悬浮显隐
    dragShow() {
      this.tipsBall = true;
      this.tipsShow = false;
    },

    // 操作跳转
    clickTo(v) {
      console.log(v);
      this.clickIndex = v;
      switch (v) {
        case 1:
          // 创建团队
          this.$store.commit("setNoviceActive", "0");
          this.$router.push({ path: "firstLogin" });
          break;
        case 4:
          // 团队设置
          this.$store.commit("setNoviceActive", "4");
          this.$router.push({ path: "/" });
          break;
        case 2:
          // 创建项目
          this.$store.commit("setNoviceActive", "2");
          this.$router.push({ path: "/" });
          break;
        case 3:
          // 保证金
          this.$store.commit("setNoviceActive", "3");
          this.$router.push({ path: "/" });
          break;
        case 5:
          // 团队成员
          this.$store.commit("setNoviceActive", "0");
          this.$router.push({ path: "TeamSetup" });
          break;
        case 6:
          // 项目设置
          this.$store.commit("setNoviceActive", "6");
          this.$router.push({ path: "Project" });
          break;
        default:
          break;
      }
    },
  },

  directives: {
    // 悬浮球
    drag(el, binding, vnode) {
      let oDiv = el; // 当前元素
      if (sessionStorage.getItem("oldHeightClick")) {
        oDiv.style.top = sessionStorage.getItem("oldHeight") + "px";
        sessionStorage.removeItem("oldHeightClick");
      } else {
        sessionStorage.setItem("oldHeight", oDiv.offsetTop);
      }

      // console.log(el,binding,vnode,vnode.context,_this.a)

      let _this = vnode.context; // this 指向

      oDiv.onmousedown = (e) => {
        // 鼠标按下，计算当前元素距离可视区的距离
        let disX = e.clientX - oDiv.offsetLeft;
        let disY = e.clientY - oDiv.offsetTop;
        let isClick = true;

        document.onmousemove = function (e) {
          // 通过事件委托，计算移动的距离
          let l = e.clientX - disX;
          let t = e.clientY - disY;
          // 移动当前元素
          oDiv.style.left = l + "px";
          oDiv.style.top = t + "px";
          sessionStorage.removeItem("oldHeightClick");

          // 超出重置为0
          let w = document.documentElement.clientWidth - 80;
          let h = document.documentElement.clientHeight - 80;

          if (oDiv.offsetLeft <= 0) {
            oDiv.style.left = "0px";
          } else if (oDiv.offsetLeft >= w) {
            oDiv.style.left = w + "px";
          }

          if (oDiv.offsetTop <= 0) {
            oDiv.style.top = "0px";
          } else if (oDiv.offsetTop >= h) {
            oDiv.style.top = h + "px";
          }
          sessionStorage.setItem("oldHeight", oDiv.offsetTop >= h ? h : t);

          return (isClick = false);
        };

        document.onmouseup = function (e) {
          document.onmousemove = null;
          document.onmouseup = null;

          // 弹窗
          if (isClick) {
            _this.tipsBall = false;
            _this.tipsShow = true;

            let defaultTeam = JSON.parse(sessionStorage.getItem("defaultTeam"));
            let pay = {
              teamId: defaultTeam ? defaultTeam.teamId : null,
              teamProjectId: null,
            };
            _this.$store.dispatch("getType", pay);
          }
        };

        // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false;
      };
    },

    // 弹窗
    dradBox(el, binding, vnode) {
      let oDiv = el; // 当前元素

      if (document.documentElement.clientHeight - oDiv.offsetTop < 560) {
        let hieght = document.documentElement.clientHeight - 560;
        oDiv.style.top = hieght + "px";
        sessionStorage.setItem("oldHeightClick", true);
      }

      // console.log(el,binding,vnode,vnode.context,_this.a)

      let _this = vnode.context; // this 指向

      oDiv.onmousedown = (e) => {
        // 鼠标按下，计算当前元素距离可视区的距离
        let disX = e.clientX - oDiv.offsetLeft;
        let disY = e.clientY - oDiv.offsetTop;
        let isClick = true;

        _this.clickIndex = 0;

        document.onmousemove = function (e) {
          // 通过事件委托，计算移动的距离
          let l = e.clientX - disX;
          let t = e.clientY - disY;
          // 移动当前元素
          oDiv.style.left = l + "px";
          oDiv.style.top = t + "px";
          sessionStorage.removeItem("oldHeightClick");

          // 超出重置为0
          let w = document.documentElement.clientWidth - 280;
          let h = document.documentElement.clientHeight - 560;

          if (oDiv.offsetLeft <= 0) {
            oDiv.style.left = "0px";
          } else if (oDiv.offsetLeft >= w) {
            oDiv.style.left = w + "px";
          }

          if (oDiv.offsetTop <= 0) {
            oDiv.style.top = "0px";
          } else if (oDiv.offsetTop >= h) {
            oDiv.style.top = h + "px";
          }

          return (isClick = false);
        };

        document.onmouseup = function (e) {
          document.onmousemove = null;
          document.onmouseup = null;
        };

        // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false;
      };
    },
  },

  mounted() {},
};
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  .ty-icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  // 悬浮球样式
  .button-box {
    height: 64px;
    width: 80px;
    border-radius: 50%;
    position: fixed;
    bottom: 120px;
    left: 222px;
    padding-left: 15px;
    padding-top: 8px;
    cursor: pointer;
    opacity: 0.7;
    z-index: 9999;
  }

  .btn-bg-img {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2dd1ac;
    background-size: cover;
    border-radius: 50%;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .button-box:hover {
    color: white;
    opacity: 1;
  }

  // 弹窗样式
  .drag-box {
    width: 260px;
    position: fixed;
    bottom: 234px;
    left: 294px;
    padding-left: 15px;
    padding-top: 8px;
    opacity: 0.7;
    z-index: 9999;
  }
  .drag-cont {
    width: 260px;
    height: 530px;
    border-radius: 16px;
    background: #fff;
    background-size: cover;
    padding-bottom: 20px;

    box-shadow: 0px 8px 40px 0px rgba(243, 246, 249, 0.8);
    border: 1px solid #2174ff;
    .drag-cont-top {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: #2174ff;
      width: 100%;
      height: 50px;
      border-radius: 12px 12px 0 0;
      .icon {
        width: 18px;
        height: 16px;
        margin-left: 10px;
        margin-top: 2px;
        cursor: pointer;
      }
    }
    .drag-cont-bottom {
      // overflow-y: scroll;
      // padding-bottom: 20px;
      .drag-cont-bigBox {
        padding: 10px 0;
      }
      .drag-cont-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        .title {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #102859;
          .spot {
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-right: 10px;
            border-radius: 50%;
            background: #2174ff;
          }
          .next-icon {
            width: 16px;
            height: 18px;
            margin: 10px 5px 0 5px;
          }
          .next {
            font-size: 12px;
            margin-top: 10px;
          }
        }
        .icon {
          width: 14px;
          height: 14px;
        }
      }
      .box-text {
        display: flex;
        flex-direction: column;
        color: #758db5;
        font-size: 12px;
        margin-top: 10px;
        padding-left: 34px;
      }
      .cont-on {
        background: #f2f7ff;
      }
    }
  }
  .drag-box:hover {
    color: white;
    opacity: 1;
  }
}
.el-loading-mask {
  z-index: 8000 !important;
}
.dialogBtn {
  width: 70px;
  height: 33px;
  line-height: 5px;
  padding: 0;
}
.el-picker-panel__footer {
  .el-button--text {
    display: none;
  }
}

/* element样式重置 start */
/* 去掉input尾部上下小箭头 start */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -o-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}
/* 去掉input尾部上下小箭头 end */
</style>
