<template>
  <div class="projectList">
    <div class="content">
      <!-- <p class="content-title">项目列表</p> -->
      <div class="content-top">
        <div
          class="addProject"
          @click="createProject = true"
          v-if="projectList">
          <img src="../../assets/icon/icon_add@2x.png" alt="" />
          <span>{{$t('m.创建项目')}}</span>
        </div>

        <div class="sort">
          <span
            :class="selectNum === 0 ? 'select' : ''"
            style="width: 64px; border-radius: 8px 0 0 8px"
            @click="selectBtn(0)">
            {{$t('m.全部')}}</span>
          <span :class="selectNum === 1 ? 'select' : ''" @click="selectBtn(1)">
            {{$t('m.已上线')}}</span>
          <span
            style="border-radius: 0 8px 8px 0"
            :class="selectNum === 2 ? 'select' : ''"
            @click="selectBtn(2)">
            {{$t('m.未上线')}}</span>
        </div>
      </div>

      <div v-if="projectList" class="content-warp">
        <div
          class="content-box"
          v-for="(item, index) in projectData"
          :key="index"
          @click="toTeamDetails(item)">
          <div class="project-title">
            <div class="project-title-top">
              <span class="project-name">{{ item.projectName }}
              </span>

              <el-dropdown trigger="click" style="margin-left: 20px">
                <span class="el-dropdown-link" placement:bottom-start>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="toTeamDetails(item)">
                    {{$t('m.项目详情')}}</el-dropdown-item>
                  <el-dropdown-item @click.native="toProjectSetup(item, 2)">
                    {{$t('m.项目设置')}}</el-dropdown-item>
                  <el-dropdown-item @click.native="toProjectSetup(item, 3)">
                    {{$t('m.项目成员')}}</el-dropdown-item>
                  <el-dropdown-item @click.native="projectOutMember(item)">
                    {{$t('m.退出项目')}}</el-dropdown-item>
                  <el-dropdown-item
                    @click.native="deleteProjectMember(item)"
                    style="color: #f56c6c">
                    {{$t('m.删除项目')}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="project-title-state">
              <span
                class="project-type suspend-color"
                v-if="item.showStatus == 'notOnline'">
                {{$t('m.未上线')}}</span>
              <span
                class="project-type settlement-color"
                v-if="item.showStatus == 'review'">
                {{$t('m.审核中')}}</span>
              <span
                class="project-type settlement-completed-color"
                v-if="item.showStatus == 'online'">
                {{$t('m.已上线')}}</span>
              <span
                class="project-type suspend-color"
                v-if="item.status == 'suspendGold'">
                {{$t('m.暂停入金')}}</span>
              <span
                class="project-type settlement-color"
                v-if="item.status == 'settlement'">
                {{$t('m.结算中')}}</span>
              <span
                class="project-type settlement-completed-color"
                v-if="item.status == 'settled'">
                {{$t('m.结算完成')}}</span>
              <span class="project-type gold-color" v-if="item.status == 'gold'">
                {{$t('m.入金中')}}</span>
              <span
                class="project-type settlement-completed-color"
                v-if="item.visibility == 'yes'">
                {{$t('m.可见')}}</span>
              <span
                class="project-type suspend-color"
                v-if="item.visibility == 'no'">
                {{$t('m.不可见')}}</span>
            </div>
          </div>
          <span class="project-introduce">{{ item.projectIntroduct }}</span>
          <span class="accNikeName">{{ $t('m.管理员') + account.nickName }}</span>
        </div>

        <div class="defaultPage" v-if="online">
          <img
            style="width: 202px; height: 136px"
            src="../../assets/image/img_01@2x.png"
            alt=""
          />

          <span style="color: #999999" v-if="selectNum === 1">
            {{$t('m.您暂时没有上线项目呦')}}</span>
          <span style="color: #999999" v-if="selectNum === 2">
            {{$t('m.您暂时没有未上线项目呦')}}</span>
          <span style="color: #999999" v-else>
            {{$t('m.您暂时没有项目呦')}}</span>
        </div>
      </div>
      <div class="defaultPage" v-else>
        <img src="../../assets/image/编组 6备份@2x.png" alt="" />
        <span style="color: #999999">{{$t('m.暂无项目，点击下方创建项目吧～')}}</span>
        <div class="btn-warp">
          <img src="../../assets/icon/but_add@2x.png" alt="" />
          <span @click="createProject = true">{{$t('m.创建项目')}}</span>
        </div>
      </div>

      <div class="tips-warp" v-if="!projectList && tipsShow">
        <div class="tips">
          <div class="tips-main">
            <div class="tipsTitle">{{$t('m.什么是量化托管服务？')}}</div>
            <div class="tipsContent">
              {{$t('m.量化托管是一个快速、高效的批量策略交易平台，面向专业的策略交易团队或个人、解决小白用户策略工具使用痛点。提供从项目创建、发布、用户入金、资金托管到收益结算、团队管理等服务。降低项目开发成本，让团队专注策略交易。')}}
            </div>
            <div class="tipsBtn" @click="toOperationManual()">{{$t('m.操作手册')}}</div>
          </div>
          <div class="tips-img">
            <img src="../../assets/image/img copy@2x.png" alt="" />
          </div>
          <i class="el-icon-close tips-icon" @click="tipsShow = false"></i>
        </div>
      </div>
    </div>

    <!-- 创建项目 -->
    <el-dialog
      :title="$t('m.创建项目')"
      :visible.sync="createProject"
      width="30%"
      :before-close="createClose"
      :close-on-click-modal="false"
      :show-close="false">
      <input
        class="create-project-input"
        type="text"
        :placeholder="$t('m.请输入项目名字')"
        :maxlength="8"
        v-model="newProjectNmae"
      />
      <div style="font-size: 12px; margin-top: 5px">{{$t('m.限8个字符')}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddProject()">{{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="addProject()">{{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProjectList,
  addProject,
  getRole,
  deleteProjectMember,
  deleteProject,
  selectMessageTemaList,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      createProject: false, //创建项目
      newProjectNmae: null, // 新项目名字
      projectList: null, //项目列表
      defaultTeam: null,
      account: null,
      isShow: false,
      selectNum: 0, //项目上线
      tipsShow: true, //提示条件
      projectData: [], //筛选数据
      online: false, //项目列表筛选
      deleteTeamData: null, //GA
      MessageTemaList: null, //团队列表
      detailItem: null,
      role: null, //用户权限
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 创建项目关闭
    createClose(done) {
      // this.createProject=false
      this.newProjectNmae = null;
    },
    // 操作手册
    toOperationManual() {
      location.href;
      // https://shimo.im/docs/dPCWphVkPwGHcPcK/read
      // window.open='https://shimo.im/docs/dPCWphVkPwGHcPcK/read'
      window.open(
        "https://shimo.im/docs/dPCWphVkPwGHcPcK/read",
        "_blank",
        "width=1500,height=800,top=100px,left=0px"
      );
    },
    // // 提示关闭按钮
    // tipsShowBtn() {
    //   this.tipsShow = false;
    //   sessionStorage.setItem("tipsShow", "false");
    // },
    // 项目设置
    toProjectSetup(item, active) {
      let payload = {
        teamProjectId: item.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getRole(payload).then((res) => {
        if (res.status == 200) {
          this.role = res.data.role.mark;
          if (this.role == this.$t('m.超级管理员')) {
            sessionStorage.setItem("project", JSON.stringify(item));
            this.$router.push({
              path: "projectSetup",
              query: { active: active },
            });
          } else {
            this.$alert(
              this.$t('m.您没有权限使用该功能，敬请谅解！'),
              this.$t('m.额，好像没有权限'),
              {
                confirmButtonText: this.$t('m.知道啦'),
              }
            );
            return;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 项目详情
    toTeamDetails(item) {
      sessionStorage.setItem("project", JSON.stringify(item));
      this.getRole(item.teamProjectId);
    },
    // 查询角色权限
    getRole(id) {
      let payload = {
        teamProjectId: id,
        token: sessionStorage.getItem("token"),
      };
      getRole(payload).then((res) => {
        if (res.status == 200) {
          sessionStorage.setItem("role", JSON.stringify(res.data.role));
          if (
            res.data.role.roleName == "ADMIN" ||
            res.data.role.roleName == "MANAGE"
          ) {
            sessionStorage.setItem("isSuper", true);
            this.$router.push({ path: "Project" }); //管理员
          } else {
            sessionStorage.setItem("isSuper", false);
            sessionStorage.setItem("detailsOperationAuthority", true);
            // this.$router.push({
            // path: "Projectoperator",
            // path: "ProjectNewPerator",
            // query: { userId: this.account.userId },
            // }); //操作员
            this.$router.push({ path: "Project" });
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询团队列表
    selectMessageTemaList() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      selectMessageTemaList(payload).then((res) => {
        if (res.status == 200) {
          this.MessageTemaList = res.data.MessageTemaList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询项目列表
    getProjectList() {
      if (sessionStorage.getItem("queryNumber")) {
        if (this.MessageTemaList && this.MessageTemaList.length !== 0) {
          this.MessageTemaList.forEach((e) => {
            if (e.teamName == sessionStorage.getItem("queryNumber")) {
              this.defaultTeam = e;
            }
          });
        }
      }
      if (
        sessionStorage.getItem("defaultTeam") &&
        JSON.parse(sessionStorage.getItem("defaultTeam")).teamId !==
          this.defaultTeam.teamId
      ) {
        this.defaultTeam = JSON.parse(sessionStorage.getItem("defaultTeam"));
      }
      if (!this.defaultTeam) {
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        pageNo: 1,
        pageSize: 100,
      };
      getProjectList(payload).then((res) => {
        if (res.status == 200) {
          this.projectList = res.data.projectList;
          // if(this.projectList.length==0){
          //   this.online=true
          // }else{
          //   this.online=false
          // }

          this.selectBtn(0);
          this.isShow =
            this.account.userId == this.projectList &&
            this.projectList.createUser
              ? true
              : false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 创建项目
    addProject() {
      if (!this.newProjectNmae) {
        this.$message.error(this.$t('m.项目名称不能为空'));
        return;
      } else if (this.newProjectNmae.length > 8) {
        this.$message.error(this.$t('m.项目名称不能超过6个字符'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        projectName: this.newProjectNmae,
        projectIntruct: "",
      };
      addProject(payload).then((res) => {
        if (res.status == 200) {
          this.newProjectNmae = null;
          this.getProjectList();
          this.newProjectNmae = null;
          this.createProject = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    cancelAddProject() {
      this.newProjectNmae = null;
      this.createProject = false;
    },
    //退出项目
    projectOutMember(item) {
      this.$confirm(
        this.$t('m.退出项目后，该成员所属的用户将被分配给项目管理员'),
        this.$t('m.退出项目'),
        {
          confirmButtonText: this.$t('m.确定'),
          cancelButtonText: this.$t('m.取消'),
          closeOnClickModal: false,
        }
      )
        .then(() => {
          let payload = {
            teamProjectId: item.teamProjectId,
            token: sessionStorage.getItem("token"),
            userId: this.account.userId,
          };
          deleteProjectMember(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.操作成功'));
              this.getProjectList();
              this.deleteTeam = false;
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 删除项目
    deleteProjectMember(item) {
      let payload = {
        teamProjectId: item.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getRole(payload).then((res) => {
        if (res.status == 200) {
          this.role = res.data.role.mark;
          if (this.role == this.$t('m.超级管理员')) {
            let payload = {
              teamProjectId: item.teamProjectId,
              token: sessionStorage.getItem("token"),
            };
            this.$confirm(
              this.$t('m.删除项目操作一经确认，不可恢复，请谨慎操作。'),
              this.$t('m.删除项目'),
              {
                confirmButtonText: this.$t('m.确定'),
                cancelButtonText: this.$t('m.取消'),
                type: "warning",
              }
            )
              .then(() => {
                deleteProject(payload).then((res) => {
                  if (res.status == 200) {
                    this.$message.success(this.$t('m.操作成功'));
                    this.getProjectList();
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: this.$t('m.已取消删除'),
                });
              });
          } else {
            this.$alert(
              this.$t('m.您没有权限使用该功能，敬请谅解！'),
              this.$t('m.额，好像没有权限'),
              {
                confirmButtonText: this.$t('m.知道啦'),
              }
            );
            return;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //筛选按钮
    selectBtn(item) {
      this.selectNum = item;
      if (item === 0) {
        this.online = false;
        return (this.projectData = this.projectList);
      }
      //筛选条件:全部为空,已上线为online,未上线为notOnline
      this.projectData = [];

      if (this.projectList) {
        for (let i = 0; i < this.projectList.length; i++) {
          if (item === 2) {
            if (
              this.projectList[i].showStatus === "review" ||
              this.projectList[i].showStatus === "notOnline"
            ) {
              this.projectData.push(this.projectList[i]);
            }
          } else {
            if (this.projectList[i].showStatus === "online")
              this.projectData.push(this.projectList[i]);
          }
        }
      }
      if (this.projectData.length === 0) {
        this.online = true;
        return;
      }
      this.online = false;
    },
    projectRouter() {
      this.$router.push({ path: "project" });
    },
  },
  created() {
    let account = JSON.parse(sessionStorage.getItem("account"));
    this.account = account;
    // this.tipsShow = JSON.parse(sessionStorage.getItem("tipsShow"));
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    this.getProjectList();
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    // this.defaultTeam = JSON.parse(sessionStorage.getItem("defaultTeam"));
    // this.selectMessageTemaList();
    let account = JSON.parse(sessionStorage.getItem("account"));
    this.account = account;
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);

    if (sessionStorage.getItem("defaultTeam")) {
      this.defaultTeam = JSON.parse(defaultTeam);
      this.getProjectList();
    } else {
      setTimeout(() => {
        let defaultTeam = sessionStorage.getItem("defaultTeam");
        this.defaultTeam = JSON.parse(defaultTeam);
        this.getProjectList();
      }, 1000);
    }
  },
};
</script>

<style lang='scss' scoped>
.projectList {
  padding-left: 40px;
  .content {
    display: flex;
    flex-direction: column;
    .content-title {
      font-size: 16px;
      margin: 16px 0 40px;
    }
    .content-top {
      // height: 200px;
      display: flex;
      height: 40px;
      margin-top: 30px;
      .addProject {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 40px;
        color: #fff;
        font-size: 14px;
        // background: #007aff;
        border: 1px solid #2174ff;
        color: #2174ff;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 16px;
        img {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
        span {
          margin-left: 2px;
        }
      }
      .sort {
        width: 225px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 40px;
        background: #fff;
        border-radius: 8px;
        cursor: pointer;
        span {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .content-warp {
      display: flex;
      flex-wrap: wrap;
      .content-box {
        width: 290px;
        height: 192px;
        border-radius: 16px;
        padding: 24px 26px 0 32px;
        display: flex;
        flex-direction: column;
        margin: 40px 40px 0 0;
        background: #ffffff;
        position: relative;
        .project-title {
          .project-title-top {
            display: flex;
            justify-content: space-between;
            .project-name {
              font-size: 20px;
              display: flex;
              align-items: center;
            }
            .operation-icon {
              height: 20px;
              cursor: pointer;
            }
          }
          p {
            font-size: 12px;
            color: #999;
            margin-top: 8px;
          }
        }
        .project-title-state {
          margin-top: 16px;
          display: flex;
          align-items: center;
          .project-type {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 50px;
            height: 20px;
            padding: 0 6px;

            font-size: 8px;
            // background: #52a3fe;
            border-radius: 0px 8px 0px 8px;
            color: #fff;
            margin-left: 8px;
          }
          .gold-color {
            background: #ffa600; //入金中
          }
          .suspend-color {
            //暂停/下线
            background: #b3b3b3;
          }
          .settlement-color {
            //结算
            background: #70c1d0;
          }
          .settlement-completed-color {
            //上线/结算完成
            background: #409eff;
          }
        }
        .project-introduce {
          margin-top: 50px;
          font-size: 12px;
          color: #666;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 20px;
        }
        .accNikeName {
          font-size: 12px;
          color: #102859;
          position: absolute;
          left: 32px;
          bottom: 14px;
        }
      }
    }
  }
  .defaultPage {
    // margin-top: 50px;
    height: 400px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      height: 160px;
      width: 228px;
    }
    span {
      font-size: 16px;
      color: #313237;
      margin-left: 8px;
    }
    .btn-warp {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      width: 186px;
      height: 40px;
      background: linear-gradient(270deg, #00d1ff 0%, #2174ff 100%);
      box-shadow: 0px 8px 20px 0px rgba(176, 215, 253, 0.6);
      border-radius: 22px;
      cursor: pointer;
      margin-top: 30px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 2px;
      }
      span {
        color: #fff;
      }
    }
  }
  // 创建项目
  .create-project-input {
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: 1px solid #999;
    outline: none;
  }
  .tips-warp {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 140px;
    .tips {
      display: flex;
      width: 1020px;
      height: 220px;
      // background: #fff;
      padding: 28px 40px 15px 40px;
      border-radius: 16px;
      // margin-top: 30px;
      .tips-main {
        width: 630px;
        .tipsTitle {
          font-size: 20px;
          color: #102859;
        }
        .tipsContent {
          color: #55698a;
          margin: 10px 0 30px 0;
        }
        .tipsBtn {
          color: #007aff;
          cursor: pointer;
        }
      }
      .tips-img {
        width: 166px;
        height: 130px;
        margin: 0 48px 0 40px;
        img {
          width: 100%;
        }
      }
      .tips-icon {
        font-size: 18px;
        position: relative;
        top: -8px;
        left: 18px;
        color: #9faabd;
        cursor: pointer;
      }
    }
  }
}
.select {
  background: #0079fd;
  color: white;
}
</style>