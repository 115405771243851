//中文
const cn = {
  m: {
    "langs":'ch',
    
    "实例编号":"实例编号",
    "Vtrading APP用户":"Vtrading APP用户",
    //----------------------------------------核心 9
    "加载中":"加载中",
    "新手操作":"新手操作",
    "创建团队":"创建团队",
    "团队设置":"团队设置",
    "设置团队信息":"设置团队信息",
    "补充团队保证金":"补充团队保证金",
    "提交团队审核":"提交团队审核",
    "等待审核通过":"等待审核通过",
    "创建项目":"创建项目",
    "项目设置":"项目设置",
    "设置基本信息、托管限制及分成赔付":"设置基本信息、托管限制及分成赔付",
    "补充项目保证金":"补充项目保证金",
    "提交项目审核":"提交项目审核",
    "项目审核通过":"项目审核通过",
    "查看成员权限":"查看成员权限",
    "邀请成员加入团队":"邀请成员加入团队",
    "保证金":"保证金",
    "转入团队保证金":"转入团队保证金",
    "划转保证金到项目":"划转保证金到项目",


    "创建团队名称":"创建团队名称",
    "选择服务对象":"选择服务对象",
    "完成":"完成",
    "团队名称":"团队名称",
    "选择您的团队服务对象":"选择您的团队服务对象",
    "绑定平台标识":"绑定平台标识",
    "请输入您的团队名称,限8个字符":"请输入您的团队名称,限8个字符",
    "Vtrading App用户":"Vtrading App用户",
    "由Vtrading提供结算解决方案":"由Vtrading提供结算解决方案",
    "嵌入式系统平台用户":"嵌入式系统平台用户",
    "由平台提供结算解决方案":"由平台提供结算解决方案",
    "您可以联系平台方获取标识":"您可以联系平台方获取标识",
    "未查询到平台，请联系平台方确认":"未查询到平台，请联系平台方确认",
    "创建":"创建",
    "请先输入团队名称":"请先输入团队名称",
    "团队名称限8个字符":"团队名称限8个字符",
    "团队名称已存在，不能重名！":"团队名称已存在，不能重名！",
    "请输入appId":"请输入appId",
    "创建成功":"创建成功",
    "下一步":"下一步",
    "上一步":"上一步",


    "创建新团队":"创建新团队",
    "项目列表":"项目列表",
    "托管项目":"托管项目",
    "团队成员":"团队成员",
    "仅超级管理员可见":"仅超级管理员可见",
    "首页":"首页",
    "退出登录":"退出登录",
    "限6个字符":"限6个字符",
    "请输入内容":"请输入内容",
    "团队介绍":"团队介绍",
    "限500个字符":"限500个字符",
    "取 消":"取 消",
    "确 定":"确 定",
    "请简单介绍一下这个团队吧…":"请简单介绍一下这个团队吧…",
    "您没有权限使用该功能，敬请谅解！":"您没有权限使用该功能，敬请谅解！",
    "知道啦":"知道啦",
    "未审核":"未审核",
    "审核中":"审核中",
    "已审核":"已审核",
    "退出成功":"退出成功",
    "团队名称不能超过6个字符":"团队名称不能超过6个字符",
    "团队介绍不能为空":"团队介绍不能为空",
    "团队名称不能为空":"团队名称不能为空",
    "修改成功":"修改成功",


    "扫码登录":"扫码登录",
    "二维码有效期":"二维码有效期",
    "刷新二维码":"刷新二维码",
    "打开 VtradingAPP 扫一扫登录":"打开 VtradingAPP 扫一扫登录",
    "安全验证":"安全验证",
    "为了您的账户安全，请完成以下验证操作":"为了您的账户安全，请完成以下验证操作",
    "谷歌验证码":"谷歌验证码",
    "请输入6位谷歌验证码":"请输入6位谷歌验证码",
    "安全验证未绑定":"安全验证未绑定",
    "为了确保账户安全，请先前往APP端-安全中心绑定谷歌验证器。":"为了确保账户安全，请先前往APP端-安全中心绑定谷歌验证器。",
    "请输入GA":"请输入GA",
    "登录成功":"登录成功",
    "确认关闭？":"确认关闭？",
    "提 交":"提 交",


    "同步全部资产":"同步全部资产",
    "策略交易":"策略交易",
    "创建模板":"创建模板",
    "策略":"策略",
    "智能订单":"智能订单",
    "快捷交易":"快捷交易",
    "创建订单链":"创建订单链",
    "订单链":"订单链",
    "持仓组合":"持仓组合",
    "我管理的账户":"我管理的账户",
    "项目管理的账户":"项目管理的账户",
    "信号中心":"信号中心",
    "交易数据监测":"交易数据监测",
    "项目成员":"项目成员",
    "消息中心":"消息中心",
    "同步成功":"同步成功",


    "项目详情":"项目详情",


    "项目数据":"项目数据",
    "我的用户":"我的用户",
    "用户列表":"用户列表",


    "高级设置":"高级设置",
    "操作成功":"操作成功",


    //----------------------------------------projectSetup 9
    "配置设置":"配置设置",
    "正式上线后不可修改":"正式上线后不可修改",
    "账户":"账户",
    "暂时只支持币币":"暂时只支持币币",
    "投入币种限制":"投入币种限制",
    "暂时只支持USDT投入":"暂时只支持USDT投入",
    "投入资金限制":"投入资金限制",
    "范围":"范围",
    "未上线":"未上线",
    "已上线":"已上线",
    "结算中":"结算中",
    "交易所限制":"交易所限制",
    "请选择":"请选择",
    "交易对限制":"交易对限制",
    "佣金形式":"佣金形式",
    "盈利分成(必填)":"盈利分成(必填)",
    "用户 盈利 &{1} 的部分，要求分成比例":"用户 盈利 &{1} 的部分，要求分成比例",
    "请输入":"请输入",
    "每次分成上限":"每次分成上限",
    "最小可用保证金要求":"最小可用保证金要求",
    "当用户 亏损 &{1} 的部分，承诺赔偿比例":"当用户 亏损 &{1} 的部分，承诺赔偿比例",
    "赔付承诺生效时间":"赔付承诺生效时间",
    "请输入时间":"请输入时间",
    "天":"天",
    "保 存":"保 存",
    "基本信息":"基本信息",
    "申请上线时将对内容进行审核":"申请上线时将对内容进行审核",
    "项目名称":"项目名称",
    "项目简介":"项目简介",
    "项目简介内容在上架时需提交审核":"项目简介内容在上架时需提交审核",
    "项目操作":"项目操作",
    "项目状态":"项目状态",
    "项目阶段":"项目阶段",
    "入金操作":"入金操作",
    "开放入金":"开放入金",
    "暂停入金":"暂停入金",
    "项目可见":"项目可见",
    "可见":"可见",
    "不可见":"不可见",
    "测试V口令":"测试V口令",
    "结算":"结算",
    "申请上线":"申请上线",
    "币币":"币币",
    "项目可见：可见则展示在量化托管列表；不可见则不在量化托管列表展示，但可通过测试V口令访问":"项目可见：可见则展示在量化托管列表；不可见则不在量化托管列表展示，但可通过测试V口令访问",
    "入金操作：开放入金时，用户可以将资金投入项目，暂停入金时，无法投入资金":"入金操作：开放入金时，用户可以将资金投入项目，暂停入金时，无法投入资金",
    "生效时间之前退出的将不会获得赔偿":"生效时间之前退出的将不会获得赔偿",
    "[V口令]":"打开Vtrading，跟着量化大V一起飞",
    "打开Vtrading，跟着量化大V一起飞":"",
    "确认结算后，项目将开始结算清盘，并从量化托管列表移除":"确认结算后，项目将开始结算清盘，并从量化托管列表移除",
    "确定":"确定",
    "取消":"取消",
    "V口令已复制，去粘贴给好友吧":"V口令已复制，去粘贴给好友吧",
    "最小投入资金不能低于最小范围":"最小投入资金不能低于最小范围",
    "最大投入资金不能大于最大范围":"最大投入资金不能大于最大范围",
    "最小投入资金不能大于最大投入资金":"最小投入资金不能大于最大投入资金",
    "盈利比例不能为负":"盈利比例不能为负",
    "盈利分成比例不能为负":"盈利分成比例不能为负",
    "盈利分成比例不能超过50%":"盈利分成比例不能超过50%",
    "盈利分成上限不能为负":"盈利分成上限不能为负",
    "最小保证金不能为负":"最小保证金不能为负",
    "亏损赔付不能为负":"亏损赔付不能为负",
    "亏损比例不能为负":"亏损比例不能为负",
    "赔付生效时间不能小于0":"赔付生效时间不能小于0",
    "赔付承诺生效时间不能大于30天":"赔付承诺生效时间不能大于30天",
    "设置成功":"设置成功",
    "输入值不能为负数":"输入值不能为负数",
    "结算完成":"结算完成",
    "最少选择一个交易所":"最少选择一个交易所",
    "项目介绍不能为空！":"项目介绍不能为空！",
    "最少选择一个交易对":"最少选择一个交易对",


    "产品使用许可":"产品使用许可",
    "团队缴纳相应保证金，通过官方审核后即可对用户提供量化托管服务，在项目运行期内，平台将收取：":"团队缴纳相应保证金，通过官方审核后即可对用户提供量化托管服务，在项目运行期内，平台将收取：",
    "1、每托管账户不超过账户盈利10%的分成费，费用在账户退出结算时自动扣取，亏损账户无需支付；":"1、每托管账户不超过账户盈利10%的分成费，费用在账户退出结算时自动扣取，亏损账户无需支付；",
    "2、每日不超过&(1)的托管资金管理费。":"2、每日不超过&(1)的托管资金管理费。",
    "产品推广周期内部分或全额减免支付分成费和管理费，具体比例以平台页面为准。":"产品推广周期内部分或全额减免支付分成费和管理费，具体比例以平台页面为准。",
    "当前团队需支付的账户收益分成费比例为0%，资金管理费为0%。":"当前团队需支付的账户收益分成费比例为0%，资金管理费为0%。",
    "团队保证金":"团队保证金",
    "团队初始激活保证金不低于&{1}VTN":"团队初始激活保证金不低于&{1}VTN",
    "初始保证金":"初始保证金",
    "保证金余额":"保证金余额",
    "操作":"操作",
    "补充保证金":"补充保证金",
    "提取保证金":"提取保证金",
    "明细":"明细",
    "项目保证金":"项目保证金",
    "项目初始激活保证金不低于 10000VTN":"项目初始激活保证金不低于 10000VTN",
    "项目":"项目",
    "未结算":"未结算",
    "已结算":"已结算",
    "可划转":"可划转",
    "划转":"划转",
    "交易授权":"交易授权",
    "请在Vtrading APP上确认操作或 使用APP扫描下方二维码":"请在Vtrading APP上确认操作或 使用APP扫描下方二维码",
    "二维码已失效":"二维码已失效",
    "点击刷新":"点击刷新",
    "激活团队":"激活团队",
    "全部项目":"全部项目",
    "余额= 初始+已结算":"余额= 初始+已结算",
    "强制结算":"强制结算",
    "余额≤0 或 (余额+未结算)≤0 时项目将被强制清算下线。":"余额≤0 或 (余额+未结算)≤0 时项目将被强制清算下线。",
    "实际为减少风险，当 (余额+未结算)/项目最低初始激活保证金≤20%时，将提前结算。":"实际为减少风险，当 (余额+未结算)/项目最低初始激活保证金≤20%时，将提前结算。",
    "强制结算时先结算盈利分成部分，剩余金额用于结算亏损赔付，不足以支付亏损赔付的由亏损方根据亏损权重分摊":"强制结算时先结算盈利分成部分，剩余金额用于结算亏损赔付，不足以支付亏损赔付的由亏损方根据亏损权重分摊",
    "其他：":"其他：",
    "用户退出时将整点结算，结算时操作员不可操作":"用户退出时将整点结算，结算时操作员不可操作",
    "亏损赔付时优先使用已结算的VTN":"亏损赔付时优先使用已结算的VTN",
    "提取保证金时优先转出初始的USDT":"提取保证金时优先转出初始的USDT",
    "请选择币种":"请选择币种",
    "金额":"金额",
    "请输入金额":"请输入金额",
    "可提：":"可提：",
    "从":"从",
    "请选择项目账户":"请选择项目账户",
    "到":"到",
    "币种":"币种",
    "划转数量":"划转数量",
    "全部":"全部",
    "可用划转":"可用划转",
    "转入项目的USDT将自动按汇率兑换成VTN，并在转出时原价兑成USDT":"转入项目的USDT将自动按汇率兑换成VTN，并在转出时原价兑成USDT",
    "划 转":"划 转",
    "由超级管理员的保证金账户补充和项目保证金转入":"由超级管理员的保证金账户补充和项目保证金转入",
    "可用于保证金结算的余额，余额 = 转入 + 转出 + 已结算":"可用于保证金结算的余额，余额 = 转入 + 转出 + 已结算",
    "未结算的盈利分成 + 亏损赔付 + 盈利分成费，嵌入式系统不计算盈利分成和亏损赔付":"未结算的盈利分成 + 亏损赔付 + 盈利分成费，嵌入式系统不计算盈利分成和亏损赔付",
    "已结算的盈利分成 + 亏损赔付 + 盈利分成费 +资金管理费，嵌入式系统不计算盈利分成和亏损赔付":"已结算的盈利分成 + 亏损赔付 + 盈利分成费 +资金管理费，嵌入式系统不计算盈利分成和亏损赔付",
    "可提取的保证金，优先提取初始余额结余部分， 提取此部分将自动返回保证金账户，其他部分将提至收益账户，当存在保证金项目时，不可提取保证金":"可提取的保证金，优先提取初始余额结余部分， 提取此部分将自动返回保证金账户，其他部分将提至收益账户，当存在保证金项目时，不可提取保证金",
    "优先提取初始余额结余部分，提取的USDT将自动返回保证金账户，提取的VTN将提至收益账户":"优先提取初始余额结余部分，提取的USDT将自动返回保证金账户，提取的VTN将提至收益账户",
    "从保证金账户转入，暂时只支持USDT":"",
    "当项目状态为未上线、已下线时，可划转全部余额；当项目状态为审核中、已上线时，可划转 = min（余额，余额 + 未结算）- 10000 VTN；当项目状态为结算中时，可划转数量为0。":"当项目状态为未上线、已下线时，可划转全部余额；当项目状态为审核中、已上线时，可划转 = min（余额，余额 + 未结算）- 10000 VTN；当项目状态为结算中时，可划转数量为0。",
    "团队账户":"团队账户",
    "划转数量不能为空":"划转数量不能为空",
    "划转数量不能小于0":"划转数量不能小于0",
    "划转数量不能超出可划转数":"划转数量不能超出可划转数",
    "团队初始激活保证金不低于 10000VTN，请补充保证金":"团队初始激活保证金不低于 10000VTN，请补充保证金",
    "确定激活团队?":"确定激活团队?",
    "激活团队?":"激活团队?",
    "输入金额必须大于0":"输入金额必须大于0",
    "输入金额不能大于可提金额":"输入金额不能大于可提金额",


    "团队保证金明细":"团队保证金明细",
    "筛选类型":"筛选类型",
    "时间":"时间",
    "类型":"类型",
    "转入至项目":"转入至项目",
    "项目转出":"项目转出",
    "备注":"备注",
    "折合":"折合",
    "标记汇率":"标记汇率",
    "转出":"转出",
    "转入":"转入",
    "保证金[VTN]":"保证金[VTN]",
    "亏损赔付":"亏损赔付",
    "盈利分成":"盈利分成",
    "资金管理费":"资金管理费",
    "收益分成费":"收益分成费",
    "项目保证金明细":"项目保证金明细",


    "最多支持20个币对，项目上线后不可修改，请谨慎选择":"最多支持20个币对，项目上线后不可修改，请谨慎选择",
    "添加":"添加",
    "已经存在所选交易对":"已经存在所选交易对",
    "最多支持20个交易对":"最多支持20个交易对",
    "保存成功":"保存成功",


    "项目信息":"项目信息",
    "审核失败":"审核失败",
    "已下线":"已下线",
    "请输入项目名称":"请输入项目名称",
    "限20个字符":"限20个字符",
    "请输入项目简介":"请输入项目简介",
    "项目名称不能为空":"项目名称不能为空",

    
    "退出项目":"退出项目",
    "删除项目":"删除项目",
    "入金中":"入金中",
    "管理员":"管理员",
    "您暂时没有上线项目呦":"您暂时没有上线项目呦",
    "您暂时没有未上线项目呦":"您暂时没有未上线项目呦",
    "您暂时没有项目呦":"您暂时没有项目呦",
    "暂无项目，点击下方创建项目吧～":"暂无项目，点击下方创建项目吧～",
    "什么是量化托管服务？":"什么是量化托管服务？",
    "量化托管是一个快速、高效的批量策略交易平台，面向专业的策略交易团队或个人、解决小白用户策略工具使用痛点。提供从项目创建、发布、用户入金、资金托管到收益结算、团队管理等服务。降低项目开发成本，让团队专注策略交易。":"量化托管是一个快速、高效的批量策略交易平台，面向专业的策略交易团队或个人、解决小白用户策略工具使用痛点。提供从项目创建、发布、用户入金、资金托管到收益结算、团队管理等服务。降低项目开发成本，让团队专注策略交易。",
    "操作手册":"操作手册",
    "请输入项目名字":"请输入项目名字",
    "限8个字符":"限8个字符",
    "超级管理员":"超级管理员",
    "额，好像没有权限":"额，好像没有权限",
    "项目名称不能超过6个字符":"项目名称不能超过6个字符",
    "退出项目后，该成员所属的用户将被分配给项目管理员":"退出项目后，该成员所属的用户将被分配给项目管理员",
    "删除项目操作一经确认，不可恢复，请谨慎操作。":"删除项目操作一经确认，不可恢复，请谨慎操作。",
    "已取消删除":"已取消删除",


    "成员权限":"成员权限",
    "成员":"成员",
    "账号":"账号",
    "权限":"权限",
    "默认":"默认",
    "设置为默认操作员":"设置为默认操作员",
    "设置为管理员":"设置为管理员",
    "移出项目":"移出项目",
    "邀请成员":"邀请成员",
    "选择":"选择",
    "团队唯一":"团队唯一",
    "创建/删除项目":"创建/删除项目",
    "管理团队成员":"管理团队成员",
    "兼容管理员":"兼容管理员",
    "项目唯一":"项目唯一",
    "管理项目":"管理项目",
    "分流用户":"分流用户",
    "兼容操作员":"兼容操作员",
    "操作员":"操作员",
    "配置自动交易":"配置自动交易",
    "批量操作":"批量操作",
    "入金用户将自动分配给默认操作员":"入金用户将自动分配给默认操作员",
    "移出成功":"移出成功",


    "结算明细":"结算明细",
    "退出时间":"退出时间",
    "入金时间":"入金时间",
    "用户":"用户",
    "总投入(USDT)":"总投入(USDT)",
    "总投入(KRW)":"总投入(KRW)",
    "总收益(USDT)":"总收益(USDT)",
    "总收益(KRW)":"总收益(KRW)",
    "总收益率":"总收益率",
    "实际结算金额(VTN)":"实际结算金额(VTN)",


    "团队状态：":"团队状态：",
    "审核未通过 原因：":"审核未通过 原因：",
    "申请审核":"申请审核",
    "撤回申请":"撤回申请",
    "您的团队服务对象":"您的团队服务对象",
    "由平台方提供结算解决方案":"由平台方提供结算解决方案",
    "团队信息":"团队信息",
    "草稿":"草稿",
    "修改":"修改",
    "保存":"保存",
    "限500字符":"限500字符",
    "解散团队操作一经确认，不可恢复呦":"解散团队操作一经确认，不可恢复呦",
    "请输入安全验证码(谷歌验证码)":"请输入安全验证码(谷歌验证码)",
    "抱歉，无法解散团队":"抱歉，无法解散团队",
    "您的团队下存在项目未删除，无法进行操作。":"您的团队下存在项目未删除，无法进行操作。",
    "团队介绍不能为空！":"团队介绍不能为空！",
    "团队名称不能为空！":"团队名称不能为空！",
    "请输入团队内容":"请输入团队内容",
    "解散团队成功":"解散团队成功",
    


    //----------------------------------------teamSetup 2
    "成员列表":"成员列表",
    "添加成员":"添加成员",
    "用户名":"用户名",
    "我":"我",
    "最后操作":"最后操作",
    "无":"无",
    "退出团队":"退出团队",
    "移出团队":"移出团队",
    "成员设置":"成员设置",
    "账号：":"账号：",
    "团队项目":"团队项目",
    "不能修改超级管理员角色":"不能修改超级管理员角色",
    "您没有权限使用该功能，":"您没有权限使用该功能，",


    "团队状态":"团队状态",
    "未激活":"未激活",
    "已激活":"已激活",
    "审核反馈：":"审核反馈：",

    
    "这里是":"这里是",
    "解散团队":"解散团队",
    "全部成员":"全部成员",
    "未加入项目成员":"未加入项目成员",
    "项目组":"项目组",
    "通过链接邀请":"通过链接邀请",
    "链接有效期1小时":"链接有效期1小时",
    "复制链接":"复制链接",
    "解散团队操作一经确认，不可恢复呦":"解散团队操作一经确认，不可恢复呦",
    "请输入安全验证码(谷歌验证码)":"请输入安全验证码(谷歌验证码)",
    "复制成功":"复制成功",
    "请先提取团队保证金，再尝试解散团队":"请先提取团队保证金，再尝试解散团队",
     

    //----------------------------------------projectManagement 31
    "可用金额":"可用金额",
    "账户列表&(1)条，已选择&(2)条":"账户列表&(1)条，已选择&(2)条",
    "请先选择账户":"请先选择账户",


    "智能交易":"智能交易",
    "托管账户":"托管账户",
    "项目消息":"项目消息",
    "信息":"信息",
    "现货":"现货",
    "合约":"合约",
    "数量":"数量",
    "价格":"价格",
    "交易对":"交易对",
    "交易类型":"交易类型",
    "委托":"委托",
    "卖出":"卖出",
    "买入":"买入",
    "交易状态":"交易状态",
    "成功":"成功",
    "失败":"失败",
    "错误码":"错误码",
    "入金":"入金",
    "退出入金":"退出入金",
    "触发结算":"触发结算",
    "api无效":"api无效",
    "手动终止":"手动终止",
    "自动终止":"自动终止",
    "操作员终止":"操作员终止",
    "所属账户":"所属账户",
    "所属操作员":"所属操作员",


    "子币对操作":"子币对操作",
    "执行中":"执行中",
    "暂停中":"暂停中",
    "启动中":"启动中",
    "重启中":"重启中",
    "终止中":"终止中",
    "启动错误":"启动错误",
    "终止":"终止",
    "暂停":"暂停",
    "重启":"重启",
    "参数":"参数",
    "修改参数":"修改参数",
    "确认暂停该策略？":"确认暂停该策略？",
    "暂停成功":"暂停成功",
    "确认重启该策略？":"确认重启该策略？",
    "重启成功":"重启成功",
    "确认终止该策略？":"确认终止该策略？",
    "参数不能为空":"参数不能为空",
    "参数不能小于最低范围":"参数不能小于最低范围",
    "参数不能大于最大范围":"参数不能大于最大范围",
    "参数配置不正确，请重新修改":"参数配置不正确，请重新修改",


    "操作日志":"操作日志",
    "暂无记录":"暂无记录",


    "策略消息":"策略消息",
    "暂无数据":"暂无数据",


    "历史详情":"历史详情",
    "历史策略":"历史策略",
    "启动时间":"启动时间",
    "终止时间":"终止时间",
    "实例":"实例",
    "总投入":"总投入",
    "总收益":"总收益",
    "总年化":"总年化",
    "实现年化":"实现年化",
    "交易数（买入）（卖出）":"交易数（买入）（卖出）",
    "消息":"消息",


    "订单链消息":"订单链消息",
    "市价卖出":"市价卖出",
    "市价买入":"市价买入",
    "委托成功":"委托成功",
    "委托失败":"委托失败",
    "买入成功":"买入成功",
    "买入失败":"买入失败",

    
    "委托卖出":"委托卖出",
    "委托买入":"委托买入",


    "选择交易对":"选择交易对",
    "交易对列表":"交易对列表",
    "涨跌幅":"涨跌幅",
    "请选择交易对":"请选择交易对",
    "自定义模式只能选择1个交易对":"自定义模式只能选择1个交易对",


    "设置默认操作员":"设置默认操作员",
    "整体迁移用户":"整体迁移用户",
    "当前/累计账户数量：":"当前/累计账户数量：",
    "当前/累计执行策略：":"当前/累计执行策略：",
    "当前/累计资产：":"当前/累计资产：",
    "当前/累计账户盈亏：":"当前/累计账户盈亏：",
    "最大盈利/回撤率：":"最大盈利/回撤率：",
    "当前平均年化：":"当前平均年化：",
    "迁移用户":"迁移用户",
    "请选择操作员":"请选择操作员",
    "请先选择操作员":"请先选择操作员",
    "迁移成功":"迁移成功",


    "创建者":"创建者",
    "平台":"平台",
    "有效期":"有效期",
    "删除信号":"删除信号",
    "属性":"属性",
    "复制":"复制",
    "接收时间":"接收时间",
    "消息内容":"消息内容",
    "信号记录":"信号记录",
    "删除成功":"删除成功",
    "复制失败":"复制失败",
    "确认删除此信号？":"确认删除此信号？",


    "共&(1)名项目成员":"共&(1)名项目成员",
    "默认操作员":"默认操作员",
    "添 加":"添 加",
    "移出项目后，该成员所属的用户将被分配给项目管理员":"移出项目后，该成员所属的用户将被分配给项目管理员",
    "添加成功":"添加成功",


    "创建信号":"创建信号",
    "TradingView信号订阅教程>>":"TradingView信号订阅教程>>",
    "接收结果":"接收结果",
    "暂未收到信息":"暂未收到信息",
    "名称":"名称",
    "请输入名称":"请输入名称",
    "请输入有效期":"请输入有效期",
    "属性映射":"属性映射",
    "请输入属性":"请输入属性",
    "请输入显示名称":"请输入显示名称",
    "删除-":"删除-",
    "显示名称":"显示名称",
    "固定值":"固定值",
    "可选":"可选",
    "注释":"注释",
    "新增字段+":"新增字段+",
    "我的创建":"我的创建",
    "新信号":"新信号",
    "请选择平台":"请选择平台",
    "请输入属性&(1)的属性":"请输入属性&(1)的属性",
    "请输入属性&(1)的显示名称":"请输入属性&(1)的显示名称",
    "服务器接收到信号后开始推送并计时， 超时后信号失效并停止推送。":"服务器接收到信号后开始推送并计时， 超时后信号失效并停止推送。",


    "根据用户建议，APP端展示时自动剔除不足7日结算退出的数据。":"根据用户建议，APP端展示时自动剔除不足7日结算退出的数据。",
    "数据概览图（项目）":"数据概览图（项目）",
    "当前/累计账户数量":"累计账户数量",
    "当前/累计资产(USDT)":"当前/累计资产(USDT)",
    "当前/累计盈亏(USDT)":"当前/累计盈亏(USDT)",
    "最大盈利/回撤率":"最大盈利/回撤率",
    "当前平均年化":"当前平均年化",
    "资产走势图":"资产走势图",
    "每日利润":"每日利润",
    "资金盈利":"资金盈利",
    "资产亏损":"资产亏损",
    "详情":"详情",
    "当前/累计执行策略":"当前/累计执行策略",
    "总资产":"总资产",
    "当前收益":"当前收益",


    "当前策略数：":"当前策略数：",
    "当前总资金(USDT)：":"当前总资金(USDT)：",
    "当前总投入(USDT)：":"当前总投入(USDT)：",
    "当前总收益(USDT)：":"当前总收益(USDT)：",
    "平均总年化：":"平均总年化：",
    "未执行策略用户：":"未执行策略用户：",
    "交易所":"交易所",
    "请选择交易所":"请选择交易所",
    "开始时间":"开始时间",
    "结束时间":"结束时间",
    "剩余可用":"剩余可用",
    "收起":"收起",
    "展开":"展开",
    "重置":"重置",
    "查询":"查询",
    "当前用户":"当前用户",
    "总资金(USDT)":"总资金(USDT)",
    "已投入(USDT)":"已投入(USDT)",
    "剩余可用(USDT)":"剩余可用(USDT)",
    "当前策略数":"当前策略数",
    "历史用户":"历史用户",
    "请先选择用户":"请先选择用户",


    "策略配置":"策略配置",
    "选择策略：":"选择策略：",
    "请选择策略":"请选择策略",
    "选择交易所：":"选择交易所：",
    "选择模式：":"选择模式：",
    "自定义模式":"自定义模式",
    "多币对模式":"多币对模式",
    "选择交易对：":"选择交易对：",
    "策略方向":"策略方向",
    "赚本币（看多）":"赚本币（看多）",
    "赚交易币（看空）":"赚交易币（看空）",
    "参数配置":"参数配置",
    "范围：":"范围：",
    "配置投入":"配置投入",
    "固定投入金额":"固定投入金额",
    "请输入投入金额":"请输入投入金额",
    "可用余额：":"可用余额：",
    "满足比例，但低于下限不投； 满足比例，但高于上限的按上限投；":"满足比例，但低于下限不投； 满足比例，但高于上限的按上限投；",
    "比例投入或固定投入需满足策略本身投入限制，高于策略限制上限按策略上限投。":"比例投入或固定投入需满足策略本身投入限制，高于策略限制上限按策略上限投。",
    "启动策略":"启动策略",
    "修改模板名称":"修改模板名称",
    "请先选择交易所":"请先选择交易所",
    "价格上限/价格下限不能小于0":"价格上限/价格下限不能小于0",
    "请先选择策略":"请先选择策略",
    "当前选择模式为自定义模式，交易对数量不能超过1个":"当前选择模式为自定义模式，交易对数量不能超过1个",
    "参数配置不正确":"参数配置不正确",
    "单格投入不能为0":"单格投入不能为0",
    "首单投入不能为0":"首单投入不能为0",
    "首单金额不能为0":"首单金额不能为0",
    "单笔金额不能为0":"单笔金额不能为0",
    "投入金额不能低于最小范围":"投入金额不能低于最小范围",
    "投入金额不能大于最大范围":"投入金额不能大于最大范围",
    "可用余额不足":"可用余额不足",
    "投入百分比不能小于0%":"投入百分比不能小于0%",
    "投入百分比不能大于100%":"投入百分比不能大于100%",
    "投入下限不能低于最小范围":"投入下限不能低于最小范围",
    "投入上限不能大于最大范围":"投入上限不能大于最大范围",
    "启动成功":"启动成功",


    "范围值":"范围值",
    "可用资金百分比":"可用资金百分比",
    "可用资金比例":"可用资金比例",
    "请输入比率":"请输入比率",
    "投入下限":"投入下限",
    "请输入投入下限":"请输入投入下限",
    "投入上限":"投入上限",
    "请输入投入上限":"请输入投入上限",
    "满足比例，但高于上限的按上限投；满足比例，但低于下限不投； 满足比例，但高于上限的按上限投；":"满足比例，但高于上限的按上限投；满足比例，但低于下限不投； 满足比例，但高于上限的按上限投；",
    "创建策略模板":"创建策略模板",
    "新的模板":"新的模板",
    "买入价格/卖出价格不能小于0":"买入价格/卖出价格不能小于0",
    "模板名字不能为空":"模板名字不能为空",
    "价格上限不能低于价格下限":"价格上限不能低于价格下限",
    "首单投入不能为0%":"首单投入不能为0%",
    "首单金额不能为0%":"首单金额不能为0%",
    "单笔金额不能为0%":"单笔金额不能为0%",
    "买入价格不能为0%":"买入价格不能为0%",
    "卖出价格不能为0%":"卖出价格不能为0%",
    "买入价格不能低于卖出价格":"买入价格不能低于卖出价格",
    "投入百分比不能小于0":"投入百分比不能小于0",
    "投入百分比不能大于100":"投入百分比不能大于100",


    "已投入策略(USDT)":"已投入策略(USDT)",
    "当前策略":"当前策略",
    "策略名称":"策略名称",
    "策略模式":"策略模式",
    "实现收益":"实现收益",
    "浮动收益":"浮动收益",
    "总年化/实现年化":"总年化/实现年化",
    "状态":"状态",
    "增加子币对":"增加子币对",
    "请谨慎增加币对，避免操作失败致使实例，被大量移至未分组":"请谨慎增加币对，避免操作失败致使实例，被大量移至未分组",
    "确认终止策略？":"确认终止策略？",
    "终止成功":"终止成功",
    "没有选择币对":"没有选择币对",
    "增加币对成功":"增加币对成功",


    "策略详情":"策略详情",
    "浮动收益(USDT)":"浮动收益(USDT)",
    "增加币对":"增加币对",
    "持仓":"持仓",
    "委托冻结":"委托冻结",
    "委托订单":"委托订单",
    "成交记录":"成交记录",
    "历史记录":"历史记录",
    "成交价":"成交价",
    "暂无成交记录":"暂无成交记录",
    "历史参数":"历史参数",


    "终止订单链":"终止订单链",
    "查看订单链":"查看订单链",
    "修改订单链":"修改订单链",
    "智能订单信息":"智能订单信息",
    "执行时长":"执行时长",
    "初始投入":"初始投入",
    "当前订单":"当前订单",
    "订单方向":"订单方向",
    "订单状态":"订单状态",
    "订单链状态":"订单链状态",
    "已完成":"已完成",
    "当前委托":"当前委托",
    "委托时间":"委托时间",
    "方向":"方向",
    "委托数量":"委托数量",
    "委托价格":"委托价格",
    "订单":"订单",
    "执行动作":"执行动作",
    "成交数量":"成交数量",
    "成交金额":"成交金额",
    "成交均价":"成交均价",
    "成交价格":"成交价格",
    "终止订单":"终止订单",
    "确认终止后订单将自动撤消未成交的委托单。":"确认终止后订单将自动撤消未成交的委托单。",
    "关 闭":"关 闭",
    "张":"张",
    "做多":"做多",
    "做空":"做空",
    "平多":"平多",
    "平空":"平空",
    "等待触发":"等待触发",
    "等待成交":"等待成交",
    "成交成功":"成交成功",
    "订单异常":"订单异常",


    "共计订单&(1)条":"共计订单&(1)条",
    "已选择&(1)条":"已选择&(1)条",
    "确认强制结算后账户将被服务器接管，所有持仓自动兑换成托管币种后等待下一结算期结算。":"确认强制结算后账户将被服务器接管，所有持仓自动兑换成托管币种后等待下一结算期结算。",
    "请耐心等待，结算完成后您可在历史账户中查看。":"请耐心等待，结算完成后您可在历史账户中查看。",
    "强制结算会被记录在项目及团队评分系统中，非必要请勿进行此项操作。":"强制结算会被记录在项目及团队评分系统中，非必要请勿进行此项操作。",
    "当前账户":"当前账户",
    "历史账户":"历史账户",


    "修改备注":"修改备注",
    "请输入备注":"请输入备注",


    "订单标签：":"订单标签：",
    "交易所：":"交易所：",
    "交易类型：":"交易类型：",
    "下单账户":"下单账户",
    "可开&(1)张":"可开&(1)张",
    "可平&(1)张":"可平&(1)张",
    "暂无可用账户":"暂无可用账户",
    "启动订单链":"启动订单链",


    "批量修改":"批量修改",
    "已选择订单&(1)条":"已选择订单&(1)条",
    "更多":"更多",
    "卖出成功":"卖出成功",
    "卖出失败":"卖出失败",
    "买入失败":"买入失败",


    "创建策略模版":"创建策略模版",
    "模板排序":"模板排序",
    "自定义":"自定义",
    "多币对":"多币对",
    "添加账户":"添加账户",
    "加仓":"加仓",
    "修改模板总投入":"修改模板总投入",
    "移除策略模版":"移除策略模版",
    "开启后新分配到的账户将自动启动此策略":"开启后新分配到的账户将自动启动此策略",
    "策略：":"策略：",
    "实现收益 (USDT)":"实现收益 (USDT)",
    "已暂停":"已暂停",
    "日志":"日志",
    "暂无日志":"暂无日志",
    "策略模板还是空的，快去创建策略模板吧～":"策略模板还是空的，快去创建策略模板吧～",
    "更多>>":"更多>>",
    "终止确认":"终止确认",
    "此过程将持续几分钟，请耐心等待！您可以查看其他页面，但不可进行操作，以免数据出错。":"此过程将持续几分钟，请耐心等待！您可以查看其他页面，但不可进行操作，以免数据出错。",
    "请确认终止现有策略实例&(1)个，确认终止后，策略将自动撤销未成交的委托单，并兑换成投入币种。":"请确认终止现有策略实例&(1)个，确认终止后，策略将自动撤销未成交的委托单，并兑换成投入币种。",
    "暂停确认":"暂停确认",
    "请确认暂停现有策略实例&(1)个，确认暂停后，策略将自动挂起，不再执行新的委托，未成交的委托单继续等待成交，数据不再更新。请确认终止现有策略实例":"请确认暂停现有策略实例&(1)个，确认暂停后，策略将自动挂起，不再执行新的委托，未成交的委托单继续等待成交，数据不再更新。请确认终止现有策略实例",
    "加仓投入上限":"加仓投入上限",
    "加仓金额":"加仓金额",
    "满足比例，但高于上限的按上限投；":"满足比例，但高于上限的按上限投；",
    "比例投入或固定投入需满足策略本身投入限制，":"比例投入或固定投入需满足策略本身投入限制，",
    "高于策略限制上限按策略上限投。":"高于策略限制上限按策略上限投。",
    "删除子币对":"删除子币对",
    "模板重命名":"模板重命名",
    "修改模板投入配置":"修改模板投入配置",
    "范围值：":"范围值：",
    "定投金额":"定投金额",
    "修改模版总投入不影响已投入的策略":"修改模版总投入不影响已投入的策略",
    "新分配的用户将依据排序依次启动符合模板要求且开启自动交易的策略":"新分配的用户将依据排序依次启动符合模板要求且开启自动交易的策略",
    "模板名称":"模板名称",
    "自动交易":"自动交易",
    "排序":"排序",
    "该模板下没有执行实例":"该模板下没有执行实例",
    "确认移除模板？":"确认移除模板？",
    "当前模板没有执行策略":"当前模板没有执行策略",
    "价格下限不能小于0":"价格下限不能小于0",
    "价格上限/价格下限不能小于":"价格上限/价格下限不能小于",
    "修改参数成功":"修改参数成功",
    "投入比例不能为空":"投入比例不能为空",
    "投入比例不能小于等于0%":"投入比例不能小于等于0%",
    "投入比例不能大于100%":"投入比例不能大于100%",
    "加仓投入下限不能小于最小投入":"加仓投入下限不能小于最小投入",
    "加仓投入上限限不能大于最大投入":"加仓投入上限限不能大于最大投入",
    "加仓投入下限不能大于加仓投入上限":"加仓投入下限不能大于加仓投入上限",
    "投入金额不能为空":"投入金额不能为空",
    "投入金额不能小于0":"投入金额不能小于0",
    "请输入投入比例":"请输入投入比例",
    "投入金额不能小于最小投入":"投入金额不能小于最小投入",
    "投入金额不能大于最大投入":"投入金额不能大于最大投入",
    "至少保留一个交易对":"至少保留一个交易对",

    
    "总收益(USDT)：":"总收益(USDT)：",
    "总收益率：":"总收益率：",
    "加仓投入下限":"加仓投入下限",
    "满足比例，但低于下限不投；":"满足比例，但低于下限不投；",
    "参数不正确，请重新修改":"参数不正确，请重新修改",


    "项目状态：":"项目状态：",
    "下线结算中":"下线结算中",
    "项目保证金余额：":"项目保证金余额：",
    "审核未通过：":"审核未通过：",
    "保证金不足,初始保证金不低于&(1)VTN":"保证金不足,初始保证金不低于&(1)VTN",
    "去划转":"去划转",
    "申请审核上线":"申请审核上线",
    "申请审核修改内容":"申请审核修改内容",
    "测试口令":"测试口令",
    "重置为未上线":"重置为未上线",
    "下线结算":"下线结算",
    "入金操作：":"入金操作：",
    "保存修改":"保存修改",
    "项目可见：可见则显示在量化托管列表；不可见则不在量化托管列表显示，但可通过测试口令访问。":"项目可见：可见则显示在量化托管列表；不可见则不在量化托管列表显示，但可通过测试口令访问。",
    "可见性设置在项目上线后生效。":"可见性设置在项目上线后生效。",
    "项目可见：":"项目可见：",
    "设置":"设置",
    "托管类型：":"托管类型：",
    "全API托管":"全API托管",
    "托管币种：":"托管币种：",
    "交易币种限制":"交易币种限制",
    "最多支持20个币种，项目上线后不可修改，请谨慎选择":"最多支持20个币种，项目上线后不可修改，请谨慎选择",
    "必填":"必填",
    "用户盈利":"用户盈利",
    "的部分，要求分成比例":"的部分，要求分成比例",
    "风险赔付承诺":"风险赔付承诺",
    "选填":"选填",
    "当用户亏损":"当用户亏损",
    "的部分，承诺赔偿比例：":"的部分，承诺赔偿比例：",
    "是否在app端剔除托管时长不足7日的用户托管数据":"是否在app端剔除托管时长不足7日的用户托管数据",
    "复 制":"复 制",
    "币种：":"币种：",
    "开启后，当用户托管不足7日就退出时，app将修正累计投入、收益及人数等数据":"开启后，当用户托管不足7日就退出时，app将修正累计投入、收益及人数等数据",
    "请联系超级管理员操作！":"请联系超级管理员操作！",
    "划转成功":"划转成功",
    "项目可见：可见则显示在量化托管列表；不可见则不在量化托管列表显示，但可通过测试口令访问":"项目可见：可见则显示在量化托管列表；不可见则不在量化托管列表显示，但可通过测试口令访问",
    "生效时间之前退出的用户将不会获得赔偿":"生效时间之前退出的用户将不会获得赔偿",
    "生效实际不得大于30天":"生效实际不得大于30天",
    "至少存在一个交易所":"至少存在一个交易所",
    "输入比例不能为负":"输入比例不能为负",
    "输入比例不能大于100%":"输入比例不能大于100%",
    "币对不能为空，请先选择币种":"币对不能为空，请先选择币种",
    "币种不能为空":"币种不能为空",
    "分成上限不能为负":"分成上限不能为负",
    "最小可用保证金要求不能为负":"最小可用保证金要求不能为负",
    "投入范围限制不符合，请重新修改":"投入范围限制不符合，请重新修改",
    "盈利分成比例不符合，请重新修改":"盈利分成比例不符合，请重新修改",
    "赔付承诺生效时间不能小于0天":"赔付承诺生效时间不能小于0天",
    "亏损比例不能小于0%":"亏损比例不能小于0%",
    "亏损比例不能大于100%":"亏损比例不能大于100%",
    "赔付比例不能小于0%":"赔付比例不能小于0%",
    "赔付比例不能大于100%":"赔付比例不能大于100%",
    "确认结算后项目将从app端移除，所有账户将被服务器接管，依次终止交易后自动兑换成托管币种，并在下一结算期结算保证金。":"确认结算后项目将从app端移除，所有账户将被服务器接管，依次终止交易后自动兑换成托管币种，并在下一结算期结算保证金。",
    

    "隐藏小额币种":"隐藏小额币种",
    "开启后，价值小于10 USDT的币种将会被隐藏。":"开启后，价值小于10 USDT的币种将会被隐藏。",
    "账户类型":"账户类型",
    "统一账户":"统一账户",
    "逐仓":"逐仓",
    "全仓":"全仓",
    "同步资产":"同步资产",
    "快速兑换":"快速兑换",
    "快速平仓":"快速平仓",
    "权益":"权益",
    "可用担保资产":"可用担保资产",
    "持仓量(张)":"持仓量(张)",
    "可平量(张)":"可平量(张)",
    "开仓均价":"开仓均价",
    "担保资产率":"担保资产率",
    "预估强平价":"预估强平价",
    "收益":"收益",
    "收益率":"收益率",
    "可用":"可用",
    "共计持仓&(1)条":"共计持仓&(1)条",
    "确认快速兑换USDT后所选持仓将以taker单快速兑换为USDT。":"确认快速兑换USDT后所选持仓将以taker单快速兑换为USDT。",
    "到:":"到:",
    "从:":"从:",



    "多币対":"多币対",
    "双向":"双向",
    "投入币种：":"投入币种：",
    "复制交易":"复制交易",
    "减仓":"减仓",
    "策略信息：":"策略信息：",
    "交易数 / 买入 / 卖出":"交易数 / 买入 / 卖出",
    "交易数 / 开仓 / 平仓":"交易数 / 开仓 / 平仓",
    "持仓：":"持仓：",
    "已包含手续费成本":"已包含手续费成本",
    "合约信息：":"合约信息：",
    "账户权益":"账户权益",
    "强平价":"强平价",
    "开仓阈值":"开仓阈值",
    "交易数/买入/卖出":"交易数/买入/卖出",
    "启动":"启动",
    "历史记录：":"历史记录：",
    "减仓金额":"减仓金额",
    "可用: ":"可用: ",
    "参数设置":"参数设置",
    "倍数补仓":"倍数补仓",
    "增量补仓":"增量补仓",
    "固定间隔":"固定间隔",
    "自定义间隔":"自定义间隔",
    "阈值":"阈值",
    "网格分布":"网格分布",
    "等比网格":"等比网格",
    "等差网格":"等差网格",
    "追踪补仓":"追踪补仓",
    "预埋单补仓":"预埋单补仓",
    "一次性止盈":"一次性止盈",
    "逐单止盈":"逐单止盈",
    "追踪止盈":"追踪止盈",
    "预埋单止盈":"预埋单止盈",
    "策略开仓阈值":"策略开仓阈值",
    "其他设置":"其他设置",
    "总收益止盈":"总收益止盈",
    "总亏损止损":"总亏损止损",
    "定时自动终止":"定时自动终止",
    "买入（张）":"买入（张）",
    "价格（USDT）":"价格（USDT）",
    "开多":"开多",
    "卖出（张）":"卖出（张）",
    "开空":"开空",
    "暂无委托订单":"暂无委托订单",
    "数量 (张)":"数量 (张)",
    "暂无可加币对":"暂无可加币对",
    "确定终止后，策略将自动撤销未完成的委托单，并兑换成投入币种。":"确定终止后，策略将自动撤销未完成的委托单，并兑换成投入币种。",
    "增加币对成功参数不能小于最小范围":"增加币对成功参数不能小于最小范围",
    "网格间隔(等差)不能为0":"网格间隔(等差)不能为0",
    "价格上限不能小于0":"价格上限不能小于0",
    "单格投入不正确，请检查修改":"单格投入不正确，请检查修改",
    "首单投入不正确，请检查修改":"首单投入不正确，请检查修改",
    "首单金额不正确，请检查修改":"首单金额不正确，请检查修改",
    "单笔金额不正确，请检查修改":"单笔金额不正确，请检查修改",
    "买入价格不正确，请检查修改":"买入价格不正确，请检查修改",
    "卖出价格不正确，请检查修改":"卖出价格不正确，请检查修改",
    "策略开仓阈值不能小于0":"策略开仓阈值不能小于0",
    "策略开仓阈值不能为空":"策略开仓阈值不能为空",
    "其他设置参数不正确，请重新修改":"其他设置参数不正确，请重新修改",
    "止盈根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止盈后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。":"止盈根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止盈后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。",
    "止盈根据总收益率计算，触发止盈后系统自动撤单平仓后终止策略。":"止盈根据总收益率计算，触发止盈后系统自动撤单平仓后终止策略。",
    "止损根据总收益率计算，触发止损后系统自动撤单平仓后终止策略。":"止损根据总收益率计算，触发止损后系统自动撤单平仓后终止策略。",
    "总收益止损":"总收益止损",
    "策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。定时终止可能会有几分钟的偏差，触发后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。":"策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。定时终止可能会有几分钟的偏差，触发后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。",
    "定时终止可能会有几分钟的偏差，触发后系统自动撤单平仓后终止策略。":"定时终止可能会有几分钟的偏差，触发后系统自动撤单平仓后终止策略。",
    "止损根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止损后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。":"止损根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止损后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。",
    


    "模版标签":"模版标签",
    "选择模式":"选择模式",
    "杠杆倍数":"杠杆倍数",
    "配置投入带*符号为必填项":"配置投入带*符号为必填项",
    "投入":"投入",
    "资金比例":"资金比例",
    "资金上下限":"资金上下限",
    "选定的账户自动以此配置为模板启动策略实例，您也可以先创建模板，然后再选择账户添加到创建好的模板中以启动实例。":"选定的账户自动以此配置为模板启动策略实例，您也可以先创建模板，然后再选择账户添加到创建好的模板中以启动实例。",
    "创建策略":"创建策略",
    "参数不能小于最小范围":"参数不能小于最小范围",
    "投入不能小于最小投入范围":"投入不能小于最小投入范围",
    "投入不能大于最大投入范围":"投入不能大于最大投入范围",
    "资金上限不能大于投入最大范围":"资金上限不能大于投入最大范围",
    "资金上限不能小于投入最小范围":"资金上限不能小于投入最小范围",
    "资金下限不能小于投入最小范围":"资金下限不能小于投入最小范围",
    "资金下限不能大于投入最大范围":"资金下限不能大于投入最大范围",
    "资金下限不能大于资金上限":"资金下限不能大于资金上限",
    "资金上限不能小于资金下限":"资金上限不能小于资金下限",
    "请先输入策略投入":"请先输入策略投入",
    "参数不正确，请检查修改":"参数不正确，请检查修改",
    "投入不正确，请检查修改":"投入不正确，请检查修改",
    "止损参数不能小于0%":"止损参数不能小于0%",
    "止损参数不能大于100%":"止损参数不能大于100%",
    "止赢参数不能小于0%":"止赢参数不能小于0%",
    "策略模板名称不能为空":"策略模板名称不能为空",
    "划转不能小于0":"划转不能小于0",
    "划转不能大于可用划转金额":"划转不能大于可用划转金额",
    "1、设置固定金额时，不满足金额或数量的账户不下单；":"1、设置固定金额时，不满足金额或数量的账户不下单；",
    "2、设置比例投入，账户满足比例时，低于下限的不下单；高于上限的按上限下单。":"2、设置比例投入，账户满足比例时，低于下限的不下单；高于上限的按上限下单。",



    "多币对&(1)个":"多币对&(1)个",
    "移除模板":"移除模板",
    "总投入配置":"总投入配置",
    "重命名":"重命名",
    "添加用户":"添加用户",



    "账户数据":"账户数据",
    "总资金":"总资金",
    "已投入":"已投入",
    "资金分布":"资金分布",
    "永续":"永续",
    "快速兑换USDT":"快速兑换USDT",
    "确认选中的持仓立即兑换成投入币种？":"确认选中的持仓立即兑换成投入币种？",
    "已冻结的持仓，需要先撤销委托订单可兑换哦":"已冻结的持仓，需要先撤销委托订单可兑换哦",
    "请输入阈值":"请输入阈值",
    "平台不支持&(1)快速兑换":"平台不支持&(1)快速兑换",
    "未选中可平的持仓":"未选中可平的持仓",



    "下限选填":"下限选填",
    "上限选填":"上限选填",
    "多仓":"多仓",
    "选择全部账户":"选择全部账户",
    "展开全部账户":"展开全部账户",
    "在此处选择委托方式并设置参数。":"在此处选择委托方式并设置参数。",
    "*选择比例投入时可设置上下限限制，账户可用低于下限时不下单；高于上限的按上限下单。":"选择比例投入时可设置上下限限制，账户可用低于下限时不下单；高于上限的按上限下单。",
    "币币交易":"币币交易",
    "市价":"市价",
    "限价":"限价",
    "条件单":"条件单",
    "资金上下限":"资金上下限",
    "买入开多":"买入开多",
    "买入平空":"买入平空",
    "卖出开空":"卖出开空",
    "卖出平多":"卖出平多",
    "触发方向":"触发方向",
    "向上突破":"向上突破",
    "向下突破":"向下突破",
    "触发价格":"触发价格",
    "价格类型":"价格类型",
    "此处可选择交易所与交易类型，目前支持币币与U本位永续合约交易。":"此处可选择交易所与交易类型，目前支持币币与U本位永续合约交易。",
    "支持在多个交易所同时下单，下单时后台会自动过滤不支持的账户。":"支持在多个交易所同时下单，下单时后台会自动过滤不支持的账户。",
    "可在此处快速选择交易对，当选择多个交易所API时，行情价只显示其中一家价格，不影响实际下单。":"可在此处快速选择交易对，当选择多个交易所API时，行情价只显示其中一家价格，不影响实际下单。",
    "累计":"累计",
    "快速兑换 USDT":"快速兑换 USDT",
    "止盈":"止盈",
    "止损":"止损",
    "全部撤销":"全部撤销",
    "下单方式":"下单方式",
    "条件委托":"条件委托",
    "限价委托":"限价委托",
    "市价委托":"市价委托",
    "已成交":"已成交",
    "撤销":"撤销",
    "已撤销":"已撤销",
    "此列表只提供7日内历史委托数据":"此列表只提供7日内历史委托数据",
    "持仓 - 当前委托":"持仓 - 当前委托",
    "在此处查看持仓及委托数据。":"在此处查看持仓及委托数据。",
    "币币持仓":"币币持仓",
    "币币持仓支持快速兑换成USDT；":"币币持仓支持快速兑换成USDT；",
    "合约持仓":"合约持仓",
    "合约持仓支持快速平仓和止盈止损操作。":"合约持仓支持快速平仓和止盈止损操作。",
    "可平":"可平",
    "下单失败":"下单失败",
    "以taker单立即成交":"以taker单立即成交",
    "空仓":"空仓",
    "平仓":"平仓",
    "开仓":"开仓",
    "冻结":"冻结",
    "折合 USDT 估值":"折合 USDT 估值",
    "折合 USDT 成本":"折合 USDT 成本",
    "折合 USDT 收益":"折合 USDT 收益",
    "品种":"品种",
    "开仓均价USDT":"开仓均价USDT",
    "收益USDT":"收益USDT",
    "强平价USDT":"强平价USDT",
    "数量 | 金额":"数量 | 金额",
    "历史委托":"历史委托",
    "1、设置固定投入时，不满足金额或数量的账户不下单；":"1、设置固定投入时，不满足金额或数量的账户不下单；",
    "限价买入价格不能为空":"限价买入价格不能为空",
    "限价卖出价格不能为空":"限价卖出价格不能为空",
    "买单投入不能为空":"买单投入不能为空",
    "买单投入不能小于等于0":"买单投入不能小于等于0",
    "卖单投入不能小于等于0":"卖单投入不能小于等于0",
    "买单下限不能小于0":"买单下限不能小于0",
    "卖单下限不能小于0":"卖单下限不能小于0",
    "买单资金下限不能大于资金上限":"买单资金下限不能大于资金上限",
    "卖单资金下限不能大于资金上限":"卖单资金下限不能大于资金上限",
    "买单比例投入不能大于100%":"买单比例投入不能大于100%",
    "卖单比例投入不能大于100%":"卖单比例投入不能大于100%",
    "买单触发价格不能为空":"买单触发价格不能为空",
    "卖单触发价格不能为空":"卖单触发价格不能为空",
    "买单触发价格不能小于0":"买单触发价格不能小于0",
    "卖单触发价格不能小于0":"卖单触发价格不能小于0",
    "买单限价价格不能为空":"买单限价价格不能为空",
    "卖单限价价格不能为空":"卖单限价价格不能为空",
    "买单限价价格不能小于0":"买单限价价格不能小于0",
    "卖单限价价格不能小于0":"卖单限价价格不能小于0",
    "请选择至少一个账户":"请选择至少一个账户",
    "没有符合条件的下单账户":"没有符合条件的下单账户",
    "下单成功":"下单成功",
    "触发价格输入错误":"触发价格输入错误",
    "下单数量不能为空":"下单数量不能为空",
    "触发价格不能为空":"触发价格不能为空",
    "请先选择至少一条需要操作的数据":"请先选择至少一条需要操作的数据",
    "投入不能大于投入范围":"投入不能大于投入范围",
    "投入不能小于0":"投入不能小于0",
    "做空止盈触发价格必须低于开仓均价":"做空止盈触发价格必须低于开仓均价",
    "做空止损触发价格必须高于开仓均价":"做空止损触发价格必须高于开仓均价",
    "做多止盈触发价格必须高于开仓均价":"做多止盈触发价格必须高于开仓均价",
    "做多止损触发价格必须低于开仓均价":"做多止损触发价格必须低于开仓均价",
    "卖单投入不能为空":"卖单投入不能为空",


    
    "打开 VtradingAPP 扫一扫登录":"打开 VtradingAPP 扫一扫登录",
    "成员管理":"成员管理",
    "请输入账户":"请输入账户",
    "触发条件":"触发条件",
    "请输入价格":"请输入价格",
    "托管时间":"托管时间",
    "至":"至",
    "异常指下单时出现交易所报错信息、数据异常等；无效指无法同步，可能是结算中、失去权限、交易所断开链接等。":"异常指下单时出现交易所报错信息、数据异常等；无效指无法同步，可能是结算中、失去权限、交易所断开链接等。",
    "API状态":"API状态",
    "仓位":"仓位",
    "上一次同步时间":"上一次同步时间",
    "折合投入币种估值":"折合投入币种估值",
    "标签":"标签",
    "订单收益":"订单收益",
    "当前订单状态":"当前订单状态",
    "当前方向":"当前方向",
    "实例状态":"实例状态",
    "U本位永续合约":"U本位永续合约",
    "USDT本位永续合约":"USDT本位永续合约",


    "等待执行":"等待执行",
    "新建订单":"新建订单",
    "订单&(1)参数设置":"订单&(1)参数设置",
    "带*符号，为必填项":"带*符号，为必填项",
    "下单方向":"下单方向",
    "请输入数量":"请输入数量",
    "资金比例：":"资金比例：",
    "合约面值":"合约面值",
    "投入上下限":"投入上下限",
    "新建动作":"新建动作",
    "暂无动作、点击下方按钮创建动作":"暂无动作、点击下方按钮创建动作",
    "投入比例不能小于0":"投入比例不能小于0",
    "投入比例不能大于100":"投入比例不能大于100",
    "订单链由顺序执行的多个订单构成，可充分扩展，使您的下单计划不再受限，资金使用更高效。":"订单链由顺序执行的多个订单构成，可充分扩展，使您的下单计划不再受限，资金使用更高效。",
    "1、在上一个订单成交后，所获得的持仓投入下一个订单。":"1、在上一个订单成交后，所获得的持仓投入下一个订单。",
    "例：开启两个订单，前一订单设置的交易对为BTC/USDT，投入USDT买入BTC，成交后获得BTC，则后一个订单的投入币种为BTC，如后一订单设置的交易对也为BTC/USDT，则委托方向为卖出；如后一订单设置的交易对为ETH/BTC，则委托方向为买入。":"例：开启两个订单，前一订单设置的交易对为BTC/USDT，投入USDT买入BTC，成交后获得BTC，则后一个订单的投入币种为BTC，如后一订单设置的交易对也为BTC/USDT，则委托方向为卖出；如后一订单设置的交易对为ETH/BTC，则委托方向为买入。",
    "2、订单按顺序执行，删除其中一个，后续订单都将被删除。":"2、订单按顺序执行，删除其中一个，后续订单都将被删除。",
    "动作数最多支持5个":"动作数最多支持5个",
    "合约最多支持2个":"合约最多支持2个",
    "订单数最多支持5个":"订单数最多支持5个",
    "请为订单添加至少一个动作":"请为订单添加至少一个动作",
    "固定投入":"固定投入",
    "比例投入":"比例投入",
    "动作":"动作",

    "信号马丁":"信号马丁",
    "网格交易":"网格交易",
    "海风马丁":"海风马丁",
    "海浪网格":"海浪网格",
    "马丁追踪":"马丁追踪",
    "区间套利":"区间套利",
    "恒量定投":"恒量定投",
    "投":"投",



    "价格上限":"价格上限",
    "价格下限":"价格下限",
    "网格间隔":"网格间隔",
    "单格盈利率":"单格盈利率",
    "最大挂单数":"最大挂单数",
    "单格投入":"单格投入",
    "首单投入":"首单投入",
    "补仓金额模式":"补仓金额模式",
    "补仓金额倍数":"补仓金额倍数",
    "倍投起始单":"倍投起始单",
    "最大做单数":"最大做单数",
    "补仓间隔模式":"补仓间隔模式",
    "间隔比例":"间隔比例",
    "补仓增幅":"补仓增幅",
    "补仓反弹比例":"补仓反弹比例",
    "止盈下单模式":"止盈下单模式",
    "止盈阈值":"止盈阈值",
    "止盈回撤比例":"止盈回撤比例",
    "开始交易信号":"开始交易信号",
    "停止交易信号":"停止交易信号",
    "投入缩放率":"投入缩放率",
    "间隔缩放率":"间隔缩放率",
    "盈利率缩放率":"盈利率缩放率",
    "首单金额":"首单金额",
    "金额追投倍数":"金额追投倍数",
    "追投间隔":"追投间隔",
    "追投反弹比例":"追投反弹比例",
    "止盈回撤":"止盈回撤",
    "回撤调整":"回撤调整",
    "买入价格":"买入价格",
    "卖出价格":"卖出价格",
    "定投周期":"定投周期",
    "单笔金额":"单笔金额",
    "第1次补仓比例":"第1次补仓比例",
    "第2次补仓比例":"第2次补仓比例",
    "第3次补仓比例":"第3次补仓比例",
    "第4次补仓比例":"第4次补仓比例",
    "第5次补仓比例":"第5次补仓比例",
    "第6次补仓比例":"第6次补仓比例",
    "第7次补仓比例":"第7次补仓比例",
    "第8次补仓比例":"第8次补仓比例",
    "补仓金额增量":"补仓金额增量",
    "强制平仓信号":"强制平仓信号",
  }
}

export default cn

//       $t('m.搜索')