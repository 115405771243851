<template>
  <div class="teamSetting" v-if="defaultTeam">
    <div class="top">
      <div class="left">
        <span>{{$t('m.团队状态：')}}</span>
        <span class="type yellow" v-if="defaultTeam.contentStatus == 'review'">
          {{$t('m.审核中')}}</span>
        <span
          class="type grey"
          v-if="defaultTeam.status == 'notActivate' && defaultTeam.contentStatus != 'review'">
          {{$t('m.未审核')}}</span>
        <span
          class="type blue"
          v-if="defaultTeam.status == 'activate' && defaultTeam.contentStatus != 'review'">
          {{$t('m.已审核')}}</span>
      </div>
      <div class="center" v-if="defaultTeam.des && defaultShow == true">
        <span>{{ $t('m.审核未通过 原因：') + defaultTeam.des }}</span>
      </div>
      <div class="right">
        <span
          class="type blue"
          v-if="(defaultTeam.content_draft || defaultTeam.status == 'notActivate') && defaultTeam.contentStatus !== 'review'"
          @click="activationTeam()">
          {{$t('m.申请审核')}}</span>
        <span
          class="type white"
          v-if="defaultTeam.contentStatus == 'review'"
          @click="withdrawApply()">
          {{$t('m.撤回申请')}}</span>
      </div>
    </div>

    <div class="bottom">
      <div class="service-object">
        <!-- <div class="title">
          <span>服务对象</span>
          <span class="tips">（审核通过后不可修改）</span>
          <span class="update">修改</span>
        </div> -->
        <div class="service">
          <span>{{$t('m.您的团队服务对象')}}</span>
        </div>
        <div class="cont" v-if="defaultTeam.platType == 'app'">
          <div>{{$t('m.Vtrading App用户')}}</div>
          <div>{{$t('m.由Vtrading提供结算解决方案')}}</div>
        </div>

        <div class="cont" v-else>
          <div>{{$t('m.嵌入式系统平台用户')}}</div>
          <div>{{$t('m.由平台方提供结算解决方案')}}</div>
        </div>
      </div>

      <div class="team-info">
        <div class="team-title">
          <span>{{$t('m.团队信息')}}</span>
          <span class="draft" v-if="defaultTeam.content_draft">
            {{$t('m.草稿')}}</span>
          <span class="update" v-if="!isUpdate" @click="isUpdate = true">
            {{$t('m.修改')}}</span>
          <span class="update" v-if="isUpdate" @click="ModifySubmit()">
            {{$t('m.保存')}}</span>
        </div>
        <div class="team-name">
          <div class="title">
            <span>{{$t('m.团队名称')}}</span>
            <span class="tips">({{$t('m.限8个字符')}})</span>
          </div>
          <el-input
            v-model="teamName"
            :placeholder="$t('m.请输入内容')"
            :disabled="!isUpdate">
          </el-input>
        </div>
        <div class="team-introduction">
          <div class="title">
            <span>{{$t('m.团队介绍')}}</span>
            <span class="tips">({{$t('m.限500字符')}})</span>
          </div>
          <el-input
            type="textarea"
            :placeholder="$t('m.请输入内容')"
            v-model="teamIntroduct"
            :maxlength="500"
            resize="none"
            :rows="8"
            :disabled="!isUpdate">
          </el-input>
        </div>
      </div>
    </div>

    <!-- 解散团队 -->
    <el-dialog
      title="解散团队"
      class="deleteTeam"
      :visible.sync="deleteTeam"
      width="460px"
      :close-on-click-modal="false"
      top="260px">
      <span style="color: #5e5f64; font-size: 14px">
        {{$t('m.解散团队操作一经确认，不可恢复呦')}}</span>
      <input
        type="text"
        :placeholder="$t('m.请输入安全验证码(谷歌验证码)')"
        style="
          border: 0;
          border-bottom: 2px solid #e6e7ed;
          font-size: 12px;
          width: 421px;
          margin-top: 10px;
          padding: 5px 0;
        "
        v-model="deleteTeamData"/>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="padding: 0"
          class="dialogBtn"
          @click="deleteTeam = false">
          {{$t('m.取 消')}}</el-button>
        <el-button
          class="dialogBtn"
          style="background: #f56c6c; border: 0; padding: 0"
          type="primary"
          @click="deleteTeamConfirm()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
    <!-- 解散团队但是无权限 -->
    <el-dialog
      :title="$t('m.抱歉，无法解散团队')"
      :visible.sync="deleteTeamVip"
      width="30%"
      :close-on-click-modal="false"
      top="270px"
    >
      <span style="font-size: 14px">
        {{$t('m.您的团队下存在项目未删除，无法进行操作。')}}</span>
      <span slot="footer" class="dialog-footer btn">
        <span @click="deleteTeamVip = false">{{$t('m.知道啦')}}</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkGACode,
  dissolutionTeam,
  findTeamProjectCount,
  updateTeamMessage,
  withdrawApply,
  findTemaMessage,
  applyContentExamine,
  activationTeam,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      defaultTeam: null,
      teamName: null,
      teamIntroduct: null,

      isUpdate: false,

      deleteTeam: false, //解散团队
      deleteTeamData: null, //谷歌验证码
      deleteTeamVip: false, //解散团队没权限
      projectTeamList: [],
      account: null,
      defaultShow: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取团队信息
    findTemaMessage() {
      if (!sessionStorage.getItem("defaultTeam")) {
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: JSON.parse(sessionStorage.getItem("defaultTeam")).teamId,
      };
      findTemaMessage(payload).then((res) => {
        if (res.status == 200) {
          this.defaultTeam = res.data.TeamMessage;
          if (
            res.data.TeamMessage.status == "notActivate" &&
            res.data.TeamMessage.contentStatus != "review"
          ) {
            this.defaultShow = true;
            sessionStorage.getItem("defaultShow", true);
          }
          this.teamIntroduct = res.data.TeamMessage.teamIntroduct;
          this.teamName = res.data.TeamMessage.teamName;
          sessionStorage.setItem(
            "defaultTeam",
            JSON.stringify(res.data.TeamMessage)
          );
          this.findTeamProjectCount();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //保存修改团队介绍
    ModifySubmit() {
      if (!this.teamIntroduct) {
        this.$message.error(this.$t('m.团队介绍不能为空！'));
        return;
      }
      if (!this.teamName) {
        this.$message.error(this.$t('m.团队名称不能为空！'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        teamIntroduct: this.teamIntroduct,
        teamName: this.teamName,
      };
      updateTeamMessage(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.isUpdate = false;
          this.$message.success(this.$t('m.操作成功'));
          this.findTemaMessage();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 审核内容/激活团队
    activationTeam() {
      if (!this.teamIntroduct) {
        return this.$message.error(this.$t('m.请输入团队内容'));
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        teamName: this.teamName,
        teamIntroduct: this.teamIntroduct,
      };

      activationTeam(payload).then((res) => {
        if (res.status == 200) {
          this.findTemaMessage();
          this.defaultShow = false;
          sessionStorage.setItem("defaultShow", false);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 撤回申请
    withdrawApply() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      withdrawApply(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.操作成功'));
          this.findTemaMessage();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 查询团队下的项目
    findTeamProjectCount() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      findTeamProjectCount(payload).then((res) => {
        if (res.status == 200) {
          this.projectTeamList = res.data.projectCount;
        }
      });
    },
    // 解散团队
    dissolutionTeam(payload) {
      payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      dissolutionTeam(payload).then((res) => {
        if (res.status == 200) {
          this.deleteTeamData = null;
          let list = JSON.parse(sessionStorage.getItem("MessageTemaList"));

          for (let i = 0; i < list.length; i++) {
            if (list[i].teamId == this.defaultTeam.teamId) {
              list.splice(i, 1);
            }
          }

          sessionStorage.setItem("MessageTemaList", JSON.stringify(list));
          sessionStorage.setItem("defaultTeam", JSON.stringify(list[0]));

          this.$message.success(this.$t('m.解散团队成功'));
          this.$router.push({ path: "/" });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 解散团队按钮
    deleteTeamBtn() {
      if (this.projectTeamList.length !== 0) {
        this.deleteTeamVip = true;
      } else {
        this.deleteTeam = true;
      }
    },
    // 确认解散团队按钮
    deleteTeamConfirm() {
      if (!this.deleteTeamData) {
        this.$message.error(this.$t('m.请输入GA'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        gaCode: this.deleteTeamData,
        phone: this.account.phone ? this.account.phone : this.account.email,
      };
      checkGACode(payload).then((res) => {
        if (res.status == 200) {
          this.dissolutionTeam();
        } else {
          this.$message.error(res.msg);
        }
      });
      this.deleteTeam = false;
    },
  },
  created() {
    this.defaultShow = sessionStorage.getItem("defaultShow");
  },
  mounted() {
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    this.findTemaMessage();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.teamSetting {
  background: #fff;
  padding-left: 40px;
  padding-top: 20px;
  height: 100%;
  box-sizing: border-box;
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 20px;
    margin-bottom: 40px;
    .left {
      display: flex;
      align-items: center;
      .type {
        display: inline-block;
        // width: 62px;
        // height: 22px;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        border-radius: 4px;
        padding: 1px 8px;
      }
      .grey {
        color: #55698a;
        background: #e7e9ee;
      }
      .yellow {
        background: #f9efe2;
        color: #ff9600;
      }
      .blue {
        color: #2174ff;
        background: rgba(33, 116, 255, 0.05);
      }
    }
    .center {
      width: 800px;
      height: 40px;
      line-height: 40px;
      font-size: 12px;
      background: rgba(222, 37, 37, 0.05);
      border-radius: 3px;
      border: 1px solid rgba(222, 37, 37, 0.08);
      color: rgba(222, 37, 37, 1);
      text-indent: 20px;
    }
    .right {
      padding-right: 40px;
      .type {
        display: inline-block;
        padding: 5px 14px;
        font-size: 14px;
        margin-right: 20px;
        border-radius: 4px;
        cursor: pointer;
      }
      .blue {
        background: #2174ff;
        color: #fff;
      }
      .red {
        color: #ff3a3a;
        border: 1px solid #ff3a3a;
      }
      .white {
        color: #2174ff;
        border: 1px solid #2174ff;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    .team-info {
      width: 800px;
      .team-title {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
        border-bottom: 1px solid #f3f4f6;

        // :first-child{
        //     color: #102859;
        // }
        .draft {
          display: inline-block;
          width: 41px;
          height: 18px;
          border-radius: 10px;
          border: 1px solid rgba(33, 116, 255, 0.3);
          color: #2174ff;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
          margin-left: 10px;
          font-size: 12px;
        }
        .update {
          font-size: 12px;
          color: #2174ff;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .team-name {
        margin: 20px 0;
        .title {
          margin-bottom: 12px;
          .tips {
            color: #55698a;
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }
      .team-introduction {
        margin-bottom: 40px;
        .title {
          margin-bottom: 12px;
          .tips {
            color: #55698a;
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }
    }
    .service-object {
      width: 800px;
      //   margin-top: 30px;
      margin-bottom: 30px;
      .title {
        .tips {
          font-size: 12px;
          color: #102859;
          margin: 0 10px;
        }
        .update {
          color: #2174ff;
          font-size: 12px;
        }
      }
      .service {
        margin: 30px 0 20px 0;
      }
      .cont {
        width: 202px;
        height: 50px;
        background: #f9fafc;
        border-radius: 4px;
        padding: 14px 0 14px 20px;
        font-size: 14px;
        color: #55698a;
        :last-child {
          padding-top: 10px;
          font-size: 12px;
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btn {
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  span {
    width: 70px;
    height: 33px;
    background: #66b1ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
}
</style>