import { render, staticRenderFns } from "./projectList.vue?vue&type=template&id=206c7836&scoped=true&"
import script from "./projectList.vue?vue&type=script&lang=js&"
export * from "./projectList.vue?vue&type=script&lang=js&"
import style0 from "./projectList.vue?vue&type=style&index=0&id=206c7836&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206c7836",
  null
  
)

export default component.exports