//日语
const en = {
  m: {
    "langs":'ja',

    "新手必读":"初心者向け",
  }
}

export default en
