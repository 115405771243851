//英文
const en = {
  m: {
    "langs":'en',


    "实例编号":"Number",
    "Vtrading APP用户":"Vtrading APP users",
    //----------------------------------------核心 9
    "加载中":"Loading",
    "新手操作":"Novice operation",
    "创建团队":"Create team",
    "团队设置":"Team settings",
    "设置团队信息":"Set up team information",
    "补充团队保证金":"Supplementary team margin",
    "提交团队审核":"Submit to team for review",
    "等待审核通过":"Waiting for approval",
    "创建项目":"Create project",
    "项目设置":"Project settings",
    "设置基本信息、托管限制及分成赔付":"Setting basic information、escrow restrictions and split compensation ",
    "补充项目保证金":"Supplementary Project margin",
    "提交项目审核":"Submit project for review",
    "项目审核通过":"Project approved",
    "查看成员权限":"View member permissions",
    "邀请成员加入团队":"Invite members to join the team",
    "保证金":"Margin",
    "转入团队保证金":"Transfer to team margin",
    "划转保证金到项目":"Transfer margin to project",


    "创建团队名称":"Create team name",
    "选择服务对象":"Select service object",
    "完成":"Done",
    "团队名称":"Team name",
    "选择您的团队服务对象":"Select your team service object",
    "绑定平台标识":"Bind platform ID",
    "请输入您的团队名称,限8个字符":"Please enter your team name，limited to 8 characters",
    "Vtrading App用户":"Vtrading APP user",
    "由Vtrading提供结算解决方案":"Settlement solution provided by Vtrading",
    "嵌入式系统平台用户":"Embedded system platform user",
    "由平台提供结算解决方案":"Settlement solution provided by the platform",
    "您可以联系平台方获取标识":"You can contact the platform to get the logo",
    "未查询到平台，请联系平台方确认":"Platform not found， please contact the platform to confirm",
    "创建":"Create",
    "请先输入团队名称":"Please enter the team name first",
    "团队名称限8个字符":"Team name is limited to 8 characters",
    "团队名称已存在，不能重名！":"The team name already exists，can not be repeated！",
    "请输入appId":"Please enter appId",
    "创建成功":"Created successfully",
    "下一步":"Next step",
    "上一步":"Previous step",


    "创建新团队":"Create a new team",
    "项目列表":"Project list",
    "托管项目":"Managed project",
    "团队成员":"Team members",
    "仅超级管理员可见":"Visible only to Super Admin",
    "首页":"Homepage",
    "退出登录":"Logout",
    "限6个字符":"Limited to 6 characters",
    "请输入内容":"Please enter content",
    "团队介绍":"Team introduction",
    "限500个字符":"Limited to 500 characters",
    "取 消":"Cancel",
    "确 定":"Confirm",
    "请简单介绍一下这个团队吧…":"Please give a brief introduction to this team…",
    "您没有权限使用该功能，敬请谅解！":"You do not have permission to use feature，please understand！",
    "额，好像没有权限":"Well，there seems to be no authority",
    "知道啦":"Got it",
    "未审核":"Unaudited",
    "审核中":"Under review",
    "已审核":"Audited",
    "退出成功":"Quit successfully",
    "团队名称不能超过6个字符":"Team name can not exceed 6 characters",
    "团队介绍不能为空":"Team introduction can not be empty",
    "团队名称不能为空":"Team name can not be empty",
    "修改成功":"Modified successfully",


    "扫码登录":"Login",
    "二维码有效期":"QR code validity period",
    "刷新二维码":"Refresh QR Code",
    "打开 Vtrading APP 扫一扫登录":"Open Vtrading APP and scan login",
    "安全验证":"Security verification",
    "为了您的账户安全，请完成以下验证操作":"For the security of your account，please complete the following verification operations",
    "谷歌验证码":"Google CAPTCHA",
    "请输入6位谷歌验证码":"Please enter 6-digit Google CAPTCHA",
    "安全验证未绑定":"Security authentication is not bound",
    "为了确保账户安全，请先前往APP端-安全中心绑定谷歌验证器。":"To ensure account security，please go to the app side-security Center to bind Google verifier。",
    "请输入GA":"Please enter GA",
    "登录成功":"Login successful",
    "确认关闭？":"Confirm the shutdown？",
    "提 交":"Submit",


    "同步全部资产":"Synchronize all assets",
    "策略交易":"Strategic trading",
    "创建模板":"Create template",
    "策略":"Strategy",
    "智能订单":"Smart order",
    "快捷交易":"Fast transaction",
    "创建订单链":"Create order chain",
    "订单链":"Order chain",
    "持仓组合":"Position combination",
    "我管理的账户":"The account I manage",
    "项目管理的账户":"Account for project management",
    "信号中心":"Signal center",
    "交易数据监测":"Transaction data monitoring",
    "项目成员":"Project member",
    "消息中心":"Message center",
    "同步成功":"Synchronization successful",


    "项目详情":"Project details",


    "项目数据":"Project data",
    "我的用户":"My users",
    "用户列表":"User list",


    "高级设置":"Advanced settings",
    "操作成功":"Operation successful",


    //----------------------------------------projectSetup
    "配置设置":"Configuration settings",
    "正式上线后不可修改":"Can not be modified after official launch",
    "账户":"Accounts",
    "暂时只支持币币":"Only currencies are supported for the currencies",
    "投入币种限制":"Input currency restrictions",
    "暂时只支持USDT投入":"Only support USDT input for the time being",
    "投入资金限制":"Restrictions on investment",
    "范围":"Range",
    "未上线":"Offline",
    "已上线":"Online",
    "结算中":"Settlement",
    "交易所限制":"Exchange restrictions",
    "请选择":"Please select",
    "交易对限制":"Trading to restrictions",
    "佣金形式":"Form of commission",
    "盈利分成(必填)":"Profit share (required)",
    "用户 盈利 &{1} 的部分，要求分成比例":"Part of user profit & {1}，required to be in proportion",
    "请输入":"Please enter",
    "每次分成上限":"The upper limit of each share",
    "最小可用保证金要求":"Minimum available margin requirement",
    "当用户 亏损 &{1} 的部分，承诺赔偿比例":"When the user loses & {1}，the proportion of promised compensation",
    "赔付承诺生效时间":"Effective time of promise of compensation",
    "请输入时间":"Please enter time",
    "天":"Heaven",
    "保 存":"Conserve",
    "基本信息":"Basic information",
    "申请上线时将对内容进行审核":"The content will be reviewed when the application is launched",
    "项目名称":"Project name",
    "项目简介":"Project profile",
    "项目简介内容在上架时需提交审核":"The content of the project profile should be submitted for review when it is put on the shelves",
    "项目操作":"Project operation",
    "项目状态":"Project status",
    "项目阶段":"Project phase",
    "入金操作":"Deposit operation",
    "开放入金":"Open deposit",
    "暂停入金":"suspension of deposit",
    "项目可见":"Project visible",
    "可见":"Visible",
    "不可见":"Invisible",
    "测试V口令":"Test V password",
    "结算":"Settlement",
    "申请上线":"Apply online",
    "币币":"currency",
    "项目可见：可见则展示在量化托管列表；不可见则不在量化托管列表展示，但可通过测试V口令访问":"Items visible： visible is displayed in quantitative managed list； invisible is not shown in quantitative managed list， but can be accessed by testing V password",
    "入金操作：开放入金时，用户可以将资金投入项目，暂停入金时，无法投入资金":"Deposit operation：when deposit is open，user can put the money into the project，but when deposit is suspended，user can not invest the money",
    "生效时间之前退出的将不会获得赔偿":"Those who withdraw before the effective time will not receive compensation",
    "[V口令]":"[V password]",
    "打开Vtrading，跟着量化大V一起飞":"Open Vtrading，fly with quantized Big V",
    "确认结算后，项目将开始结算清盘，并从量化托管列表移除":"After confirming the settlement，the project will be liquidated and removed from the quantitative escrow list",
    "确定":"OK",
    "取消":"Cancel",
    "V口令已复制，去粘贴给好友吧":"V password has been copied，go and paste it to your friends",
    "最小投入资金不能低于最小范围":"The minimum investment cannot be lower than the minimum range",
    "最大投入资金不能大于最大范围":"The maximum investment cannot be greater than the maximum range",
    "最小投入资金不能大于最大投入资金":"The minimum investment can not be greater than the maximum",
    "盈利比例不能为负":"Profit ratio can not be negative",
    "盈利分成比例不能为负":"Profit share can not be negative",
    "盈利分成比例不能超过50%":"Profit share can not exceed 50%",
    "盈利分成上限不能为负":"Profit sharing limit can not be negative",
    "最小保证金不能为负":"Minimum margin can not be negative",
    "亏损赔付不能为负":"Loss compensation can not be negative",
    "亏损比例不能为负":"Loss ratio can not be negative",
    "赔付生效时间不能小于0":"The effective time of compensation can not be less than 0",
    "赔付承诺生效时间不能大于30天":"The effective time of the promise of compensation can not be more than 30 days",
    "设置成功":"Set successfully",
    "输入值不能为负数":"Input value cannot be negative",
    "结算完成":"Settlement completed",
    "最少选择一个交易所":"Choose at least one exchange",
    "项目介绍不能为空！":"The project introduction can not be empty！",
    "最少选择一个交易对":"Choose at least one EOS/ETH",


    "产品使用许可":"Product license",
    "团队缴纳相应保证金，通过官方审核后即可对用户提供量化托管服务，在项目运行期内，平台将收取：":"Team pays the corresponding deposit，it can provide quantitative hosting services to users after official examination， during the running period of the project，the platform will charge：",
    "1、每托管账户不超过账户盈利10%的分成费，费用在账户退出结算时自动扣取，亏损账户无需支付；":"1、Each escrow account dose not exceed 10% of the profit of the account，the fee shall be deducted when withdraws from settlement，the loss account does not need to be paid；",
    "2、每日不超过&(1)的托管资金管理费。":"2、The daily management fee of escrow funds shall not exceed 0.01%。",
    "产品推广周期内部分或全额减免支付分成费和管理费，具体比例以平台页面为准。":"The sharing fee and management fee partially or fully waived or waived during the product promotion cycle，the specific proportion shall prevail on the platform page。",
    "当前团队需支付的账户收益分成费比例为0%，资金管理费为0%。":"At present，the proportion of account income sharing fee paid by the team is 0%，the fund management fee is 0%。",
    "团队保证金":"Team margin",
    "团队初始激活保证金不低于&{1}VTN":"Team initial activation deposit is not less than & {1} VTN",
    "初始保证金":"Initial margin",
    "保证金余额":"Margin balance",
    "操作":"Operation",
    "补充保证金":"Supplementary margin",
    "提取保证金":"Margin withdrawal",
    "明细":"Details",
    "项目保证金":"Project margin",
    "项目初始激活保证金不低于 10000VTN":"The initial activation deposit of the project is not less than 10000VTN",
    "项目":"Project",
    "未结算":"Unsettled",
    "已结算":"Settled",
    "可划转":"Transferable",
    "划转":"Rowing",
    "交易授权":"Transaction authorization",
    "请在Vtrading APP上确认操作或 使用APP扫描下方二维码":"Please confirm the operation on Vtrading APP or use APP to scan the QR code below",
    "二维码已失效":"The QR code has expired",
    "点击刷新":"Click refresh",
    "激活团队":"Activate team",
    "全部项目":"All projects",
    "余额= 初始+已结算":"Balance = initial + settled ",
    "强制结算":"Compulsory settlement",
    "余额≤0 或 (余额+未结算)≤0 时项目将被强制清算下线。":"When the balance ≤ 0 or (balance + unsettled) ≤ 0，the project will be forced to be liquidated offline。",
    "实际为减少风险，当 (余额+未结算)/项目最低初始激活保证金≤20%时，将提前结算。":"In order to reduce risk，when (balance + unsettled) / the minimum initial activation deposit of the project is less than 20%，it will be settled in advance。",
    "强制结算时先结算盈利分成部分，剩余金额用于结算亏损赔付，不足以支付亏损赔付的由亏损方根据亏损权重分摊":"When making a compulsory settlement，profit is divided into parts and the remaining amount is used to settle the loss compensation，if not enough to pay the loss compensation, the loss party shall share it according to the loss weight",
    "其他：":"Other",
    "用户退出时将整点结算，结算时操作员不可操作":"Settlement will be made on the hour when the user exits，the operator can not operate when the settlement occurs",
    "亏损赔付时优先使用已结算的VTN":"Priority is given to the settled VTN when paying losses",
    "提取保证金时优先转出初始的USDT":"Priority is given to transferring the initial USDT when withdrawing margin",
    "请选择币种":"Please select currency",
    "金额":"Amount",
    "请输入金额":"Please enter amount",
    "可提：":"Can be mentioned",
    "从":"From",
    "请选择项目账户":"Please select project account",
    "到":"To",
    "币种":"Currency",
    "划转数量":"Transferred quantity",
    "全部":"All",
    "可用划转":"Available rowing",
    "转入项目的USDT将自动按汇率兑换成VTN，并在转出时原价兑成USDT":"The USDT transferred to the project will be automatically converted into VTN at the exchange rate， the original price will be converted into USDT at the time of transfer",
    "划 转":"Rowing",
    "由超级管理员的保证金账户补充和项目保证金转入":"Supplementary from Super Admin's margin account and transfer of project deposit",
    "可用于保证金结算的余额，余额 = 转入 + 转出 + 已结算":"Balance available for margin settlement，balance = transfer + transfer + settled",
    "未结算的盈利分成 + 亏损赔付 + 盈利分成费，嵌入式系统不计算盈利分成和亏损赔付":"Unsettled profit sharing + loss compensation + profit sharing fee，the embedded system does not calculate profit sharing and loss compensation",
    "已结算的盈利分成 + 亏损赔付 + 盈利分成费 +资金管理费，嵌入式系统不计算盈利分成和亏损赔付":"Settled profit sharing + loss compensation + profit sharing fee + fund management fee，embedded system does not calculate profit sharing and loss compensation",
    "可提取的保证金，优先提取初始余额结余部分， 提取此部分将自动返回保证金账户，其他部分将提至收益账户，当存在保证金项目时，不可提取保证金":"For the margin that can be withdrawn，priority will be given to the initial balance，which will be automatically returned to the margin account，and the rest will be raised to the income account，when there is a margin item，the margin can not be withdrawn",
    "优先提取初始余额结余部分，提取的USDT将自动返回保证金账户，提取的VTN将提至收益账户":"Priority is given to the initial balance，extracted USDT will be automatically returned to the margin account and the extracted VTN will be raised to the income account",
    "从保证金账户转入，暂时只支持USDT":"Transfer from margin account，only USDT is supported",
    "当项目状态为未上线、已下线时，可划转全部余额；当项目状态为审核中、已上线时，可划转 = min（余额，余额 + 未结算）- 10000 VTN；当项目状态为结算中时，可划转数量为0。":"When the project status is not online or offline， all balances can be transferred；when the project status is under review and has been online，can be transferred = min (balance, balance + unsettled)-10000 VTN；when the project status is settlement，the transferable quantity is 0。",
    "团队账户":"Team account",
    "划转数量不能为空":"Transfer quantity cannot be empty",
    "划转数量不能小于0":"The number of transfers cannot be less than 0",
    "划转数量不能超出可划转数":"The number of transfers can not exceed the number of transfers",
    "团队初始激活保证金不低于 10000VTN，请补充保证金":"The initial activation deposit of the team is not less than 10000VTN，please supplement the deposit",
    "确定激活团队?":"Are you sure to to active the team?",
    "激活团队?":"Active team?",
    "输入金额必须大于0":"Input amount must be greater than 0",
    "输入金额不能大于可提金额":"The input amount can not be greater than the withdrawable amount",


    "团队保证金明细":"Details of team margin",
    "筛选类型":"Filter type",
    "时间":"Time",
    "类型":"Type",
    "转入至项目":"Transfer to project ",
    "项目转出":"Project transfer out",
    "备注":"Remarks",
    "折合":"Equivalent",
    "标记汇率":"Marked exchange rate",
    "转出":"Transfer out",
    "转入":"Transfer",
    "保证金[VTN]":"Margin [VTN]",
    "亏损赔付":"Loss compensation",
    "盈利分成":"Profit sharing",
    "资金管理费":"Fund management fee",
    "收益分成费":"Income sharing fee",
    "项目保证金明细":"Details of project deposit",


    "最多支持20个币对，项目上线后不可修改，请谨慎选择":"A maximum of 20 currency pairs are supported，which cannot be modified after the project is launched，please choose carefully",
    "添加":"Add",
    "已经存在所选交易对":"The selected deal pair already exists",
    "最多支持20个交易对":"Up to 20 trading pairs are supported",
    "保存成功":"Saved successfully",


    "项目信息":"Project information",
    "审核失败":"Audit failed",
    "已下线":"Offline",
    "请输入项目名称":"Please enter project name",
    "限20个字符":"Limited to 20 characters",
    "请输入项目简介":"Please enter project profile",
    "项目名称不能为空":"Project name can not be empty",


    "退出项目":"Exit the project",
    "删除项目":"Delete item",
    "入金中":"Enter the gold",
    "管理员":"Administrator",
    "您暂时没有上线项目呦":"You do not have an online project for the time being",
    "您暂时没有未上线项目呦":"You have no unlaunched project for the time being",
    "您暂时没有项目呦":"You don't have a project at the moment",
    "暂无项目，点击下方创建项目吧～":"There is no project yet，click below to create a project～",
    "什么是量化托管服务？":"What is quantitative managed service？",
    "量化托管是一个快速、高效的批量策略交易平台，面向专业的策略交易团队或个人、解决小白用户策略工具使用痛点。提供从项目创建、发布、用户入金、资金托管到收益结算、团队管理等服务。降低项目开发成本，让团队专注策略交易。":"Quantitative escrow is a fast、efficient batch strategy trading platform for professional strategy trading teams or individuals to solve the pain points of novice users' strategy tools。Provide services from project creation、release、user deposit、fund escrow to revenue settlement、team management and other services。Reduce project development costs and let the team focus on strategic trading。",
    "操作手册":"Operation manual",
    "请输入项目名字":"Please enter the project name",
    "限8个字符":"limited to 8 characters",
    "超级管理员":"Super admin",
    "额，好像没有权限":"Well，there seems to be no authority",
    "项目名称不能超过6个字符":"Project name can not exceed 6 characters",
    "退出项目后，该成员所属的用户将被分配给项目管理员":"After exiting the project，the user to which the member belongs will be assigned to the project administrator",
    "删除项目操作一经确认，不可恢复，请谨慎操作。":"Once the deletion of the project is confirmed，it can not be resumed，please proceed with caution。",
    "已取消删除":"Undeleted",


    "成员权限":"Member permissions",
    "成员":"Member",
    "账号":"Account",
    "权限":"Permission",
    "默认":"Default",
    "设置为默认操作员":"Set as default operator",
    "设置为管理员":"Set as administrator",
    "移出项目":"Remove project",
    "邀请成员":"Invite members",
    "选择":"Select",
    "团队唯一":"Team unique",
    "创建/删除项目":"Create/delete project",
    "管理团队成员":"Manage team members",
    "兼容管理员":"Compatible administrator",
    "项目唯一":"Project unique",
    "管理项目":"Manage projects",
    "分流用户":"Diversion user",
    "兼容操作员":"compatible operator",
    "操作员":"Operator",
    "配置自动交易":"Configure automatic transactions",
    "批量操作":"Batch operation",
    "入金用户将自动分配给默认操作员":"The deposit user will be automatically assigned to the default operator",
    "移出成功":"Removed successfully",


    "结算明细":"Settlement details",
    "退出时间":"Exit time",
    "入金时间":"Deposit time",
    "用户":"User",
    "总投入(USDT)":"Total input(USDT)",
    "总投入(KRW)":"Total input(KRW)",
    "总收益(USDT)":"Total income(USDT)",
    "总收益(KRW)":"Total income(KRW)",
    "总收益率":"Total rate of return",
    "实际结算金额(VTN)":"Actual settlement amount (VTN)",


    "团队状态：":"Team status：",
    "审核未通过 原因：":"Reason why the audit failed",
    "申请审核":"Application review",
    "撤回申请":"Withdrawal of application",
    "您的团队服务对象":"Your team service object",
    "由平台方提供结算解决方案":"Settlement solution provided by the platform",
    "团队信息":"Team information",
    "草稿":"Draft",
    "修改":"Modify",
    "保存":"Save",
    "限500字符":"Limited to 500 characters",
    "解散团队操作一经确认，不可恢复呦":"Once the operation of disbanding the team is confirmed，it can not be resumed",
    "请输入安全验证码(谷歌验证码)":"Please enter security CAPTCHA (Google CAPTCHA)",
    "抱歉，无法解散团队":"Sorry，unable to dissolve the team",
    "您的团队下存在项目未删除，无法进行操作。":"There are items under your team that have not been deleted，can not be operated。",
    "团队介绍不能为空！":"The team introduction can not be empty！",
    "团队名称不能为空！":"The team name can not be empty！",
    "请输入团队内容":"Please enter team content",
    "解散团队成功":"Successful dissolution of the team",


    //----------------------------------------teamSetup 2
    "成员列表":"Member list",
    "添加成员":"Add member",
    "用户名":"User name",
    "我":"Me",
    "最后操作":"Final operation",
    "无":"Nothing",
    "退出团队":"Quit the team",
    "移出团队":"Move out of the team",
    "成员设置":"Member settings",
    "团队项目":"Team project",
    "不能修改超级管理员角色":"The super admin role can not be modified",
    "您没有权限使用该功能，":"You do not have permission to use this feature，",


    "团队状态":"Team status",
    "未激活":"Not activated",
    "已激活":"Activated",
    "审核反馈：":"Audit feedback",


    "这里是":"This is",
    "解散团队":"Disband the team",
    "全部成员":"All members",
    "未加入项目成员":"Did not join project members",
    "项目组":"Project team",
    "通过链接邀请":"Invite through a link",
    "链接有效期1小时":"The link is valid for 1 hour",
    "复制链接":"Copy link",
    "解散团队操作一经确认，不可恢复呦":"Once the operation of disbanding the team is confirmed，it can not be resumed",
    "请输入安全验证码(谷歌验证码)":"Please enter security CAPTCHA (Google CAPTCHA)",
    "复制成功":"Copy successfully",
    "请先提取团队保证金，再尝试解散团队":"Please withdraw the team deposit before you try to disband the team",
    
    
    //----------------------------------------projectManagement 31
    "可用金额":"Available amount",
    "账户列表&(1)条，已选择&(2)条":"Account list & (1)，selected & (2)",
    "请先选择账户":"Please select an account first",


    "智能交易":"Smart trading",
    "托管账户":"Escrow account",
    "项目消息":"Project message",
    "信息":"Message",
    "现货":"Spot goods",
    "合约":"Contract",
    "数量":"Amount",
    "价格":"Value",
    "交易对":"EOS/ETH",
    "交易类型":"Transaction type",
    "委托":"Entrust",
    "卖出":"Sell",
    "买入":"Buy",
    "交易状态":"Transaction status",
    "成功":"Succeed",
    "失败":"Fail",
    "错误码":"Error code",
    "入金":"Deposit",
    "退出入金":"Withdrawal from deposit",
    "触发结算":"Trigger settlement",
    "api无效":"The API is invalid",
    "手动终止":"Manual termination",
    "自动终止":"Automatic termination",
    "操作员终止":"Operator termination",
    "所属账户":"Personal account",
    "所属操作员":"Personal operator",


    "子币对操作":"Currency pairs operation",
    "执行中":"In execution",
    "暂停中":"Pausing",
    "启动中":"Starting",
    "重启中":"Rebooting",
    "终止中":"Ending",
    "启动错误":"Startup error",
    "终止":"End",
    "暂停":"Pause",
    "重启":"Reboot",
    "参数":"Parameters",
    "修改参数":"Modify parameters",
    "确认暂停该策略？":"Confirm to suspend the policy？",
    "暂停成功":"Paused successfully",
    "确认重启该策略？":"Confirm to restart the policy",
    "重启成功":"Restart successfully",
    "确认终止该策略？":"Confirm termination of the policy？",
    "参数不能为空":"Parameters can not be empty",
    "参数不能小于最低范围":"Parameters can not be less than the minimum range",
    "参数不能大于最大范围":"Parameter can not be greater than the maximum range",
    "参数配置不正确，请重新修改":"Incorrect parameter configuration，please modify it again",


    "操作日志":"Operation log",
    "暂无记录":"No record yet",


    "策略消息":"Policy message",
    "暂无数据":"No data yet",


    "历史详情":"Historical details",
    "历史策略":"Historical strategy",
    "启动时间":"Startup time",
    "终止时间":"End time",
    "实例":"Example",
    "总投入":"Total input",
    "总收益":"Total revenue",
    "总年化":"Total annualization",
    "实现年化":"Achieve annualization",
    "交易数（买入）（卖出）":"Number of transactions (buy) (sell)",
    "消息":"Message",


    "订单链消息":"Order chain message",
    "市价卖出":"Sell at market price",
    "市价买入":"Buy at market price",
    "委托成功":"Successful entrustment",
    "委托失败":"Entrustment failed",
    "买入成功":"Buy successfully",
    "买入失败":"Buy failure",
    "委托卖出":"Entrust to sell",
    "委托买入":"Entrust to buy",


    "选择交易对":"Select a EOS/ETH",
    "交易对列表":"EOS/ETH list",
    "涨跌幅":"Percentage change",
    "请选择交易对":"Please select a EOS/ETH",
    "自定义模式只能选择1个交易对":"Custom mode can only select one EOS/ETH",


    "设置默认操作员":"Set default operator",
    "整体迁移用户":"All migrate users",
    "当前/累计账户数量：":"Number of current/cumulative accounts",
    "当前/累计执行策略：":"Current/cumulative execution policies：",
    "当前/累计资产：":"Current/cumulative assets：",
    "当前/累计账户盈亏：":"Current/cumulative account profit or loss：",
    "最大盈利/回撤率：":"Maximum profit/withdrawal rate：",
    "当前平均年化：":"Current average annualization：",
    "迁移用户":"Migrate users",
    "请选择操作员":"Please select operator",
    "请先选择操作员":"Please select operator first",
    "迁移成功":"Migration successful",


    "创建者":"Creator",
    "平台":"Platform",
    "有效期":"Expiration date",
    "删除信号":"Delete signal",
    "属性":"Attribute",
    "复制":"Copy",
    "接收时间":"Receiving time",
    "消息内容":"Message contents",
    "信号记录":"Signal recording",
    "删除成功":"Deleted successfully",
    "复制失败":"Copy failed",
    "确认删除此信号？":"Confirm to delete this signal？",


    "共&(1)名项目成员":"Total & (1) project member",
    "默认操作员":"Default operator",
    "添 加":"Add",
    "移出项目后，该成员所属的用户将被分配给项目管理员":"After moving out of the project， the user to which the member belongs will be assigned to the project administrator",
    "添加成功":"Added successfully",


    "创建信号":"Create signal",
    "TradingView信号订阅教程>>":"TradingView signal subscription tutorial>>",
    "接收结果":"Receive results",
    "暂未收到信息":"No message received yet",
    "名称":"Name",
    "请输入名称":"Please enter name",
    "请输入有效期":"Please enter the expiration date",
    "属性映射":"Attribute mapping",
    "请输入属性":"Please enter attributes",
    "请输入显示名称":"Please enter display name",
    "删除-":"Delete-",
    "显示名称":"Display name",
    "固定值":"Fixed value",
    "可选":"Optional",
    "注释":"Notes",
    "新增字段+":"New field+",
    "我的创建":"My creation",
    "新信号":"New signal",
    "请选择平台":"Please select platform",
    "请输入属性&(1)的属性":"Please enter the attribute&the attribute of (1)",
    "请输入属性&(1)的显示名称":"Please enter the attribute& the display name of (1)",
    "服务器接收到信号后开始推送并计时， 超时后信号失效并停止推送。":"When the server receives the signal and it starts pushing and timing，after the timeout，the signal becomes invalid and the push stops。",


    "根据用户建议，APP端展示时自动剔除不足7日结算退出的数据。":"According to the user's suggestions，the data of settlement and exit less than 7 days will be automatically removed when the app is displayed。",
    "数据概览图（项目）":"Data overview map (project)",
    "当前/累计账户数量":"Number of current/cumulative accounts",
    "当前/累计资产(USDT)":"Current/accumulated assets (USDT)",
    "当前/累计盈亏(USDT)":"Current/accumulated profit and loss (USDT)",
    "最大盈利/回撤率":"Maximum profit/withdrawal rate",
    "当前平均年化":"Current average annualization",
    "资产走势图":"Asset trend chart",
    "每日利润":"Daily profit",
    "资金盈利":"Fund profit",
    "资产亏损":"Loss on assets",
    "详情":"Details",
    "当前/累计执行策略":"Current/cumulative execution strategy",
    "总资产":"Total assets",
    "当前收益":"Current earnings",


    "当前策略数：":"Current policies",
    "当前总资金(USDT)：":"Current total funds(USDT)",
    "当前总投入(USDT)：":"Current total investment (USDT)",
    "当前总收益(USDT)：":"Current total revenue(USDT)",
    "平均总年化：":"Average total annualization：",
    "未执行策略用户：":"Policy not enforced user",
    "交易所":"Exchange",
    "请选择交易所":"Please select an exchange",
    "开始时间":"Start time",
    "结束时间":"End time",
    "剩余可用":"Available",
    "收起":"Put away",
    "展开":"Expand",
    "重置":"Reset",
    "查询":"Query",
    "当前用户":"Current user",
    "总资金(USDT)":"Total funds (USDT)",
    "已投入(USDT)":"Invested (USDT)",
    "剩余可用(USDT)":"Remaining available (USDT)",
    "当前策略数":"Current policies",
    "历史用户":"Historical user",
    "请先选择用户":"Please select a user first",


    "策略配置":"Policy configuration",
    "选择策略：":"Select policy",
    "请选择策略":"Please select a policy",
    "选择交易所：":"Select Exchange：",
    "选择模式：":"Select mode：",
    "自定义模式":"Custom mode",
    "多币对模式":"Multi-currency pair mode",
    "选择交易对：":"Select EOS/ETH",
    "策略方向":"Policy direction",
    "赚本币（看多）":"Earn local currency (bullish)",
    "赚交易币（看空）":"Earn trading currency (bearish)",
    "参数配置":"Parameter configuration",
    "范围：":"Range",
    "配置投入":"Configuration input",
    "固定投入金额":"Fixed input amount",
    "请输入投入金额":"Please enter the input amount",
    "可用余额：":"Available balance：",
    "满足比例，但低于下限不投； 满足比例，但高于上限的按上限投；":"Meet the proportion，but do not cast below the lower limit；meet the proportion，but higher than the upper limit by the upper limit；",
    "比例投入或固定投入需满足策略本身投入限制，高于策略限制上限按策略上限投。":"Proportional investment or fixed investment should meet the input limit of the strategy itself， which is higher than the upper limit of the policy。",
    "启动策略":"Startup strategy",
    "修改模板名称":"Modify template name",
    "请先选择交易所":"Please select the exchange first",
    "价格上限/价格下限不能小于0":"Price ceiling/price floor can not be less than 0",
    "请先选择策略":"Please select a strategy first",
    "当前选择模式为自定义模式，交易对数量不能超过1个":"The current selection mode is custom mode，the number of EOS/ETH can not exceed 1",
    "参数配置不正确":"Incorrect parameter configuration",
    "单格投入不能为0":"Single input can not be 0",
    "首单投入不能为0":"The first order input can not be 0",
    "首单金额不能为0":"Single amount can not be 0",
    "单笔金额不能为0":"Single amount cannot be 0",
    "投入金额不能低于最小范围":"The input amount can not be lower than the minimum range",
    "投入金额不能大于最大范围":"The input amount can not be greater than the maximum range",
    "可用余额不足":"Insufficient available balance",
    "投入百分比不能小于0%":"Input percentage can not be less than 0%",
    "投入百分比不能大于100%":"Input percentage can not be greater than 100%",
    "投入下限不能低于最小范围":"The lower limit of input can not be lower than the minimum range",
    "投入上限不能大于最大范围":"The upper limit of input can not be greater than the maximum range",
    "启动成功":"Startup successful",

    
    "范围值":"Range value",
    "可用资金百分比":"Percentage of available funds",
    "可用资金比例":"Proportion of available funds",
    "请输入比率":"Please enter the ratio",
    "投入下限":"Lower limit of input",
    "请输入投入下限":"Please enter the lower limit of input",
    "投入上限":"Input limit",
    "请输入投入上限":"Please enter the input limit",
    "满足比例，但高于上限的按上限投；满足比例，但低于下限不投； 满足比例，但高于上限的按上限投；":"Meet the proportion，but above the upper limit by the upper limit； meet the proportion，but not below the lower limit；meet the proportion，but above the upper limit by the upper limit；",
    "创建策略模板":"Create policy template",
    "新的模板":"New template",
    "买入价格/卖出价格不能小于0":"The buying price/selling price can not be less than 0",
    "模板名字不能为空":"Template name can not be empty",
    "价格上限不能低于价格下限":"The price ceiling can not be lower than the lower price limit",
    "首单投入不能为0%":"The first order input can not be 0%",
    "首单金额不能为0%":"The first order amount can not be 0%",
    "单笔金额不能为0%":"Single amount can not be 0%",
    "买入价格不能为0%":"The purchase price can not be 0%",
    "卖出价格不能为0%":"The selling price can not be 0%",
    "买入价格不能低于卖出价格":"The buying price can not be lower than the selling price",
    "投入百分比不能小于0":"Input percentage can not be less than 0",
    "投入百分比不能大于100":"Input percentage can not be greater than 100",


    "已投入策略(USDT)":"Invested Strategy (USDT)",
    "当前策略":"Current Strategy",
    "策略名称":"Policy name",
    "策略模式":"Policy mode",
    "实现收益":"Realized benefits",
    "浮动收益":"Floating income",
    "总年化/实现年化":"Total annualization/realization annualization",
    "状态":"Status",
    "增加子币对":"Add sub-coin pairs",
    "请谨慎增加币对，避免操作失败致使实例，被大量移至未分组":"Please be careful to increase currency pairs，avoid instances being moved to ungrouped due to operation failure",
    "确认终止策略？":"Confirm termination policy？",
    "终止成功":"Terminated successfully",
    "没有选择币对":"No currency pair selected",
    "增加币对成功":"Increase currency pair success",


    "策略详情":"Strategy details",
    "浮动收益(USDT)":"Floating income (USDT)",
    "增加币对":"Add currency pair",
    "持仓":"Position",
    "委托冻结":"Entrustment freeze",
    "委托订单":"Entrustment order",
    "成交记录":"Transaction record",
    "历史记录":"History",
    "成交价":"transaction price",
    "暂无成交记录":"No transaction record yet",
    "历史参数":"Historical parameters",


    "终止订单链":"Terminate the order chain",
    "查看订单链":"View order chain",
    "修改订单链":"Modify order chain",
    "智能订单信息":"Smart order information",
    "执行时长":"Execution time",
    "初始投入":"Initial input",
    "当前订单":"Current order",
    "订单方向":"Order direction",
    "订单状态":"Order status",
    "订单链状态":"Order chain status",
    "已完成":"Completed",
    "当前委托":"Current delegation",
    "委托时间":"Entrusting time",
    "方向":"Direction",
    "委托数量":"Number of delegates",
    "委托价格":"Entrust price",
    "订单":"Order",
    "执行动作":"Execute actions",
    "成交数量":"Number of transactions",
    "成交金额":"Transaction amount",
    "成交均价":"Average transaction price",
    "成交价格":"Transaction price",
    "终止订单":"Terminate the order",
    "确认终止后订单将自动撤消未成交的委托单。":"After confirming the termination，the order will automatically cancel the unfinished order。",
    "关 闭":"Close",
    "张":"BTC",
    "做多":"Long",
    "做空":"Short",
    "平多":"Close a position",
    "平空":"Short covering",
    "等待触发":"Waiting for trigger",
    "等待成交":"Waiting for a deal",
    "成交成功":"Successful transaction",
    "订单异常":"Order exception",


    "共计订单&(1)条":"Total order&(1)",
    "已选择&(1)条":"Selected&(1)",
    "确认强制结算后账户将被服务器接管，所有持仓自动兑换成托管币种后等待下一结算期结算。":"After confirming the compulsory settlement，the account will be taken over by the server，all positions will be automatically converted into escrow currencies and wait for settlement in the next settlement period。",
    "请耐心等待，结算完成后您可在历史账户中查看。":"Please be patient，you can check it in your historical account after the settlement is completed。",
    "强制结算会被记录在项目及团队评分系统中，非必要请勿进行此项操作。":"Compulsory settlement will be recorded in the project and team scoring system，do not do this unless necessary。",
    "当前账户":"Current account",
    "历史账户":"Historical account",


    "修改备注":"Revision remarks",
    "请输入备注":"Please enter comments",


    "订单标签：":"Order label：",
    "交易所：":"Exchange：",
    "交易类型：":"Deal type：",
    "下单账户":"Place order account",
    "可开&(1)张":"Open&(1)BTC",
    "可平&(1)张":"Keping&(1)BTC",
    "暂无可用账户":"No account available",
    "启动订单链":"Start order chain",


    "批量修改":"Batch modification",
    "已选择订单&(1)条":"Order selected&(1)",
    "更多":"More",
    "卖出成功":"Sell successfully",
    "卖出失败":"Failed to sell",
    "买入失败":"Buy failure",


    "创建策略模版":"Create a policy template",
    "模板排序":"Template sorting",
    "自定义":"Custom",
    "多币对":"Multi-currency pair",
    "添加账户":"Add account",
    "加仓":"Add to the position",
    "修改模板总投入":"Total investment in modifying template",
    "移除策略模版":"Remove policy template",
    "开启后新分配到的账户将自动启动此策略":"After opening， the newly assigned account will automatically start this policy",
    "策略：":"Policy",
    "实现收益 (USDT)":"Realized benefits (USDT)",
    "已暂停":"Suspended",
    "日志":"Log",
    "暂无日志":"No log",
    "策略模板还是空的，快去创建策略模板吧～":"Policy template is still empty，go and create a policy template～",
    "更多>>":"More>>",
    "终止确认":"termination confirmation",
    "此过程将持续几分钟，请耐心等待！您可以查看其他页面，但不可进行操作，以免数据出错。":"This process will last for a few minutes，please be patient！You can view other pages，but you can not operate to avoid data errors。",
    "请确认终止现有策略实例&(1)个，确认终止后，策略将自动撤销未成交的委托单，并兑换成投入币种。":"Please confirm the termination of the existing policy instances&(1)，after confirmation of termination，the policy will automatically revoke the outstanding entrustment order and convert it into the input currency。",
    "暂停确认":"Pause confirmation",
    "请确认暂停现有策略实例&(1)个，确认暂停后，策略将自动挂起，不再执行新的委托，未成交的委托单继续等待成交，数据不再更新。请确认终止现有策略实例":"Please confirm to suspend the existing policy instances&(1)，after confirming the pause，the policy will be automatically suspended，and no new delegation will be executed，the uncompleted commission order will continue to wait for the transaction，the data will not be updated。Please confirm to terminate the existing policy instance",
    "加仓投入上限":"Upper limit of adding to the positions",
    "加仓金额":"Amount of adding to the positions",
    "满足比例，但高于上限的按上限投；":"Meet the proportion， but higher than the upper limit by the upper limit",
    "比例投入或固定投入需满足策略本身投入限制，":"Proportional or fixed input needs to meet the input limit of the strategy itself，",
    "高于策略限制上限按策略上限投。":"Above the policy limit， vote according to the policy limit。",
    "删除子币对":"Delete sub-currency pair",
    "模板重命名":"Template renaming",
    "修改模板投入配置":"Modify template input configuration",
    "范围值：":"range value：",
    "定投金额":"fixed investment amount",
    "修改模版总投入不影响已投入的策略":"The total investment in modifying the template does not affect the strategy already invested",
    "新分配的用户将依据排序依次启动符合模板要求且开启自动交易的策略":"Newly assigned users will start policies that meet the requirements of the template and enable automatic transactions according to the order",
    "模板名称":"Template name",
    "自动交易":"Automatic transaction",
    "排序":"Sort",
    "该模板下没有执行实例":"There is no execution instance under this template",
    "确认移除模板？":"Confirm to remove the template？",
    "当前模板没有执行策略":"Current template has no enforcement policy",
    "价格下限不能小于0":"Price lower limit can not be less than 0",
    "价格上限/价格下限不能小于":"Price upper limit/lower limit can not be less than",
    "修改参数成功":"Parameter modified successfully",
    "投入比例不能为空":"Input ratio can not be empty",
    "投入比例不能小于等于0%":"Input ratio can not be less than or equal to 0%",
    "投入比例不能大于100%":"Input ratio can not be greater than 100%",
    "加仓投入下限不能小于最小投入":"The lower limit of adding to the positions can not be less than the minimum input",
    "加仓投入上限限不能大于最大投入":"The upper limit of adding to the positions can not be greater than the maximum input",
    "加仓投入下限不能大于加仓投入上限":"The lower limit of adding to the positions can not be greater than the upper limit of adding to the positions",
    "投入金额不能为空":"Input amount can not be empty",
    "投入金额不能小于0":"Input amount can not be less than 0",
    "请输入投入比例":"Please enter the input ratio",
    "投入金额不能小于最小投入":"The input amount can not be less than the minimum input",
    "投入金额不能大于最大投入":"The amount of input can not be greater than the maximum input",
    "至少保留一个交易对":"Keep at least one EOS/ETH",


    "总收益(USDT)：":"Total revenue(USDT)：",
    "总收益率：":"Total return rate：",
    "加仓投入下限":"Input lower limit of overweight",
    "满足比例，但低于下限不投；":"Meet the ratio，but not invest below the minimum threshold；",
    "参数不正确，请重新修改":"Parameter error，please modify it again",


    "项目状态：":"Project status：",
    "下线结算中":"Offline settlement in progress",
    "项目保证金余额：":"Project margin balances：",
    "审核未通过：":"Audit failed：",
    "保证金不足,初始保证金不低于&(1)VTN":"If the margin is insufficient，the initial margin shall not be less than & (1) VTN",
    "去划转":"Go transfer",
    "申请审核上线":"Apply for review and go online",
    "申请审核修改内容":"Apply for review and modification of content",
    "测试口令":"Test password",
    "重置为未上线":"Reset to not online",
    "下线结算":"Offline settlement",
    "入金操作：":"Deposit operation：",
    "保存修改":"Save changes",
    "项目可见：可见则显示在量化托管列表；不可见则不在量化托管列表显示，但可通过测试口令访问。":"Projects are visible；visible is displayed in the quantitative managed list；invisible is not shown in the quantitative managed list，but can be accessed through the test password。",
    "可见性设置在项目上线后生效。":"The visibility setting takes effect after the project is launched。",
    "项目可见：":"Project visible：",
    "设置":"Settings",
    "托管类型：":"Managed type：",
    "全API托管":"All API managed",
    "托管币种：":"Managed currency：",
    "交易币种限制":"Currency restrictions for transactions",
    "最多支持20个币种，项目上线后不可修改，请谨慎选择":"A maximum of 20 currencies are supported，and can not be modified after the project is launched，please choose carefully",
    "必填":"Required",
    "用户盈利":"User profit",
    "的部分，要求分成比例":"The part requires a sharing ratio",
    "风险赔付承诺":"Risk compensation commitment",
    "选填":"Optional",
    "当用户亏损":"When the user loses money",
    "的部分，承诺赔偿比例：":"The promised compensation ratio for the portion：",
    "是否在app端剔除托管时长不足7日的用户托管数据":"Whether to remove the managed data of users whose hosting time is less than 7 days on the app side",
    "复 制":"Copy",
    "币种：":"Currency",
    "开启后，当用户托管不足7日就退出时，app将修正累计投入、收益及人数等数据":"After activation，if the user logs out after less than 7 days of hosting，the app will adjust the accumulated investment、revenue、and number of users' data",
    "请联系超级管理员操作！":"Please contact the super administrator for operation！",
    "划转成功":"Successful transfer",
    "项目可见：可见则显示在量化托管列表；不可见则不在量化托管列表显示，但可通过测试口令访问":"Project visible：visible is displayed in the quantitative managed list；invisible is not shown in the quantitative managed list，but can be accessed through the test password",
    "生效时间之前退出的用户将不会获得赔偿":"Users who exit before effective time will not receive compensation",
    "生效实际不得大于30天":"In fact，the effective date shall not be longer than 30 days",
    "至少存在一个交易所":"There is at least one exchange",
    "输入比例不能为负":"Input ratio can not be negative",
    "输入比例不能大于100%":"Input ratio can not be greater than 100%",
    "币对不能为空，请先选择币种":"Currency pair can not be empty，please select the currency first",
    "币种不能为空":"Currency can not be empty",
    "分成上限不能为负":"The upper limit of profit sharing can not be negative",
    "最小可用保证金要求不能为负":"The minimum available margin requirement can not be negative",
    "投入范围限制不符合，请重新修改":"The investment scope limit does not comply，please revise it again",
    "盈利分成比例不符合，请重新修改":"Profit sharing ratio does not match，please revise it again",
    "赔付承诺生效时间不能小于0天":"The effective time of the promise of compensation can not be less than 0 days",
    "亏损比例不能小于0%":"Loss ratio can not be less than 0%",
    "亏损比例不能大于100%":"Loss ratio can not be greater than 100%",
    "赔付比例不能小于0%":"The payout ratio can not be less than 0%",
    "赔付比例不能大于100%":"The payout ratio can not exceed 100%",
    "确认结算后项目将从app端移除，所有账户将被服务器接管，依次终止交易后自动兑换成托管币种，并在下一结算期结算保证金。":"After confirming the settlement，the project will be removed from the app，and all accounts will be taken over by the server，after terminating the transaction in turn，it will be automatically exchanged for the escrow currency，and the deposit will be settled in the next settlement period。",


    "隐藏小额币种":"Hide small-scale currencies",
    "开启后，价值小于10 USDT的币种将会被隐藏。":"After activation，currencies with a value less than 10 USDT will be hidden。",
    "账户类型":"Account type",
    "统一账户":"Unified account",
    "逐仓":"Isolated",
    "全仓":"Cross",
    "同步资产":"Synchronous assets",
    "快速兑换":"Quick exchange",
    "快速平仓":"Quickly close positions",
    "权益":"Equity",
    "可用担保资产":"Available secured assets",
    "持仓量(张)":"Open interest(BTC)",
    "可平量(张)":"Quantity of close positions(BTC)",
    "开仓均价":"Average price of opening positions",
    "担保资产率":"Coverage assets ratio",
    "预估强平价":"Estimated strong price",
    "收益":"Earning",
    "收益率":"Earning rate",
    "可用":"Available",
    "共计持仓&(1)条":"Total positions&(1)",
    "确认快速兑换USDT后所选持仓将以taker单快速兑换为USDT。":"After confirming the quick exchange of USDT， the selected position will be quickly converted to USDT through a taker order。",
    "到:":"To",
    "从:":"From",


    "多币対":"Multi currency pair",
    "双向":"Two-way",
    "投入币种：":"Input currency",
    "复制交易":"Copy transaction",
    "减仓":"Reduce positions",
    "策略信息：":"Strategy information：",
    "交易数 / 买入 / 卖出":"Number of transactions / buy / sell",
    "交易数 / 开仓 / 平仓":"Number of transactions / open  positions / Close out position",
    "持仓：":"Position",
    "已包含手续费成本":"Already includes transaction fee costs",
    "合约信息：":"Contract Information：",
    "账户权益":"Account equity",
    "强平价":"Strong price",
    "开仓阈值":"Open positions threshold",
    "交易数/买入/卖出":"Number of transactions/buy/sell",
    "启动":"Start-up",
    "历史记录：":"Historical records：",
    "减仓金额":"Reduced position amount",
    "可用: ":"Available",
    "参数设置":"Parameter settings",
    "倍数补仓":"Multiple buy-in positions",
    "增量补仓":"Incremental buy-in positions",
    "固定间隔":"Fixed interval",
    "自定义间隔":"Custom Interval",
    "阈值":"threshold",
    "网格分布":"Grid distribution",
    "等比网格":"Geometric grid",
    "等差网格":"Arithmetic grid",
    "追踪补仓":"Track buy-in positions",
    "预埋单补仓":"Pre-trade order buy-in positions",
    "一次性止盈":"One time take profit",
    "逐单止盈":"Take profit on a per order basis",
    "追踪止盈":"Track take profit",
    "预埋单止盈":"Pre-trade order take profit",
    "策略开仓阈值":"Strategic opening position threshold",
    "其他设置":"Other settings",
    "总收益止盈":"Total income take profit",
    "总亏损止损":"Total loss stop loss",
    "定时自动终止":"Timed automatic termination",
    "买入（张）":"Buy (BTC)",
    "价格（USDT）":"Price (USDT)",
    "开多":"Buying long",
    "卖出（张）":"Sell (BTC)",
    "开空":"Short selling",
    "暂无委托订单":"No commissioned orders at the moment",
    "数量 (张)":"Quantity(BTC)",
    "暂无可加币对":"No available add currency pairs",
    "确定终止后，策略将自动撤销未完成的委托单，并兑换成投入币种。":"After the termination is confirmed，the policy will automatically cancel the unfinished commission order and exchange it into the input currency。",
    "增加币对成功参数不能小于最小范围":"The success parameter for terminating the addition of a currency pair can not be less than the minimum range",
    "网格间隔(等差)不能为0":"Grid interval(equal difference)can not be 0",
    "价格上限不能小于0":"The upper limit of price can not be less than 0",
    "单格投入不正确，请检查修改":"Incorrect input in single box，please check for modification",
    "首单投入不正确，请检查修改":"The first order input is incorrect，please check and modify",
    "首单金额不正确，请检查修改":"The amount of the first order is incorrect，please check and modify",
    "单笔金额不正确，请检查修改":"The single amount is incorrect，please check and modify",
    "买入价格不正确，请检查修改":"The purchase price is incorrect，please check and modify",
    "卖出价格不正确，请检查修改":"The selling price is incorrect, please check and modify",
    "策略开仓阈值不能小于0":"Policy opening positions threshold value can not be less than 0",
    "策略开仓阈值不能为空":"Policy opening positions threshold value can not be empty",
    "其他设置参数不正确，请重新修改":"Other setting parameters are incorrect，please modify it again",
    "止盈根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止盈后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。":"Take profit is calculated according to the total rate of return， the priority of strategy suspension operation is higher than take profit  stop loss and regular termination，that is such operations do not take effect in the suspended state。Trigger stop profit after the automatic termination strategy，the system according to your choice to decide whether to carry out the withdrawal and exchange operation。",
    "止盈根据总收益率计算，触发止盈后系统自动撤单平仓后终止策略。":"The take profit is calculated according to the total rate of return，and the system automatically withdraws and closes the positions after triggering the profit taking strategy。",
    "止损根据总收益率计算，触发止损后系统自动撤单平仓后终止策略。":"The stop loss is calculated according to the total rate of return，and the system automatically withdraws the order and closes the position after the stop loss is triggered",
    "总收益止损":"Total income stop loss",
    "策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。定时终止可能会有几分钟的偏差，触发后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。":"The priority of policy suspension operation is higher than take profit stop loss and regular termination，that is such operations do not take effect in the pause state。Timing termination may have a deviation of a few minutes，and policy is automatically terminated after triggering，the system will decide whether or not to withdraw the order exchange operation according to your choice。",
    "定时终止可能会有几分钟的偏差，触发后系统自动撤单平仓后终止策略。":"The timing termination may have a deviation of several minutes，and the system will automatically withdraw the order and close the position after the trigger and terminate the policy。",
    "止损根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止损后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。":"Stop loss is calculated according to the total rate of return， the priority of strategy suspension operation is higher than take profit stop loss and regular termination，that is such operations do not take effect in the suspended state。After triggering the stop loss，the system automatically terminates the policy，and the system decides whether or not to carry out the withdrawal and exchange operation according to your choice。",


    "模版标签":"Template label",
    "选择模式":"Selection mode",
    "杠杆倍数":"Leverage multiple",
    "配置投入带*符号为必填项":"Configuration input with* symbol is required",
    "投入":"Input",
    "资金比例":"Proportion of funds",
    "资金上下限":"Upper and lower limits of funds",
    "选定的账户自动以此配置为模板启动策略实例，您也可以先创建模板，然后再选择账户添加到创建好的模板中以启动实例。":"The selected account automatically starts the policy instance with this configuration as a template，or you can create a template first，then select an account to add to the created template to start the instance。",
    "创建策略":"Create policy",
    "参数不能小于最小范围":"Parameter can not be less than minimum range",
    "投入不能小于最小投入范围":"Input can not be less than the minimum input range",
    "投入不能大于最大投入范围":"Input can not be greater than the maximum range of input",
    "资金上限不能大于投入最大范围":"The upper limit of funds can not be greater than the maximum range of investment",
    "资金上限不能小于投入最小范围":"The upper limit of funds can not be less than the minimum range of investment",
    "资金下限不能小于投入最小范围":"The lower limit of funds can not be less than the minimum range of investment",
    "资金下限不能大于投入最大范围":"The lower limit of funds can not be greater than the maximum range of investment",
    "资金下限不能大于资金上限":"The lower limit of funds can not be greater than the upper limit of funds",
    "资金上限不能小于资金下限":"The upper limit of funds can not be less than the lower limit of funds",
    "请先输入策略投入":"Please enter policy input first",
    "参数不正确，请检查修改":"The parameter is incorrect， please check the modification",
    "投入不正确，请检查修改":"Incorrect input，please check the modification",
    "止损参数不能小于0%":"Stop loss parameter can not be less than 0%",
    "止损参数不能大于100%":"Stop loss parameter can not be greater than 100%",
    "止赢参数不能小于0%":"Take profit parameter can not be less than 0%",
    "策略模板名称不能为空":"Policy template name can not be empty",
    "划转不能小于0":"Transfer can not be less than 0",
    "划转不能大于可用划转金额":"Transfer can not be greater than the available transfer amount",
    "1、设置固定金额时，不满足金额或数量的账户不下单；":"1、When setting a fixed amount，accounts that do not meet the amount or quantity will not place an order；",
    "2、设置比例投入，账户满足比例时，低于下限的不下单；高于上限的按上限下单。":"2、Set the proportional input，when the account meets the proportion，no order will be issued if it is below the lower limit；if it is higher than the upper limit，the order will be issued according to the upper limit",


    "多币对&(1)个":"Multi-currency pairs&(1)",
    "移除模板":"Remove template",
    "总投入配置":"Total investment allocation",
    "重命名":"Rename",
    "添加用户":"Add user",


    "账户数据":"Account data",
    "总资金":"Total funds",
    "已投入":"Already invested",
    "资金分布":"Distribution of funds",
    "永续":"Sustainable",
    "快速兑换USDT":"Quick exchange USDT",
    "确认选中的持仓立即兑换成投入币种？":"Confirm that the selected position is immediately exchanged into the input currency？",
    "已冻结的持仓，需要先撤销委托订单可兑换哦":"If the position has been frozen，you need to cancel the entrustment order and exchange it",
    "请输入阈值":"Please enter threshold",
    "平台不支持&(1)快速兑换":"Platform does not support&(1)quick exchange",
    "未选中可平的持仓":"No keping position selected",


    "下限选填":"Optional lower limit",
    "上限选填":"Optional upper limit",
    "多仓":"Long position",
    "选择全部账户":"Select all accounts",
    "展开全部账户":"Open all accounts",
    "在此处选择委托方式并设置参数。":"Select the delegate method here and set the parameter。",
    "*选择比例投入时可设置上下限限制，账户可用低于下限时不下单；高于上限的按上限下单。":"*The upper and lower limit can be set when selecting the proportion of investment， when the account is lower than the lower limit，no order can be placed；if it is higher than the upper limit，the order can be placed according to the upper limit。",
    "币币交易":"Currency transaction",
    "市价":"Market price",
    "限价":"Limited price",
    "条件单":"Condition list",
    "资金上下限":"Upper and lower limits of funds",
    "买入开多":"Buy long",
    "买入平空":"Buy short",
    "卖出开空":"Sell short",
    "卖出平多":"Sell long",
    "触发方向":"Trigger direction",
    "向上突破":"Breakthrough upwards",
    "向下突破":"Breakthrough downwards",
    "触发价格":"Trigger price",
    "价格类型":"Price type",
    "此处可选择交易所与交易类型，目前支持币币与U本位永续合约交易。":"Here you can choose the exchange and transaction type，currently，it supports trading in currency and U-based perpetual contracts。",
    "支持在多个交易所同时下单，下单时后台会自动过滤不支持的账户。":"Support placing orders on multiple exchanges simultaneously，and the backed will automatically filter out unsupported accounts when placing orders。",
    "可在此处快速选择交易对，当选择多个交易所API时，行情价只显示其中一家价格，不影响实际下单。":"You can quickly select positions（EOS/ETH）here，when selecting multiple exchange API，the market price only displays one of them which does not affect the actual order placement。",
    "累计":"Cumulative",
    "快速兑换 USDT":"Quickly exchange USDT",
    "止盈":"Take profit",
    "止损":"Stop loss",
    "全部撤销":"All revoked",
    "下单方式":"Ordering method",
    "条件委托":"Conditional delegation",
    "限价委托":"Fixed price delegation",
    "市价委托":"Market delegation",
    "已成交":"Deal",
    "撤销":"Revoke",
    "已撤销":"Revoked",
    "此列表只提供7日内历史委托数据":"This list only provides historical delegation data within 7 days",
    "持仓 - 当前委托":"Position - current commission",
    "在此处查看持仓及委托数据。":"View positions and delegation data here。",
    "币币持仓":"Currency positions",
    "币币持仓支持快速兑换成USDT；":"Currency positions support to quickly exchange into USD",
    "合约持仓":"Contract positions",
    "合约持仓支持快速平仓和止盈止损操作。":"Contract positions support quick closing positions，take profit and stop loss operations",
    "可平":"Keping",
    "下单失败":"Failed to place order",
    "以taker单立即成交":"Deal immediately with taker order",
    "空仓":"Bear positions",
    "平仓":"Close positions",
    "开仓":"Open positions",
    "冻结":"Freeze",
    "折合 USDT 估值":"Equivalent to USDT valuation",
    "折合 USDT 成本":"Equivalent to USDT cost",
    "折合 USDT 收益":"Equivalent to USDT earnings",
    "品种":"Breed",
    "开仓均价USDT":"Average price of opening positions USDT",
    "收益USDT":"Earnings USDT",
    "强平价USDT":"Strong price USDT",
    "数量 | 金额":"Quantity | amount",
    "历史委托":"Historical commission",
    "1、设置固定投入时，不满足金额或数量的账户不下单；":"1、When setting a fixed investment，accounts that do not meet the amount or quantity will not place an order；",
    "限价买入价格不能为空":"The limit buying price can not be empty",
    "限价卖出价格不能为空":"The limit selling price can not be empty",
    "买单投入不能为空":"The input of the purchase order can not be empty",
    "买单投入不能小于等于0":"The input of the purchase order can not be less than or equal to 0",
    "卖单投入不能小于等于0":"The input of the sell order can not be less than or equal to 0",
    "买单下限不能小于0":"The lower limit of the purchase order can not be less than 0",
    "卖单下限不能小于0":"The lower limit of a sell order can not be less than 0",
    "买单资金下限不能大于资金上限":"The lower limit of funds for the purchase orders funds can not be greater than the upper limit of funds",
    "卖单资金下限不能大于资金上限":"The lower limit of funds for selling orders can not be greater than the upper limit of funds",
    "买单比例投入不能大于100%":"The proportion of investment in the purchase orders should not be greater than 100%",
    "卖单比例投入不能大于100%":"The proportion of investment in selling orders can not be greater than 100%",
    "买单触发价格不能为空":"The purchase order trigger price can not be empty",
    "卖单触发价格不能为空":"Sell order trigger price can not be empty",
    "买单触发价格不能小于0":"The purchase order trigger price cannot be less than 0",
    "卖单触发价格不能小于0":" Sell order trigger price can not be less than 0",
    "买单限价价格不能为空":"The purchase order limit price can not be empty",
    "卖单限价价格不能为空":"Sell order limit price can not be empty",
    "买单限价价格不能小于0":"The purchase order limit price can not be less than 0",
    "卖单限价价格不能小于0":"Sell order limit price can not be less than 0",
    "请选择至少一个账户":"Please select at least one account",
    "没有符合条件的下单账户":"There are no eligible ordering accounts available",
    "下单成功":"Order successfully",
    "触发价格输入错误":"Trigger price input error",
    "下单数量不能为空":"Order quantity can not be empty",
    "触发价格不能为空":"Trigger price cannot be empty",
    "请先选择至少一条需要操作的数据":"Please select at least one piece of data to be operated first",
    "投入不能大于投入范围":"Input can not be greater than input range",
    "投入不能小于0":"Input can not be less than 0",
    "做空止盈触发价格必须低于开仓均价":"The short selling take profit trigger price must be lower than the average price of opening positions",
    "做空止损触发价格必须高于开仓均价":"The short selling stop loss trigger price must be higher than the average price of opening positions",
    "做多止盈触发价格必须高于开仓均价":"The buying long take profit trigger price must be higher than the average price of opening positions",
    "做多止损触发价格必须低于开仓均价":"The buying long stop loss trigger price must be lower than the average price of opening positions",
    "卖单投入不能为空":"The sale order input cannot be empty",




    "打开 VtradingAPP 扫一扫登录":"Open VtradingAPP, scan and log in",
    "成员管理":"Member Management",
    "请输入账户":"Please enter your account",
    "触发条件":"Trigger condition",
    "请输入价格":"Please enter the price",
    "托管时间":"Custody time",
    "至":"To",
    "异常指下单时出现交易所报错信息、数据异常等；无效指无法同步，可能是结算中、失去权限、交易所断开链接等。":"Exception refers to the occurrence of exchange error messages, data anomalies, etc. when placing an order; Invalid refers to the inability to synchronize, which may be due to settlement, loss of permissions, exchange disconnection, etc.",
    "API状态":"API status",
    "仓位":"Position",
    "上一次同步时间":"Last time",
    "折合投入币种估值":"Investment valuation",
    "标签":"Label",
    "订单收益":"Order revenue",
    "当前订单状态":"Current order status",
    "当前方向":"Current direction",
    "实例状态":"Instance status",
    "U本位永续合约":"U-based perpetual contracts",
    "USDT本位永续合约":"U-based perpetual contracts",

    
    "等待执行":"Waiting for execution",
    "新建订单":"New order",
    "订单&(1)参数设置":"Order &(1) Parameter Settings",
    "带*符号，为必填项":"Fields marked with * are mandatory",
    "下单方向":"Ordering direction",
    "请输入数量":"Please enter the quantity",
    "资金比例：":"Funding ratio：",
    "合约面值":"Contract face value",
    "投入上下限":"Input upper and lower limits",
    "新建动作":"",
    "暂无动作、点击下方按钮创建动作":"There are currently no actions. Click the button below to create an action",
    "投入比例不能小于0":"The investment ratio cannot be less than 0",
    "投入比例不能大于100":"The investment ratio cannot exceed 100",
    "订单链由顺序执行的多个订单构成，可充分扩展，使您的下单计划不再受限，资金使用更高效。":"The order chain consists of multiple orders executed in sequence, which can be fully expanded, allowing your ordering plan to be unrestricted and funds to be used more efficiently.",
    "1、在上一个订单成交后，所获得的持仓投入下一个订单。":"1. After the previous order is completed, the position obtained is invested in the next order.",
    "例：开启两个订单，前一订单设置的交易对为BTC/USDT，投入USDT买入BTC，成交后获得BTC，则后一个订单的投入币种为BTC，如后一订单设置的交易对也为BTC/USDT，则委托方向为卖出；如后一订单设置的交易对为ETH/BTC，则委托方向为买入。":"Example: Open two orders. If the previous order sets the trading pair as BTC/USDT, invest USDT to buy BTC, and obtain BTC after completing the transaction, then the investment currency for the next order is BTC. If the trading pair set for the next order is also BTC/USDT, then the direction of the order is to sell; If the trading pair set for the next order is ETH/BTC, then the direction of the order is buy.",
    "2、订单按顺序执行，删除其中一个，后续订单都将被删除。":"2. Orders are executed in order, and if one of them is deleted, all subsequent orders will be deleted.",
    "动作数最多支持5个":"The maximum number of actions supported is 5",
    "合约最多支持2个":"The contract supports a maximum of 2",
    "订单数最多支持5个":"The maximum number of orders supported is 5",
    "请为订单添加至少一个动作":"Please add at least one action to the order",
    "固定投入":"Fixed investment",
    "比例投入":"Proportional investment",
    "动作":"Action",
    
    
    "信号马丁":"Martin signal",
    "网格交易":"Grid Trading",
    "海风马丁":"Sea Breeze Martin",
    "海浪网格":"Wave Grid",
    "马丁追踪":"Martin Tracking",
    "区间套利":"Interval arbitrage",
    "恒量定投":"Constant automatic investment",
    "投":"Input",


    "价格上限":"Price ceiling",
    "价格下限":"Price floor",
    "网格间隔":"Grid spacing",
    "单格盈利率":"Profit rate for a single grid",
    "最大挂单数":"Max order number",
    "单格投入":"Order input",
    "首单投入":"The first single input",
    "补仓金额模式":"Cover amount pattern",
    "补仓金额倍数":"Cover amount multiple",
    "倍投起始单":"Double the initial order",
    "最大做单数":"Max order quantities",
    "补仓间隔模式":"Short cover interval mode",
    "间隔比例":"Interval ratio",
    "补仓增幅":"Cover growth",
    "补仓反弹比例":"Cover the rebound ratio",
    "止盈下单模式":"Profit taking order mode",
    "止盈阈值":"Take profit threshold",
    "止盈回撤比例":"Take profit withdrawal ratio",
    "开始交易信号":"Start trading signal",
    "停止交易信号":"Stop trading signal",
    "投入缩放率":"Enter scaling rate",
    "间隔缩放率":"Interval scaling",
    "盈利率缩放率":"Profitability scaling rate",
    "首单金额":"Amount for the first order",
    "金额追投倍数":"Amount chase multiple",
    "追投间隔":"Chase for interval",
    "追投反弹比例":"Chase rebound ratio",
    "止盈回撤":"Take profit retracement",
    "回撤调整":"Retreat to adjust",
    "买入价格":"Buying price",
    "卖出价格":"Selling price",
    "定投周期":"To be cast surely cycle",
    "单笔金额":"Single amount",
    "第1次补仓比例":"Ration of the first cover",
    "第2次补仓比例":"Second cover ratio",
    "第3次补仓比例":"Third cover ratio",
    "第4次补仓比例":"Fourth cover ratio",
    "第5次补仓比例":"The proportion of the fifth cover",
    "第6次补仓比例":"The ratio of the sixth cover",
    "第7次补仓比例":"Seventh cover ratio",
    "第8次补仓比例":"Proportion of the 8th cover",
    "补仓金额增量":"Increment in cover amount",
    "强制平仓信号":"Forced liquidation signal",
  }
}

export default en
