import { get, post } from './https'

//、、、、、、、、、、、、、、、、、、、、、、 团队

//查询团队列表
export const selectMessageTemaList = params => post('team/selectMessageTemaList', params, true);

// 查询全部成员

export const findTeamMemberList = params => post('teamMember/findTeamMemberList', params, true);

// 查询未加入项目成员

export const findNoProjectMember = params => post('/teamMember/findNoProjectMember', params, true);

// 查询团队信息

export const findTemaMessage = params => post('/team/findTemaMessage', params, true);

// 修改团队介绍

export const updateTeamMessage = params => post('/team/updateTeamMessage', params, true);

// 修改团队名称

export const updateTeamName = params => post('/team/updateTeamName', params, true);

// 创建团队

export const creationTeam = params => post('/team/creationTeam', params, true);

//  查验团队名称

export const isExistenceTeamName = params => post('/team/isExistenceTeamName', params, true);

// 查询团队下项目

export const findTeamProjectCount = params => post('/teamMember/findTeamProjectCount', params, true);

// 查询项目下成员列表

export const findProjectMember = params => post('/teamMember/findProjectMember', params, true);

// 修改成员权限

export const updateMemberRole = params => post('/teamMember/updateMemberRole', params, true);

// 移除团队

export const shiftOutTeam = params => post('/team/shiftOutTeam', params, true);

// 获取团队邀请链接

export const getInvitationUrl = params => post('/team/getInvitationUrl', params, true);

//、、、、、、、、、、、、、、、、、、、、、、 项目

// 查询项目列表

export const getProjectList = params => post('/project/getProjectList', params, true);

// 创建/修改项目

export const addProject = params => post('/project/add', params, true);

// 查询项目信息

export const getProject = params => post('/project/getProject', params, true);

// 解散团队

export const dissolutionTeam = params => post('/team/dissolutionTeam', params, true);

// 查询项目成员

export const getProjectMember = params => post('/project/getProjectMember', params, true);

// 查询项目未选成员

export const getUnSelectTeamMember = params => post('/project/getUnSelectTeamMember', params, true);

// 添加成员

export const addProjectMember = params => post('/project/addProjectMember', params, true);

// 设置默认操作员

export const settingOperator = params => post('/project/settingOperator', params, true);

// 移除项目成员

export const deleteProjectMember = params => post('/project/deleteProjectMember', params, true);

// 查询交易所

export const getExchangeList = params => post('/project/getExchangeList', params, true);

// 高级设置

export const advanceSetting = params => post('/project/advanceSetting', params, true);

// 移除项目

export const deleteProject = params => post('/project/deleteProject', params, true);

// 查询项目数据

export const dataSummarizing = params => post('/strategyTemplate/dataSummarizing', params, true);

// 查询策略库

export const strategyLibrary = params => post('/strategy/strategyLibrary', params, true);

// 查询策略配置

export const getStartPage = params => post('/strategy/getStartPage', params, true);

// 查询交易对

export const getPairPage = params => post('/strategy/getPairPage', params, true);

// 查询项目角色

export const getRole = params => post('/project/getRole', params, true);

// 创建策略模板

export const addStrategyTemplate = params => post('/strategyTemplate/addStrategyTemplate', params, true);

// 查询模板列表

export const getTemplateList = params => post('/strategy/getTemplateList', params, true);

// 移除策略模板

export const deleteStrategyTemplate = params => post('/strategyTemplate/deleteStrategyTemplate', params, true);

// 模板重命名

export const updateTemplateName = params => post('/strategyTemplate/updateTemplateName', params, true);

// 模板自动交易开关

export const updateTemplateTradeSwitch = params => post('/strategyTemplate/updateTemplateTradeSwitch', params, true);

// 查询模板详情

export const getStrategyPageByTemplateList = params => post('/strategy/getStrategyPageByTemplateList', params, true);

//查询账户，添加资金账户

export const getAvailableList = params => post('/strategy/getAvailableList', params, true);

// 查询项目操作员列表

export const operationManagement = params => post('/strategyTemplate/operationManagement', params, true);

// 迁移用户

export const migrationUser = params => post('/strategyTemplate/migrationUser', params, true);

// 查询操作员列表

export const operatorList = params => post('/strategyTemplate/operatorList', params, true);

// 查询用户管理列表

export const userManagement = params => post('/strategyTemplate/userManagement', params, true);

// 用户管理顶部数据

export const userManagementTopData = params => post('/strategyTemplate/userManagementTopData', params, true);

// 用户数据详情

export const userManagementDetails = params => post('/strategyTemplate/userManagementDetails', params, true);

// 查询操作日志

export const getOperateLog = params => post('/strategy/getOperateLog', params, true);

// 模板添加账户启动策略

export const batchStart = params => post('/strategy/batchStart', params, true);

//查询模板详情

export const getTemplateMessage = params => post('/strategyTemplate/getTemplateMessage', params, true);

// 单个策略终止

export const strategyEnd = params => post('/strategy/strategyEnd', params, true);

// // 单个加仓

export const addStore = params => post('/strategy/addStore', params, true);

// // 单个暂停

export const suspend = params => post('/strategy/suspend', params, true);

// // 单个重启

export const restart = params => post('/strategy/restart', params, true);

// // 单个修改参数

export const updateParam = params => post('/strategy/updateParam', params, true);

// //单个加币对

export const manyAddSymbol = params => post('/strategy/manyAddSymbol', params, true);


// 批量暂停

export const batchSuspend = params => post('/strategy/batchSuspend', params, true);

//批量重启

export const batchRestart = params => post('/strategy/batchRestart', params, true);

// 批量加仓

export const batchAddStore = params => post('/strategy/batchAddStore', params, true);

// 批量终止

export const batchStrategyEnd = params => post('/strategy/batchStrategyEnd', params, true);

// 批量修改参数

export const batchUpdateParam = params => post('/strategy/batchUpdateParam', params, true);

// 查询消息列表


export const queryStrategyMessageList = params => post('/strategy/getStrategyMessageList', params, true);

// 执行策略详情

export const executeDetail = params => post('/strategy/executeDetail', params, true);

// 查询投入范围

export const getLimitConfig = params => post('/strategy/getLimitConfig', params, true);

// 查询成交记录

export const queryFinishOrderList = params => post('/strategy/queryFinishOrderList', params, true);

// 查询委托订单

export const getEntrustOrders = params => post('/strategy/getEntrustOrders', params, true);

// 子币对列表

export const getSonStrategyListByTemplateId = params => post('/strategy/getSonStrategyListByTemplateId', params, true);

// 创建模板查询交易所

export const getExchangeListByType = params => post('/strategy/getExchangeListByType', params, true);

// 批量添加币对

export const batchManyAddSymbol = params => post('/strategy/batchManyAddSymbol', params, true);

// 获取临时令牌

export const getTemporaryToken = params => post('/userV2/getTemporaryToken', params, true);

// 登录

export const login = params => post('/userV2/login', params, false);

// 单个启动策略

export const start = params => post('/strategy/start', params, true);

// 修改模板投入配置

export const updateTemplateInvestCoinnNum = params => post('/strategyTemplate/updateTemplateInvestCoinnNum', params, true);

// GA验证

export const checkGACode = params => post('/userV2/checkGACode', params, true);

// 查询项目下的交易对

export const getProjectPairById = params => post('/project/getProjectPairById', params, true);

// 添加项目交易对

export const addProjectPair = params => post('/project/addProjectPair', params, true);

// 根据交易所查询项目交易对

export const getPairPageById = params => post('/strategy/getPairPageById', params, true);

// 查询项目历史选择交易对

export const getTradePair = params => post('/project/getTradePair', params, true);

// 退出登入

export const logout = params => post('/userV2/logout', params, true);

// 激活团队

export const activationTeam = params => post('/team/activationTeam', params, true);

// 团队保证金

export const selectTemaBond = params => post('/bond/selectTemaBond', params, true);

// 项目列表保证金

export const selectProjectListBond = params => post('/bond/selectProjectListBond', params, true);

// 提取/补充保证金临时令牌

export const getBondTemporaryToken = params => post('/bond/getBondTemporaryToken', params, true);

// 保证金轮询

export const getBondisSuccess = params => post('/bond/getBondisSuccess', params, false);

// 资金归集

export const moneyCollection = params => post('/strategy/moneyCollection', params, true);

// 子币对操作删除币对

export const deletePair = params => post('/strategy/deletePair', params, true);



// 项目支持交易所列表

export const userManagementExchangelist = params => post('/strategyTemplate/userManagementExchangelist', params, true);

// 查询历史入金用户列表

export const fundHistoryList = params => post('/strategyTemplate/fundHistoryList', params, true);

// 查询历史详情

export const fundHistoryDetails = params => post('/strategyTemplate/fundHistoryDetails', params, true);

// 模板排序列列表

export const getTemplateSortList = params => post('/strategy/getTemplateSortList', params, true);

// 修改模板排序

export const updateTemplateSort = params => post('/strategy/updateTemplateSort', params, true);

// 随机获取标签

export const queryOrderLabel = params => post('/orderChain/queryOrderLabel', params, true);

// 查询订单链支持的交易所/交易对/

export const queryProjectData = params => post('/orderChain/queryProjectData', params, true);

// 查询入金用户

export const queryOperatorUserList = params => post('/orderChain/queryOperatorUserList', params, false);


// 动作列表

export const queryOrderActionList = params => post('/orderChain/queryOrderActionList', params, true);


// 动作详情参数

export const queryOrderActionDetails = params => post('/orderChain/queryOrderActionDetails', params, false);


//根据交易所ID 查询交易对

export const queryPair = params => post('/orderChain/queryPair', params, true);

// 查询交易所/币对 精度

export const queryFeeAndAccuracy = params => post('/orderChain/queryFeeAndAccuracy', params, true);

//创建订单链

export const batchCreateOrderChain = params => post('/orderChain/batchCreateOrderChain', params, true);

// 查询订单链列表

export const queryOrderChainList = params => post('/orderChain/queryOrderChainList', params, true);

// 查询订单链详情

export const findByIdOrderChain = params => post('/orderChain/findByIdOrderChain', params, true);

// 根据id 修改单个订单链

export const updateOrderChain = params => post('/orderChain/updateOrderChain', params, true);


// 获取策略随机标签

export const queryStrategyTemplateLabel = params => post('/orderChain/queryStrategyTemplateLabel', params, true);

// 批量修改订单链

export const batchUpdateOrderChain = params => post('/orderChain/batchUpdateOrderChain', params, true);

// 批量终止订单链

export const batchOrderChainEnd = params => post('/orderChain/batchOrderChainEnd', params, true);

//获取单个订单链详情

export const orderChainDetails = params => post('/orderChain/orderChainDetails', params, true);

//获取单个订单链日志

export const findByIdOrderLog = params => post('/orderChain/findByIdOrderLog', params, true);

// 获取币币资产详情

export const getSpotApiList = params => post('/assets/getSpotApiList', params, false);


// 批量同步现货资产

export const batchSynchSpotApi = params => post('/assets/batchSynchSpotApi', params, true);

// 批量快速兑换资产

export const quickSwap = params => post('/orderChain/quickSwap', params, true);

// 查询选中账户资金

export const findByapiIdCoin = params => post('/strategy/findByapiIdCoin', params, true);

// 查询账户资金数据

export const queryAccountData = params => post('/orderChain/queryAccountData', params, true);


//更改账户备注
export const updateUserRemarkCode = params => post('/strategyTemplate/updateUserRemarkCode', params, true);

//  查询订单日记

export const queryOrderLog = params => post('/orderChain/queryOrderLog', params, true);

//  强制结算

export const batchForceSettlement = params => post('/orderChain/batchForceSettlement', params, true);


//查询历史用户数据
export const queryHistoryAccountData = params => post('/orderChain/queryHistoryAccountData', params, true);

//获取合约持仓资料

export const getContractApiList = params => post('/assets/getContractApiList', params, false);

//获取合约支持的交易所

export const queryExchangeList = params => post('/assets/queryExchangeList', params, true);

//合约同步资产

export const batchSynchContractApi = params => post('/assets/batchSynchContractApi', params, true);

//合约同步单个资产

export const synchSingelContractApi = params => post('/assets/synchSingelContractApi', params, true);

//获取okv5的资产账户

export const getAssetApiList = params => post('/assets/getAssetApiList', params, true);

//获取可用币对

export const findContractId = params => post('/assetsTransfer/findContractId', params, true);


//、、、、、、、、、、 创建信号
export const addSignal = params => post('/signal/createSignal', params, true);

//删除信号
export const deleteSignal = params => post('/signal/deleteSignal', params, true);

//信号列表
export const querySignalList = params => post('/signal/querySignalList', params, true);

//信号详情
export const signalDetail = params => post('/signal/querySignalDetail', params, true);

//信号记录
export const signalRecode = params => post('/signal/querySignalRecode', params, true);

// 查询订单链合约交易对列表

export const queryOrderChainContractPair = params => post('/orderChain/queryOrderChainContractPair ', params, true);

// 查询合约杠杆倍数

export const findByIdLeverMultiple = params => post('/orderChain/findByIdLeverMultiple', params, true);

//合约平仓

export const quickFlatPosition = params => post('/orderChain/quickFlatPosition', params, true);

//获取合约同步资产

export const findContract = params => post('/assetsTransfer/findContract', params, true);

//币币同步全部资产

export const findFundSpot = params => post('/assetsTransfer/findFundSpot', params, true);

//币币同步单个资产

export const synchSingleSpotApi = params => post('/assets/synchSingleSpotApi', params, true);

//okv5获取余额

export const findFundAssets = params => post('/assetsTransfer/findFundAssets', params, true);

//同步单个v5资产

export const synchSingelAssetApi = params => post('/assets/synchSingelAssetApi', params, true);

//同步全部v5资产

export const batchSynchAssetApi = params => post('/assets/batchSynchAssetApi', params, true);

// 合约单个修改参数

export const contractUpdateParam = params => post('/contract/updateParam', params, true);

//划转合约

export const transfer = params => post('/assetsTransfer/transfer', params, true);

// 合约单加减仓

export const positionAdjustment = params => post('/contract/positionAdjustment', params, true);

// 合约批量加减仓

export const batchPositionAdjustment = params => post('/contract/batchPositionAdjustment', params, true);

// 合约批量启动

export const contractBatchStart = params => post('/contract/batchStart', params, true);

// 查询团队保证金明细

export const temaCapitalDetailed = params => post('/team/temaCapitalDetailed', params, true);


// 结算明细

export const settleDetail = params => post('/project/settleDetail', params, true);

// 团队撤回申请


export const withdrawApply = params => post('/team/withdrawApply ', params, true);

// 团队内容申请审核
export const applyContentExamine = params => post('/team/applyContentExamine', params, true);

// 保证金划转

export const bondisTransfer = params => post('/bond/bondisTransfer', params, true);


// 策略消息

export const strategyMessageList = params => post('/messageCenter/strategyMessageList', params, true);

// 智能交易消息

export const IntelligenceOrderMessageList = params => post('/messageCenter/IntelligenceOrderMessageList', params, true);

// 获取项目操作员列表

export const getOperator = params => post('/messageCenter/getOperator ', params, true);

// 获取项目操作员下用户列表

export const getPublicUser = params => post('/messageCenter/getPublicUser ', params, true);

// 托管消息

export const trustAccountMessageList = params => post('/messageCenter/trustAccountMessageList ', params, true);


// 获取项目消息

export const projectMessageList = params => post('/messageCenter/projectMessageList ', params, true);

// 当前委托

export const currentEntrust = params => post('/orderChain/currentEntrust ', params, false);

// 当前委托获取当前入金账户名

export const getCurrentPublicUser = params => post('/messageCenter/getCurrentPublicUser ', params, true);

// 查询交易对Id

export const querySpotByPairId = params => post('/orderChain/querySpotByPairId', params, true);

// 合约查询张数、价格

export const querySpotContractData = params => post('/orderChain/querySpotContractData', params, true);

// 快捷交易多交易所下单
export const batchQuickTransaction = params => post('/orderChain/batchQuickTransaction', params, true);

// 快捷交易
export const batchGetContractApiList = params => post('/assets/batchGetContractApiList', params, false);

// 获取下单状态信息

export const queryErrorMessage = params => post('/orderChain/queryErrorMessage', params, false);

// 轮询接口

export const quickUserAndData = params => post('/orderChain/quickUserAndData', params, false);


//项目资金走势

// 新手指导

export const prjectPictureData = params => post('/project/prjectPictureData', params, true);

//历史委托

export const historyEntrust = params => post('/orderChain/historyEntrust', params, true);



export const noviceGuidance = params => post('/project/noviceGuidance', params, false);
