import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'


let lang=localStorage.getItem('lang')
if(lang=='ch'){
  Vue.use(ElementUI);
}else{
  Vue.use(ElementUI, { locale })
}

import moment from 'moment'
Vue.prototype.$moment = moment
moment.locale('zh-cn')
import i18n from './i18n/i18n'   //国际化


import VueDND from 'awe-dnd'
Vue.use(VueDND)


// import commen from './components/commen'
// Vue.prototype.$commen = commen

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
