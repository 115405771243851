//汉语
const en = {
  m: {
    "langs":'ko',

    "新手必读":"조보 필독", 
  }
}

export default en
