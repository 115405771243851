import Vue from 'vue'
import Vuex from 'vuex'
import { noviceGuidance } from '../request/api'

Vue.use(Vuex)

import i18n from '@/i18n/i18n'   //国际化
export default new Vuex.Store({
  state: {
    isLoading: false,

    // 订单链使用数据
    orderChainType: 'spot',//订单链模式
    orderList: [{
      name: '订单1',
      value: "",
    }],
    NoviceActive: '2',

    isProject: false,

    isTeam: true,

    noviceData: null,  //弹窗信息

    lang: i18n.locale, //语言
  },

  //计算属性 类似于 computed
  getters: {

    //语言Id
    langId(state){
      const langd = {'ch':1, 'en':3, 'ko':4, 'ja':5};
      return langd[state.lang]
    },
  },

  mutations: {

    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setOrderChainType(state, payload) {
      state.orderChainType = payload;
    },
    setOrderList(state, payload) {
      state.orderList = payload
    },

    // 悬浮跳转
    setNoviceActive(state, payload) {
      state.NoviceActive = payload
    },

    // 是否项目页
    setIsProject(state, payload) {
      state.isProject = payload
    },

    // 是否自己创建的团队
    setIsTeam(state, payload) {
      state.isTeam = payload
    },

    // // 修改弹窗信息
    setNoviceData(state, payload) {
      state.noviceData = payload
    },

    //设置语言
    setLang(state, lang) {
      i18n.locale = lang;
      localStorage.setItem("lang", lang);
      state.lang = lang;
    },
  },
  
  actions: {
    // 查询弹窗信息
    getType(context, params) {
      // console.log(context,params)
      let payload = {
        token: sessionStorage.getItem('token'),
        teamId: params.teamId,
        teamProjectId: params.teamProjectId
      }
      if (!payload.token) {
        return
      }
      noviceGuidance(payload).then((res) => {
        if (res.status == 200) {
          context.commit("setNoviceData", res.data.noviceGuidanceData);
        } else {
          this.$message.error(res.msg)
        }
      })

    },
  },
  modules: {
  }
})
