<!--首页-->
<template>
  <div class="home">
    <el-container>
      <!-- aside -->
      <el-aside width="270px">
        <div>
          <div class="team" v-if="defaultTeam">
            <span class="teamName">{{ defaultTeam.teamName }}</span>
            <el-dropdown
              style="margin-left: 8px; color: white; background: #2174ff"
              trigger="click"
              placement="bottom-start">
              <span class="el-dropdown-link">
                <i
                  class="el-icon-caret-bottom"
                  style="cursor: pointer"
                  @click="selectMessageTemaList()">
                </i>
              </span>
              <el-dropdown-menu
                style="width: 240px; padding-bottom: 50px"
                slot="dropdown"
                :class="[MessageTemaList.length > 23 ? 'selectScroll' : '']">

                <template v-for="(item, idx) in MessageTemaList">
                  <el-dropdown-item
                    :key="idx"
                    @click.native="setDefaultTeam(item)">
                    {{ item.teamName }}</el-dropdown-item>
                </template>
                <el-dropdown-item
                  style="
                    border-top: 1px solid #e6e7ed;
                    display: flex;
                    align-items: center;
                    padding: 5px 0 5px 20px;
                    color: #0079fd;
                    position: fixed;
                    width: 203px;
                    background: #fff;"
                  :style="MessageTemaList.length > 23 ? 'bottom: 8px' : ''"
                  @click.native="newTeamBtn()">
                  <!-- @click.native="createTeamShow = true" -->
                  <div>
                    <img
                      style="width: 14px; height: 14px; margin-right: 5px"
                      src="../assets/icon/icon_add@2x.png"
                      alt="" />
                  {{$t('m.创建新团队')}}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <el-menu
            :default-active="active"
            class="el-menu-vertical-demo"
            background-color="#102859"
            text-color="#ffffff"
            active-text-color="#007aff">
            <el-menu-item
              :index="indexTwo"
              @click="activeClick('2', '项目列表')">
              <img src="../assets/icon/tuoguan.png" alt="" class="ty-icon" />
              <span slot="title">{{$t('m.托管项目')}}</span>
            </el-menu-item>
            <el-menu-item @click="activeClick('1', '团队成员')">
              <img src="../assets/icon/chenyuan.png" alt="" class="ty-icon" />
              <span slot="title">{{$t('m.团队成员')}}</span>
            </el-menu-item>

            <!-- <el-menu-item
              style="display: flex; align-items: center"
              @click="modifyShow()"
            >
              <img src="../assets/icon/icon@2x.png" alt="" class="ty-icon" />
              <span class="menuSlot" slot="title"
                >团队介绍
                <span
                  v-if="contentStatus"
                  :class="
                    contentStatus === '已审核'
                      ? 'menuSlotAdopt'
                      : 'menuSlotShow'
                  "
                  >{{ contentStatus }}</span
                ></span
              >
            </el-menu-item> -->

            <el-menu-item
              @click="activeClick('4', '团队设置')"
              :index="indexThree">
              <img src="../assets/icon/shezhi.png" alt="" class="ty-icon" />
              <span class="superText" slot="title">
                <div>{{$t('m.团队设置')}}</div>
                <div class="text">({{$t('m.仅超级管理员可见')}})</div>
              </span>
            </el-menu-item>
            <el-menu-item :index="indexOne" @click="activeClick('3', '保证金')">
              <img
                src="../assets/icon/baozhengjin.png"
                alt=""
                class="ty-icon"
              />
              <span class="superText" slot="title">
                <div>{{$t('m.保证金')}}</div>
                <div class="text">({{$t('m.仅超级管理员可见')}})</div>
              </span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-aside>
      
      <!-- main -->
      <el-main>
        <div class="mainTop">
          <div class="left">
            <span>{{ $t('m.'+showText) }}</span>
          </div>
          <div class="right">
            <span v-if="showText == '项目列表'">{{$t('m.首页')}} > {{$t('m.托管项目')}} > </span>
            <span v-if="showText == '保证金' || showText == '团队设置'">
              {{$t('m.首页')}} >
            </span>
            <span class="on">{{ " " + $t('m.'+showText) }}</span>

            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <img :src="account.avatar" alt="" v-if="account.avatar" />
                <img src="../assets/image/img.png" alt="" v-else />
              </span>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="accInfo">
                    <img :src="account.avatar" alt="" v-if="account.avatar" />
                    <img src="../assets/image/img.png" alt="" v-else />

                    <div class="acc">
                      <span class="name">{{ account.nickName }}</span>
                      <span class="account">{{
                        account.phone ? account.phone : account.email
                      }}</span>
                    </div>
                  </div>
                </el-dropdown-item>
                
                <el-dropdown-item @click.native="switchLang('en')">
                  <div class="yuyan">
                    <img src="../assets/image/English.png" alt="" />
                    <div class="acc">
                      <span class="name">English</span>
                    </div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item @click.native="switchLang('ch')">
                  <div class="yuyan">
                    <img src="../assets/image/Chinese.png" alt="" />
                    <div class="acc">
                      <span class="name">汉语</span>
                    </div>
                  </div>
                </el-dropdown-item>
                
                <el-dropdown-item
                  icon="el-icon-delete"
                  @click.native="loginOut()">
                  {{$t('m.退出登录')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

        </div>

        <div class="mainBottom">
          <!-- 托管项目 -->
          <ProjectList v-if="active == '2'" ref="ProjectList" />

          <!-- 团队成员 -->

          <!-- 团队设置 -->

          <!-- 保证金 -->
          <Deposit
            v-if="active == '3'"
            @selectMessageTemaList="selectMessageTemaList"
          />

          <TeamSetting v-if="active == '4'" />
        </div>
      </el-main>
    </el-container>

    <!-- 创建团队 -->
    <el-dialog
      :title="'m.创建团队'"
      :visible.sync="createTeamShow"
      width="30%"
      center
      :before-close="handleClose"
      :close-on-click-modal="false"
      :show-close="false">
      <div class="create-team">
        <div class="box">
          <div class="box-title">
            <span>{{$t('m.创建团队')}}</span>
            <span>{{$t('m.限6个字符')}}</span>
          </div>
          <el-input
            v-model="teamName"
            :placeholder="$t('m.请输入内容')"
            :maxlength="6"
          ></el-input>
        </div>
        <div class="box">
          <div class="box-title">
            <span>{{$t('m.团队介绍')}}</span>
            <span>{{$t('m.限500个字符')}}</span>
          </div>
          <el-input
            type="textarea"
            :rows="10"
            :placeholder="$t('m.请输入内容')"
            v-model="teamIntroduct"
            :maxlength="500"
            resize="none"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelcreateTeam">{{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="createTeam">{{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 团队介绍 -->
    <el-dialog
      :title="$t('m.团队介绍')"
      :visible.sync="activeShow"
      width="30%"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="create-team">
        <div class="box">
          <div class="box-title">
            <span>{{$t('m.团队名称')}}</span>
            <span>{{$t('m.限6个字符')}}</span>
          </div>
          <el-input
            v-model="modifyName"
            :placeholder="$t('m.团队名称')"
            :maxlength="6"
          ></el-input>
        </div>
        <div class="box">
          <div class="box-title">
            <span>{{$t('m.团队介绍')}}</span>
            <span>{{$t('m.限500个字符')}}</span>
          </div>
          <el-input
            type="textarea"
            :rows="10"
            :placeholder="$t('m.请简单介绍一下这个团队吧…')"
            v-model="modifyIntroduct"
            :maxlength="500"
            resize="none"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modifyCreateTeam">{{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click.native="ModifySubmit">{{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Deposit from "./projectSetup/deposit";
import ProjectList from "./projectSetup/projectList";
import TeamSetting from "./projectSetup/teamSetting";
import {
  selectMessageTemaList,
  creationTeam,
  logout,
  updateTeamMessage,
} from "../request/api";

export default {
  components: {
    Deposit,
    ProjectList,
    TeamSetting,
  },
  data() {
    return {

      lang: undefined, //当前语言
      langd: {'ch':0, 'en':1, 'ko':2, 'ja':3},
      langs: [
        {'name':'汉语', 'label':'Chinese', 'tag':'ZH', 'key':'ch', 
        'url':'@/assets/image/Chinese.png'},
        
        {'name':'英文', 'label':'English', 'tag':'EN', 'key':'en', 
        'url':'@/assets/image/English.png'},
      ],

      active: "4",
      account: null, //用户信息
      createTeamShow: false,
      teamName: "", //创建团队名字
      teamIntroduct: "", //创建团队介绍
      MessageTemaList: [], //团队列表
      copyList: [], //团队列表备份
      defaultTeam: null, // 默认团队
      isShow: false, //保证金显示
      statusImg: "activate", //团队是否激活图案
      contentStatus: null, //审核状态
      activeShow: false, //团队介绍
      modifyIntroduct: null, //团队介绍详情
      modifyName: null, //团队介绍名称
      indexOne: "3",
      indexTwo: "2",
      indexThree: "4",
      index: true,
      showText: '项目列表',
    };
  },
  computed: {},
  watch: {
    "$store.state.NoviceActive"(New, old) {
      console.log(New);
      if (New != 0) {
        let type =
          New == 2
            ? '托管项目'
            : New == 3
            ? '保证金'
            : New == 4
            ? '团队设置'
            : "";
        this.activeClick(New, type);
      }
    },
    defaultTeam(New, old) {
      if (New.createUser != this.account.userId) {
        this.$store.commit("setIsTeam", false);
      } else {
        this.$store.commit("setIsTeam", true);
      }
    },
  },
  methods: {
    
    //切换语言
    switchLang(key) {
      this.$store.commit('setLang', key)
    },

    activeClick(v, type) {
      console.log(type);
      console.log(this.defaultTeam);
      if (v == 1) {
        this.$router.push({
          path: "TeamSetup",
          query: {
            userId:
              this.account.userId == this.defaultTeam.createUser ? true : false,
          },
        });
        return;
      } else if (v == 3) {
        if (this.account.userId == this.defaultTeam.createUser) {
          this.active = v;
          this.showText = type;
          this.index = false;

          console.log(this.active);
          return;
        } else {
          if (this.index) {
            let index = this.indexOne;
            this.indexOne = this.indexTwo;
            this.indexTwo = index;
          }
          // this.$message.error("不是该团队创建人，没有设置权限");
          this.$alert(this.$t('m.您没有权限使用该功能，敬请谅解！'), $t('m.额，好像没有权限'), {
            confirmButtonText: this.$t('m.知道啦'),
          });
        }
      } else if (v == 4) {
        if (this.account.userId == this.defaultTeam.createUser) {
          this.active = v;
          this.showText = type;
          this.index = false;

          console.log(this.active);
          return;
        } else {
          if (this.index) {
            let index = this.indexThree;
            this.indexThree = this.indexTwo;
            this.indexTwo = index;
          }
          // this.$message.error("不是该团队创建人，没有设置权限");
          this.$alert(this.$t('m.您没有权限使用该功能，敬请谅解！'), $t('m.额，好像没有权限'), {
            confirmButtonText: this.$t('m.知道啦'),
          });
        }
      } else {
        this.active = v;
        this.showText = type;

        console.log(this.active);
      }
    },
    // 跳操作手册
    toOperationManual() {
      location.href;
      // https://shimo.im/docs/dPCWphVkPwGHcPcK/read
      // window.open='https://shimo.im/docs/dPCWphVkPwGHcPcK/read'
      window.open(
        "https://shimo.im/docs/dPCWphVkPwGHcPcK/read",
        "_blank",
        "width=1500,height=800,top=100px,left=0px"
      );
    },
    newTeamBtn() {
      this.$router.push({ path: "/firstLogin" });
    },
    // 查询团队列表
    selectMessageTemaList() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      selectMessageTemaList(payload).then((res) => {
        if (res.status == 200) {
          this.MessageTemaList = res.data.MessageTemaList;
          if (this.MessageTemaList.length == 0) {
            this.$router.push({ path: "firstLogin" });
          }
          sessionStorage.setItem(
            "MessageTemaList",
            JSON.stringify(this.MessageTemaList)
          );

          this.copyList = JSON.parse(JSON.stringify(res.data.MessageTemaList));
          if (sessionStorage.getItem("queryNumber")) {
            this.MessageTemaList.forEach((e) => {
              if (sessionStorage.getItem("queryNumber") == e.teamName) {
                this.defaultTeam = e;
                this.contentStatus = e.contentStatus;
                sessionStorage.removeItem("queryNumber");
                sessionStorage.setItem("defaultTeam", JSON.stringify(e));
              }
            });
          } else {
            if (!JSON.parse(sessionStorage.getItem("defaultTeam"))) {
              this.defaultTeam = this.MessageTemaList[0];
              sessionStorage.setItem(
                "defaultTeam",
                JSON.stringify(this.MessageTemaList[0])
              );
              this.contentStatus = JSON.parse(
                sessionStorage.getItem("defaultTeam")
              ).contentStatus;
            } else {
              let defaultTeam = JSON.parse(
                sessionStorage.getItem("defaultTeam")
              ).teamId;
              for (let i = 0; i < this.MessageTemaList.length; i++) {
                if (this.MessageTemaList[i].teamId === defaultTeam) {
                  this.defaultTeam = this.MessageTemaList[i];
                }
              }
              this.contentStatus = this.defaultTeam.contentStatus;
            }
          }
          this.statusImg = this.defaultTeam.status
            ? this.defaultTeam.status
            : "";
          if (this.statusImg === "notActivate") {
            this.contentStatus = "notAdopt";
          }
          this.contentStatus =
            this.contentStatus === "notAdopt"
              ? this.$t('m.未审核')
              : this.contentStatus === "review"
              ? this.$t('m.审核中')
              : this.contentStatus === "adopt"
              ? this.$t('m.已审核')
              : "";
          // this.changeCheck();
          this.isShow =
            this.account.userId == this.defaultTeam.createUser ? true : false;

          let pay = {
            teamId: this.defaultTeam ? this.defaultTeam.teamId : null,
            teamProjectId: null,
          };
          this.$store.dispatch("getType", pay);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 选择团队
    setDefaultTeam(item) {
      if (item.teamId == this.defaultTeam.teamId) {
        return;
      }
      let num = JSON.parse(JSON.stringify(this.active));

      if (!this.index) {
        if (this.active == "3") {
          let index = this.indexOne;
          this.indexOne = this.indexTwo;
          this.indexTwo = index;
          this.index = true;
        } else if (this.active == "4") {
          let index = this.indexThree;
          this.indexThree = this.indexTwo;
          this.indexTwo = index;
        }
      }
      this.defaultTeam = item;
      this.statusImg = item.status;
      this.contentStatus = this.defaultTeam.contentStatus;
      this.statusImg = this.defaultTeam.status;
      if (this.statusImg === "notActivate") {
        this.defaultTeam.contentStatus = "notAdopt";
      }
      this.contentStatus =
        this.defaultTeam.contentStatus === "notAdopt"
          ? this.$t('m.未审核')
          : this.defaultTeam.contentStatus === "review"
          ? this.$t('m.审核中')
          : this.defaultTeam.contentStatus === "adopt"
          ? this.$t('m.已审核')
          : "";
      sessionStorage.setItem("defaultTeam", JSON.stringify(item));
      this.isShow =
        this.account.userId == this.defaultTeam.createUser ? true : false;

      setTimeout(() => {
        this.active = "2";
        this.$refs.ProjectList.getProjectList();
      }, 500);
    },
    // 退出登入
    loginOut() {
      let payload = {
        token: sessionStorage.getItem("token"),
        temporaryToken: sessionStorage.getItem("temporaryToken"),
      };
      logout(payload).then((res) => {
        if (res.status == 200) {
        } else {
          // this.$message.error(res.msg);
        }
        this.$message.success(this.$t('m.退出成功'));
        sessionStorage.clear();
        this.$router.push({ path: "login" });
      });
    },
    // 创建团队
    createTeam() {
      if (this.teamName.length > 6) {
        this.$message.error(this.$t('m.团队名称不能超过6个字符'));
        return;
      } else if (this.teamIntroduct === "") {
        this.$message.error(this.$t('m.团队介绍不能为空'));
        return;
      }

      let payload = {
        token: sessionStorage.getItem("token"),
        teamName: this.teamName,
        teamIntroduct: this.teamIntroduct,
      };
      creationTeam(payload).then((res) => {
        if (res.status == 200) {
          this.createTeamShow = false;
          this.teamName = "";
          this.teamIntroduct = "";
          this.$message.success(this.$t('m.创建成功'));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 取消创建团队
    cancelcreateTeam() {
      this.createTeamShow = false;
      this.teamName = "";
      this.teamIntroduct = "";
    },
    handleClose(done) {
      done();
    },
    // 过滤团队
    changeCheck() {
      let name = this.defaultTeam.teamName;
      let list = JSON.parse(JSON.stringify(this.copyList));
      for (let i = 0; i < list.length; i++) {
        if (name == list[i].teamName) {
          list.splice(i, 1);
          this.MessageTemaList = list;
          return;
        }
      }
    },
    //团队介绍
    modifyShow() {
      this.modifyIntroduct = this.defaultTeam.teamIntroduct;
      this.modifyName = this.defaultTeam.teamName;
      if (this.account.userId == this.defaultTeam.createUser) {
        this.activeShow = true;
      } else {
        // this.$message.error("不是该团队创建人，没有设置权限");
        this.$alert(this.$t('m.您没有权限使用该功能，敬请谅解！'), $t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
    },
    modifyCreateTeam() {
      this.activeShow = false;
    },
    //修改团队介绍
    ModifySubmit() {
      if (this.modifyIntroduct === "") {
        this.$message.error(this.$t('m.团队介绍不能为空'));
        return;
      }
      if (this.modifyName === "") {
        this.$message.error(this.$t('m.团队名称不能为空'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        teamIntroduct: this.modifyIntroduct,
        teamName: this.modifyName,
      };
      updateTeamMessage(payload).then((res) => {
        if (res.status == 200) {
          this.activeShow = false;
          this.$message.success(this.$t('m.修改成功'));
          this.selectMessageTemaList();
        } else {
          this.$message.error(res.msg);
        }
      });
      // payload = {
      //   token: sessionStorage.getItem("token"),
      //   teamId: this.modifyInfo.teamId,
      //   teamName: this.modifyName,
      // };
      // updateTeamName(payload).then((res) => {
      //   if (res.status == 200) {
      //     this.$message.success("修改成功");
      //     this.activeShow = false;
      //   } else {
      //     this.$message.error(res.msg);
      //   }
      // });
    },
  },
  created() {
    
    this.account = JSON.parse(sessionStorage.getItem("account"));
  },
  mounted() {
    console.log(this.$store.state.NoviceActive);
    if (sessionStorage.getItem("active")) {
      let index = this.indexOne;
      this.indexOne = this.indexTwo;
      this.indexTwo = index;
      this.active = JSON.parse(sessionStorage.getItem("active"));
      sessionStorage.removeItem("active");
    } else {
      this.active = "2";
    }

    let token = sessionStorage.getItem("token");
    if (!token) {
      this.$router.push({ path: "login" });
    }

    this.selectMessageTemaList();
    setTimeout(() => {
      let New = this.$store.state.NoviceActive;
      let type =
        New == 2
          ? '托管项目'
          : New == 3
          ? '保证金'
          : New == 4
          ? '团队设置'
          : "";
      this.activeClick(New, type);
    }, 500);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    let account = JSON.parse(sessionStorage.getItem("account"));
    this.account = account;

    // this.selectMessageTemaList();
    this.$refs.ProjectList ? this.$refs.ProjectList.getProjectList() : "";
  },
  deactivated() {
    this.$destroy();
  },
};
</script>

<style lang='scss' scoped>

.but-yypc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #E4E6EF;

  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #181C32;
  .img-gqpc {
    width: 24px;
    height: 16px;
    margin-right: 4px;
  }
}

.home {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
  }
  // aside
  .el-aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background-color: #282933;

    background: #102859;
    color: #ffffff;
    // border: 1px solid #102859;
    .team {
      display: flex;
      align-items: center;
      color: #ffffff;
      // margin: 24px 0 0;
      padding: 12px 0px 12px 50px;
      background: #2174ff;
      .teamName {
        font-size: 16px;
        height: 36px;
        line-height: 36px;
      }
    }
    .teamShow {
      width: 118px;
      height: 30px;
      margin: 16px 0 24px 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .el-menu {
      border-right: 0px;
      .el-menu-item {
        padding: 0 32px !important;
        font-size: 14px;
        img {
          margin-right: 16px;
        }
        .menuSlot {
          display: flex;
          justify-content: center;
          align-items: center;
          .menuSlotAdopt {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 62px;
            height: 22px;
            color: #007aff;
            background: #dfeeff;
            border-radius: 11px;
            border: 1px solid #007aff;
            font-size: 12px;
            margin-left: 8px;
          }
          .menuSlotShow {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 62px;
            height: 22px;
            background: #edeff5;
            border-radius: 11px;
            border: 1px solid #e6e7ed;
            font-size: 12px;
            margin-left: 8px;
            color: #8f9096;
          }
        }
      }
    }
    .user {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-bottom: 40px;
      .user-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .user-content {
        font-size: 14px;
        color: #333;
        span {
          margin-right: 2px;
        }
        p {
          font-size: 12px;
          margin-top: 4px;
        }
        ::v-deep.el-dropdown {
          display: flex;
          cursor: pointer;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
  // main
  .el-main {
    background: #f9fafc;
    color: #333;
    padding: 0px;
    padding-left: 0px;
    height: 100%;
    .mainTop {
      width: calc(100% - 70px);
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 30px;
      background: #f0f6ff;
      padding-left: 40px;
      .left {
        color: #102859;
        font-size: 20px;
      }
      .right {
        display: flex;
        align-items: center;
        color: #9faabd;
        font-size: 18px;
        .on {
          color: #3b7cff;
          font-size: 16px;
          margin-left: 4px;
        }
        img {
          width: 30px;
          height: 30px;
          margin-left: 30px;
          cursor: pointer;
          border-radius: 50%;
        }
      }

      .topUserData {
        height: 50px;
        display: flex;
        align-items: center;
        margin: 24px 33px 0 0;
        background-color: #fff;
        font-size: 16px;
        border-radius: 5px;
        color: #5e5f64;
        span {
          margin-right: 16px;
        }
        .topUserIcon {
          width: 0;
          height: 0;
          border: 40px solid #eef0f5;
          border-right: 40px solid transparent;
        }
        .topUserBod {
          height: 100%;
          border-right: 8px solid #007aff;
          border-radius: 0 5px 5px 0;
        }
        .topUserImg {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 32px;
            height: 32px;
            margin-right: 6px;
          }
          span {
            height: 22px;
            line-height: 28px;
            margin-right: 0;
          }
        }
      }
    }
    .mainBottom {
      background: #f9fafc;
      // padding-left: 40px;
      // padding-left: 40px;

      height: -webkit-calc(100% - 64px);
      height: -moz-calc(100% - 64px);
      height: calc(100% - 64px);
    }
  }
  // 创建团队
  .create-team {
    .box {
      margin-bottom: 24px;
      ::v-deep.el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      .box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        :last-child {
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
}
.el-menu-item {
  background: #102859;
  display: flex;
  align-items: center;
}
.el-menu-item.is-active {
  background: linear-gradient(270deg, #00d1ff 0%, #2174ff 100%);
  color: #fff !important;
}

.yuyan {
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: 20px;
    margin-right: 10px;
  }
  .name {
    font-size: 12px;
    color: #102859;
  }
}
.accInfo {
  display: flex;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .acc {
    display: flex;
    flex-direction: column;
    // align-items: center;
    margin-left: 10px;
    line-height: 20px;
    .name {
      font-size: 12px;
      color: #102859;
    }
    .account {
      color: #9faabd;
      font-size: 10px;
    }
  }
}

/deep/.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.superText {
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: center;
  div {
    height: 20px;
    line-height: 20px;
  }
  .text {
    font-size: 12px;
    //   color: #3f3f3f;
  }
}
/deep/.el-menu-item,
.el-submenu__title {
  line-height: 0;
}
.selectScroll {
  overflow-y: scroll;
  top: 0 !important;
  height: calc(100% - 80px);
}
.suspensionBox {
  position: absolute;
  background-color: black;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  left: 20px;
  z-index: 9999;
}
</style>