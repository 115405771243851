import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '*',
    name: 'Home',
    redirect: '/',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  // 项目设置
  {
    path: '/projectSetup',
    name: 'ProjectSetup',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectSetup.vue')
  },
  // 团队设置
  {
    path: '/TeamSetup',
    name: 'TeamSetup',
    component: () => import(/* webpackChunkName: "about" */ '../views/TeamSetup.vue')
  },
  // 项目详情
  {
    path: '/Project',
    name: 'Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/Project.vue')
  },
  // 项目详情操作员
  // {
  //   path: '/Projectoperator',
  //   name: 'Projectoperator',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Projectoperator.vue')
  // },
  {
    path: '/ProjectNewPerator',
    name: 'ProjectNewPerator',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectNewPerator.vue')
  },
  // 添加账户
  {
    path: '/addAccount',
    name: 'addAccount',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/addAccount.vue')
  },
  // 创建策略模板
  {
    path: '/createPolicyTemplate',
    name: 'createPolicyTemplate',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/createPolicyTemplate.vue')
  },
  // 子币对操作

  {
    path: '/currencyPairOperation',
    name: 'currencyPairOperation',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/currencyPairOperation.vue')
  },

  // 详情

  {
    path: '/details',
    name: 'details',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/details.vue')
  },

  // 选择交易对 
  {
    path: '/selectTradingPairs',
    name: 'selectTradingPairs',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/selectTradingPairs.vue')
  },
  // 启动策略
  {
    path: '/startUp',
    name: 'startUp',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/startUp.vue')
  },

  // 策略操作日志
  {
    path: '/operationLog',
    name: 'operationLog',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/operationLog.vue')
  },
  // 订单链操作日志
  {
    path: '/orderChainLog',
    name: 'orderChainLog',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/orderChainLog.vue')
  },

  //订单链详情
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/orderDetail.vue')
  },

  // 单个策略详情
  {
    path: '/strategyDetails',
    name: 'strategyDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/strategyDetails.vue')
  },
  // 策略消息
  {
    path: '/strategyMessage',
    name: 'strategyMessage',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/strategyMessage.vue')
  },

  //订单链消息

  {
    path: '/orderMessage',
    name: 'orderMessage',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/orderMessage.vue')
  },

  // 结算明细

  {
    path: '/settlementDetails',
    name: 'settlementDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectSetup/settlementDetails.vue')
  },

  // 历史详情

  {
    path: '/oldDetails',
    name: 'oldDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/oldDetails.vue')
  },
  //活跃订单账户
  {
    path: '/activeOrder',
    name: 'activeOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/activeOrder.vue')
  },


  {
    path: '/strategyDetail',
    name: 'strategyDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/strategyDetail.vue')
  },

  //信号中心详情
  {
    path: '/signalInfo',
    name: 'signalInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectManagement/signalInfo.vue')
  },

  // 首次登入创建团队

  {
    path: '/firstLogin',
    name: 'firstLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/firstLogin.vue')
  },

  // 保证金明细

  {
    path: '/depositDetail',
    name: 'depositDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/projectSetup/depositDetail.vue')
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 路由拦截
router.beforeEach((to, from, next) => {
  let token = window.sessionStorage.getItem('token');
  if (token) {
    next()
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next('/login')
    }
  }
})



export default router
